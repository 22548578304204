import $ from "jquery";
import "jquery-mask-plugin";
import { allToString, appendData, collectFormData, sendData, sendEvent, transformData } from "../collector";
import ValidateUtils from "./aaalife-utils-validate";
import { checkAndSetEmailSource, checkAndSetPhoneSource } from "../utils/data-source-code";
import { isUserAQualityLead } from "../features/traffic-monetization";
import { getOverrides } from "../utils/externalOverrides";
import { parseUnformatted10DigitPhone, format10DigitNanpaPhone } from "../utils/phone";
import { getRestrictionsValidationData } from "./aaalife-utils-get-data";
import { browserStorage } from "./aaalife-utils-browser-storage";
import { getClubAndCampaignProfile, updateDynamicPhone } from "../utils/aaalife-dynamic-phone";
import { retrieveUrlParameters } from "../utils/page";

export function aaalifeCampaignAndTalkToExpertInit() {
    var _expertFormDataValid = {
        firstName: false,
        lastName: false,
        emailFormat: false,
        birthdate: false,
        phoneFormat: false,
        zipFormat: false,
        interestedIn: false,
    };

    var _campaignFormDataValid = {
        firstName: false,
        lastName: false,
        emailFormat: false,
        phoneFormat: false,
        zipFormat: false,
        bestTimeToCall: false,
        birthdate: false,
        streetAddress: false,
        city: false,
        state: false,
        gender: false,
        isMember: document.querySelector('#campaignForm [name="isMember"]') === null,
        height: false,
        weight: false,
        termLength: false,
        coverageAmount: false,
    };

    var validationRestrictions = {};
    var _inlineModal;

    if ($("#talkToAnExpertForm").length > 0 || $("#campaignForm").length > 0) {
        $(init);
    }

    /**
     * init - initialize Talk to an Expert JS
     *
     * @return {void} void
     */
    function init() {
        _initForm();
        _setFontColors();
        _initEventListeners();
        aaalife.data.user.source = aaalife.data.user.source || aaalife.fromServer.emailSource;
        var validationData = getRestrictionsValidationData();
        aaalife.utils.modalInit();
        _inlineModal = new aaalife.utils.Inline({
            content: $(".errorModal"),
        });

        validationData.then(_validateTTAAZip);
    }

    function _validateTTAAZip() {
        if ($("#zip").val()) {
            _validateZip.call($(".zipNumeric")[0]);
        }
    }

    /**
     * _initForm - pre-populate form fields with any user info that has been
     * saved
     *
     * @return {void} void
     */
    function _initForm() {
        var user = browserStorage.getObject("user");
        if (!user) {
            user = aaalife.data.user || {};
        }

        _populateDropdowns($("#year"), new Date().getFullYear() - 13, new Date().getFullYear() - 100, -1);
        _populateDaysDropdown();

        // pre-populate form fields
        if (user && Object.keys(user).length > 0) {
            $("#firstName").val(user.firstName).trigger("change");

            $("#lastName").val(user.lastName);
            $("#year").val(user.dobYear);
            $("#month").val(user.dobMonth);
            $("#day").val(user.dobDay);
            $("#email").val(user.email);
            $("#phone").val(user.phone);
            $("#zip").val(user.zip);
            const isMemberRadios = document.querySelectorAll('#campaignForm [name="isMember"]');
            isMemberRadios.forEach((radio) => (radio.checked = radio.value === user.isMember));
        }

        $("input, select").addClass("pristine").on("focus blur", _markDirty);
    }

    function _setFontColors() {
        $(".imgFormCmpnt-title").css("color", aaalife.fromServer.urlStrings.fontColor);
        $(".imgFormCmpnt-title strong").css("color", aaalife.fromServer.urlStrings.accentFontColor);
    }

    function _initEventListeners() {
        $(".showtooltip svg").on("click", function () {
            $(this).parent().find(".tooltipinfo").show();
        });
        $(".tooltipinfo .tooltipclose").on("click", function () {
            $(this).parent().hide();
        });

        _initTalkToAnExpertEventListeners();
        _initCampaignEventListeners();
    }

    function _initTalkToAnExpertEventListeners() {
        var checkboxesPresent = $("[type=checkbox]").length > 0;

        if ($("#talkToAnExpertForm").length > 0) {
            aaalife.data.user.source = $(".talkToAnExpert-submitButton").data("source");
            $(".talkToAnExpert-submitButton").on("click", _validateExpertForm);

            $('#talkToAnExpertForm [name="firstName"]').mask("YYYYYYYYYYYYYYYYYYYY", {
                translation: {
                    Y: {
                        pattern: /[A-Za-z'-]/,
                    },
                },
            });
            $('#talkToAnExpertForm [name="lastName"]').mask("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYY", {
                translation: {
                    Y: {
                        pattern: /[ A-Za-z'-]/,
                    },
                },
            });

            $("#talkToAnExpertForm .interestedIn").on("change", _productAvailableForState);

            $('#talkToAnExpertForm [name="firstName"]').on("change", _hasValue);
            $('#talkToAnExpertForm [name="lastName"]').on("change", _hasValue);
            $("#talkToAnExpertForm #phone").on("change", _checkPhone);
            $("#talkToAnExpertForm #phone").mask("YZZ(A00) A00-0000", {
                translation: {
                    A: {
                        pattern: /[2-9]/,
                    },
                    Z: {
                        pattern: /[0-1 ]/,
                        optional: true,
                    },
                    Y: {
                        pattern: /[+ ]/,
                        optional: true,
                    },
                },
            });

            $("#talkToAnExpertForm #email").on("change", _checkEmail);
            $("#talkToAnExpertForm #month").on("change", _validateAge);
            $("#talkToAnExpertForm #month").on("change", _populateDaysDropdown);
            $("#talkToAnExpertForm #day").on("change", _validateAge);
            $("#talkToAnExpertForm #year").on("change", _populateDaysDropdown);
            $("#talkToAnExpertForm #year").on("change", _validateAge);

            // Disallow submission of form with enter button
            $("#talkToAnExpertForm input").on("keypress", function (e) {
                return e.which !== 13;
            });

            if (checkboxesPresent) {
                $("#talkToAnExpertForm #zip").on("blur", _productAvailableForState);
                $("#talkToAnExpertForm #zip").on("blur", _checkZip);
            } else {
                $("#talkToAnExpertForm #zip").on("blur", _validateZip);
            }
        }
    }

    function _initCampaignEventListeners() {
        if ($("#campaignForm").length > 0) {
            $("#campaignForm").on("submit", _validateCampaignForm);
            // $('#submitShortFormButton').on('click', _validateCampaignForm);

            var params = retrieveUrlParameters();

            var cmp = params.cmp;
            var lead = params.lead;

            var user = browserStorage.getObject("user");

            if (!user) {
                user = aaalife.data.user || {};
            }

            getClubAndCampaignProfile(user.zip, cmp, lead)
                .then(updateDynamicPhone)
                .catch((error) => console.error("Error updating phone", error));
            // Validation
            $('#campaignForm [name="firstName"]').mask("YYYYYYYYYYYYYYYYYYYY", {
                translation: {
                    Y: {
                        pattern: /[A-Za-z'-]/,
                    },
                },
            });
            $('#campaignForm [name="lastName"]').mask("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYY", {
                translation: {
                    Y: {
                        pattern: /[ A-Za-z'-]/,
                    },
                },
            });
            $('#campaignForm [name="streetAddress"]').mask("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYY", {
                translation: {
                    Y: {
                        pattern: /[ #A-Za-z0-9'-]/,
                    },
                },
            });
            $('#campaignForm [name="city"]').mask("YYYYYYYYYYYYYYYYYYYY", {
                translation: {
                    Y: {
                        pattern: /[ A-Za-z0-9'-]/,
                    },
                },
            });

            // Disallow submission of form with enter button
            $("#campaignForm input").on("keypress", function (e) {
                return e.which !== 13;
            });

            $('#campaignForm [name="firstName"]').on("change", _hasValue);
            $('#campaignForm [name="lastName"]').on("change", _hasValue);
            $("#campaignForm #phone").on("change", _checkPhone);
            $("#campaignForm #phone").mask("YZZ(A00) A00-0000", {
                translation: {
                    A: {
                        pattern: /[2-9]/,
                    },
                    Z: {
                        pattern: /[0-1 ]/,
                        optional: true,
                    },
                    Y: {
                        pattern: /[+ ]/,
                        optional: true,
                    },
                },
            });
            $("#campaignForm #bestTimeToCall").on("change", _hasValue);
            $("#campaignForm #email").on("change", _checkEmail);
            $("#campaignForm #month").on("change", _validateAge);
            $("#campaignForm #month").on("change", _populateDaysDropdown);
            $("#campaignForm #day").on("change", _validateAge);
            $("#campaignForm #year").on("change", _populateDaysDropdown);
            $("#campaignForm #year").on("change", _validateAge);
            $("#campaignForm #streetAddress").on("change", _hasValue);
            $("#campaignForm #city").on("change", _hasValue);
            $("#campaignForm #state").on("change", _hasValue);
            $("#campaignForm #zip").on("blur", _validateZip);
            $("#campaignForm #gender").on("change", _validateGender);
            $('#campaignForm [name="isMember"]').on("change", _validateIsMember);
            $("#campaignForm #ft").on("change", _validateFeet);
            $("#campaignForm #in").on("change", _validateInch);
            $("#campaignForm #weight").on("change", _hasValue);
            $("#campaignForm #termLength").on("change", _validateTermLength);
            $("#campaignForm #coverageAmt").on("change", _validateCoverageAmt);
        }
    }

    function _validateExpertForm(e) {
        var isValid;
        var $firstNameField = $('#talkToAnExpertForm [name="firstName"]');
        var $lastNameField = $('#talkToAnExpertForm [name="lastName"]');
        var $phoneField = $("#talkToAnExpertForm #phone");
        var $emailField = $("#talkToAnExpertForm #email");
        var $zipField = $("#talkToAnExpertForm #zip");
        var $interestedInContainer = $("#talkToAnExpertForm .interestedIn");
        var topOfPanel;

        _expertFormDataValid.firstName = _hasValue.call($firstNameField, null, true);
        _expertFormDataValid.lastName = _hasValue.call($lastNameField, null, true);
        _expertFormDataValid.phoneFormat = _validatePhoneNumber.call($phoneField, null, true);
        _expertFormDataValid.emailFormat = _checkEmail.call($emailField, null, true);
        _expertFormDataValid.birthdate = _validateAge("showRed");
        _expertFormDataValid.zipFormat = _checkZip.call($zipField, null, true);
        _expertFormDataValid.interestedIn = _atLeastOneChecked.call($interestedInContainer);

        var zipConditions;
        aaalife.utils.validate.zip($zipField.val()).then(
            function (data) {
                zipConditions = data;
            },
            function (data) {},
        );

        var annuities = $('input[id="interestedInAnnuities"]:checked').val();
        var travelAccident = $("input#interestedInTravelAccident:checked").val();
        var annuitiesInterestedIn = $('input[id="interestedIn"]').val();

        if (typeof zipConditions != "undefined") {
            if (zipConditions.annuitiesNotOffered) {
                if (annuities == "Annuities" || annuitiesInterestedIn == "Annuities") {
                    _expertFormDataValid.zipFormat = false;
                    _highlightInvalidField(false, $zipField);
                    _showErrorModal(aaalife.fromServer.errorModalsData.annuitiesNotOffered);
                }
            }

            if (zipConditions.travelAccidentNotOffered) {
                if (travelAccident === "TravelAccident" || annuitiesInterestedIn === "TravelAccident") {
                    _expertFormDataValid.zipFormat = false;
                    _highlightInvalidField(false, $zipField);
                    _showErrorModal(aaalife.fromServer.errorModalsData.allTermNotOffered);
                }
            }

            if (zipConditions.allTermNotOffered) {
                _expertFormDataValid.zipFormat = false;
                _highlightInvalidField(false, $zipField);
                _showErrorModal(aaalife.fromServer.errorModalsData.allTermNotOffered);
                var params = retrieveUrlParameters();
                getClubAndCampaignProfile($zipField.val(), params.cmp, params.lead)
                    .then(updateDynamicPhone)
                    .catch((error) => console.error("Error updating phone", error));
            }
        }

        isValid = Object.keys(_expertFormDataValid).every(function (key) {
            return _expertFormDataValid[key];
        });

        var element = document.getElementById("invalidData");
        if (element != null) {
            element.parentNode.removeChild(element);
        }

        if (isValid) {
            var user = browserStorage.getObject("user");
            if (!user) {
                user = aaalife.data.user || {};
            }

            user.firstName = $("#firstName").val();
            user.lastName = $("#lastName").val();
            user.dobDay = $("#day").val() || user.dobDay;
            user.dobMonth = $("#month").val() || user.dobMonth;
            user.dobYear = $("#year").val() || user.dobYear;

            var phone = parseUnformatted10DigitPhone($("#phone").val());
            checkAndSetPhoneSource(phone);
            user.phone = phone;
            checkAndSetEmailSource($("#email").val());
            user.email = $("#email").val();
            user.zip = $("#zip").val();

            aaalife.data.user = user;
            browserStorage.setObject("user", aaalife.data.user);

            // Trigger custom event so the geolocation can update if needed
            $(document).trigger("userZipChanged");

            // TODO: Submit

            // Show the confirmation panel
            e.preventDefault();
            $("#talkToAnExpertForm").addClass("hide");
            $(".talkToAnExpert-confirmation").removeClass("hide");

            topOfPanel = $(".talkToAnExpert-confirmation").offset.top - 100;
            if (window.scrollY > topOfPanel) {
                window.scrollTo(0, topOfPanel);
            }

            var itc = $("#talkToAnExpertForm").data("itc");
            $("#submitExpertForm").data("itc", "");
            $("#campaignForm").append(
                '<input type="hidden" name="attr-source" value="' + $("#campaignForm").data("sourcePrime") + '">',
            );
            var attrData = $("#submitExpertForm").data();
            attrData = appendData(attrData, "itc", itc);
            attrData = appendData(attrData, "specialAction", "sf-i");
            attrData = allToString(attrData);
            $("#talkToAnExpertForm").append('<input type="hidden" id="invalidData" name="invalidData" value="false">');

            const response = sendData(collectFormData($("#talkToAnExpertForm"), attrData), false);
            if (response.status === 401) {
                // HACK: For now until we remove use of server side sessions we redirect
                // the user to /session-expired page in the event of a failed expert request.
                window.location.href = "/session-expired";
            } else if (response.status != 200) {
                window.location.href = "/unexpected-error";
            } else {
                sendEvent("TTAC");
            }
        } else {
            // form is invalid, display errors and allow user to fix
            var form = $(this).closest("form");
            var button = $(this).closest("button");
            e.preventDefault();
            $(".inlineValidation").toggleClass("show", true);
            $("#talkToAnExpertForm").append('<input type="hidden" id="invalidData" name="invalidData" value="true">');
            var formData = collectFormData(form, button.data());
            sendData(formData, false);
        }
    }

    function _validateCampaignForm(e) {
        var isValid;
        var $firstNameField = $('#campaignForm [name="firstName"]');
        var $lastNameField = $('#campaignForm [name="lastName"]');
        var $phoneField = $("#campaignForm #phone");
        var $emailField = $("#campaignForm #email");
        var $zipField = $("#campaignForm #zip");
        var $streetAddress = $("#campaignForm #streetAddress");
        var $city = $("#campaignForm #city");
        var $state = $("#campaignForm #state");
        var $weight = $("#campaignForm #weight");
        var $gender = $("#campaignForm #gender");
        var $isMemberInputs = $('#campaignForm [name="isMember"]');
        var $bestTimeToCall = $("#campaignForm #bestTimeToCall");
        var $feet = $("#campaignForm #feet");
        var $inch = $("#campaignForm #inches");
        var $termLength = $("#campaignForm #termLength");
        var $coverageAmt = $("#campaignForm #coverageAmount");
        var topOfPanel;

        _campaignFormDataValid.zipFormat;
        _campaignFormDataValid.firstName = _hasValue.call($firstNameField, null, true);

        _campaignFormDataValid.lastName = _hasValue.call($lastNameField, null, true);
        _campaignFormDataValid.phoneFormat = _validatePhoneNumber.call($phoneField, null, true);
        _campaignFormDataValid.emailFormat = _checkEmail.call($emailField, null, true);
        _campaignFormDataValid.bestTimeToCall = _validateBestTimeToCall.call($bestTimeToCall, null, true);
        _campaignFormDataValid.birthdate = _validateAge("showRed");
        _campaignFormDataValid.streetAddress = _hasValue.call($streetAddress, null, true);
        _campaignFormDataValid.city = _hasValue.call($city, null, true);
        _campaignFormDataValid.state = _validateState.call($state, null, true);
        _campaignFormDataValid.gender = _validateGender.call($gender, null, true);
        if ($isMemberInputs.length > 0) {
            _campaignFormDataValid.isMember = _validateIsMember.call($isMemberInputs, null, true);
        }
        _campaignFormDataValid.height = _validateFeet.call($feet, null, true) & _validateInch.call($inch, null, true);
        _campaignFormDataValid.weight = _hasValue.call($weight, null, true);
        _campaignFormDataValid.termLength = _validateTermLength.call($termLength, null, true);
        _campaignFormDataValid.coverageAmount = _validateCoverageAmt.call($coverageAmt, null, true);

        isValid = Object.keys(_campaignFormDataValid).every(function (key) {
            return _campaignFormDataValid[key];
        });

        var element = document.getElementById("invalidData");
        if (element != null) {
            element.parentNode.removeChild(element);
        }

        if (isValid) {
            var user = browserStorage.getObject("user");
            if (!user) {
                user = aaalife.data.user || {};
            }

            user.firstName = $("#firstName").val();
            user.lastName = $("#lastName").val();
            const phone = parseUnformatted10DigitPhone($("#phone").val());
            user.phone = phone;
            user.email = $("#email").val();
            user.zip = $("#zip").val();
            user.heightFeet = $("#feet").val();
            user.heightInches = $("#inches").val();
            user.weight = $("#weight").val();
            user.city = $("#city").val();
            user.state = $("#state").val();
            user.gender = $("#gender").val();
            user.dobDay = $("#day").val() || user.dobDay;
            user.dobMonth = $("#month").val() || user.dobMonth;
            user.dobYear = $("#year").val() || user.dobYear;
            const checkIsMemberRadio = document.querySelector('#campaignForm [name="isMember"]:checked');
            if (checkIsMemberRadio) {
                user.isMember = checkIsMemberRadio.value || user.isMember;
            }

            aaalife.data.user = user;
            browserStorage.setObject("user", aaalife.data.user);

            if (document.location.href.match(/campaigns\/term-quote\/05/)) {
                try {
                    if (!isUserAQualityLead(user, new Date(), getOverrides())) {
                        e.preventDefault();
                        // TODO: Navigate user to new media-alpha page
                        document.location.href = "/campaigns/term-quote/quote-request-other-options";
                        return;
                    }
                } catch (error) {
                    console.error(error);
                    console.warn(
                        "Unable to determine lead quality so we'll present the default UX. " +
                            "Check to ensure that the corresponding inputs are present on this form.",
                    );
                }
            }

            var itc = $("#campaignForm").data("itc");
            if (itc != "") {
                $("#campaignForm").append('<input type="hidden" name="interactionCode" value="' + itc + '">');
            }
            $("#campaignForm").append('<input type="hidden" id="invalidData" name="invalidData" value="false">');
            $("#campaignForm").append(
                '<input type="hidden" name="attr-source" value="' + $("#campaignForm").data("sourcePrime") + '">',
            );
            $("#campaignForm").append('<input type="hidden" name="attr-specialAction" value="sf">');
        } else {
            // form is invalid, display errors and allow user to fix
            e.preventDefault();
            $(".inlineValidation").toggleClass("show", true);
            if (!_campaignFormDataValid.zipFormat) {
                _highlightInvalidField(_campaignFormDataValid.zipFormat, $("#zip"));
            }
            topOfPanel = $(".campaignCmpnt-heading").offset();
            if (typeof topOfPanel !== "undefined") {
                topOfPanel.top - 100;
                if (window.scrollY > topOfPanel) {
                    window.scrollTo(0, topOfPanel);
                }
            }

            $("#campaignForm").append('<input type="hidden" id="invalidData" name="invalidData" value="true">');
            var attrData = transformData("source", $("#campaignForm").data("sourcePrime"));
            const response = sendData(collectFormData($("#campaignForm"), attrData), false);
            if (response.status === 401) {
                // HACK: For now until we remove use of server side sessions we redirect
                // the user to /session-expired page in the event of a failed expert request.
                window.location.href = "/session-expired";
            } else if (response.status != 200) {
                window.location.href = "/unexpected-error";
            }
        }
    }

    function _productAvailableForState() {
        var termLifeBoxChecked = $("input#interestedInTermLifeInsurance:checked").length > 0;
        var wholeLifeBoxChecked = $("input#interestedInWholeLifeInsurance:checked").length > 0;
        var universalLifeBoxChecked = $("input#interestedInUniversalLifeInsurance:checked").length > 0;
        var travelBoxChecked = $("input#interestedInTravelAccident:checked").length > 0;
        var annuitiesBoxChecked = $("input#interestedInAnnuities:checked").length > 0;
        var $zip = $("#talkToAnExpertForm #zip");
        var zip = $zip.val();
        var valueMissing = aaalife.utils.validate.missingValue($zip);

        var zipDeferred = aaalife.utils.validate.zip(zip);

        if (!valueMissing) {
            _enableFields();
        }
        zipDeferred.then(_zipSuccess, _zipError);

        function _zipSuccess(data) {
            if (data.allTermNotOffered) {
                if (termLifeBoxChecked) {
                    _errorAndDisable();
                    _showErrorModal(aaalife.fromServer.errorModalsData.termLifeNotOffered);
                }
                if (wholeLifeBoxChecked) {
                    _errorAndDisable();
                    _showErrorModal(aaalife.fromServer.errorModalsData.wholeLifeNotOffered);
                }
                if (universalLifeBoxChecked) {
                    _errorAndDisable();
                    _showErrorModal(aaalife.fromServer.errorModalsData.universalLifeNotOffered);
                }
                if (travelBoxChecked) {
                    _errorAndDisable();
                    _showErrorModal(aaalife.fromServer.errorModalsData.travelAccidentNotOffered);
                }
            }

            if (data.travelAccidentNotOffered && travelBoxChecked) {
                _errorAndDisable();
                _showErrorModal(aaalife.fromServer.errorModalsData.allTermNotOffered);
            }

            if (annuitiesBoxChecked && data.annuitiesNotOffered) {
                _errorAndDisable();
                _showErrorModal(aaalife.fromServer.errorModalsData.annuitiesNotOffered);
            }
        }

        function _zipError() {
            // _showErrorModal(aaalife.fromServer.errorModalsData.zipInvalid);
        }
    }
    var $allFields = $("#talkToAnExpertForm input, #talkToAnExpertForm select, #talkToAnExpertForm button");

    function _errorAndDisable() {
        _disableFieldsExceptZipAndInterestIn();
        _showInlineError(true);
    }
    function _disableFieldsExceptZipAndInterestIn() {
        var $termLife = $("#talkToAnExpertForm input[name=interestedInTermLifeInsurance]");
        var $wholeLife = $("#talkToAnExpertForm input[name=interestedInWholeLifeInsurance]");
        var $universalLife = $("#talkToAnExpertForm input[name=interestedInUniversalLifeInsurance]");
        var $travelAccident = $("#talkToAnExpertForm input[name=interestedInTravelAccident]");
        var $annuities = $("#talkToAnExpertForm input[name=interestedInAnnuities]");
        var $zipField = $("#talkToAnExpertForm #zip");

        $allFields.attr("disabled", "disabled");
        $termLife.removeAttr("disabled").addClass("invalid");
        $wholeLife.removeAttr("disabled").addClass("invalid");
        $universalLife.removeAttr("disabled").addClass("invalid");
        $travelAccident.removeAttr("disabled").addClass("invalid");
        $annuities.removeAttr("disabled").addClass("invalid");
        $zipField.removeAttr("disabled").addClass("invalid");
    }
    function _enableFields() {
        _showInlineError(false);
        $allFields.removeAttr("disabled").removeClass("invalid");
    }

    /**
     * _checkEmail
     *
     * @returns {boolean|*}
     * @private
     */
    function _checkEmail(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            var email = $input.val();

            isValid = aaalife.utils.validate.emailFormat(email, true);
            _expertFormDataValid.emailFormat = isValid;
            _campaignFormDataValid.emailFormat = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }
            if (isValid) {
                checkAndSetEmailSource($input.val());
            }

            return isValid;
        }
    }

    function _checkPhone(e, shouldValidate) {
        var $input = $(this);
        const phone = parseUnformatted10DigitPhone($input.val());

        if (aaalife.utils.validate.phoneFormat(phone)) {
            checkAndSetPhoneSource(phone);
            $input.val(format10DigitNanpaPhone(phone));
            return true;
        } else {
            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return false;
        }
    }

    /**
     * _validateAge
     *
     * @param colorFlag
     * @private
     */
    function _validateAge(colorFlag) {
        var day = $("#day").val();
        var month = $("#month").val();
        var year = $("#year").val();

        _campaignFormDataValid.birthdate = day !== "" && month !== "" && year !== "";
        _expertFormDataValid.birthdate = _campaignFormDataValid.birthdate;

        [$("#day"), $("#month"), $("#year")].forEach(function ($el) {
            if (!$el.val() && colorFlag === "showRed") {
                $el.removeClass("valid").addClass("invalid");
                _campaignFormDataValid.birthdate = false;
                _expertFormDataValid.birthdate = false;
            } else if (!!$el.val()) {
                $el.removeClass("invalid").addClass("valid");
                if (day !== "" && month !== "" && year !== "") {
                    _showInlineError(false);
                }
            }
        });
        return _campaignFormDataValid.birthdate;
    }

    /**
     * _checkZip
     *
     * @returns {boolean}
     * @private
     */
    function _checkZip(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            var zip = $input.val();

            isValid = ValidateUtils._zipCodeCDSValidation(zip);
            _expertFormDataValid.zipFormat = isValid;
            _campaignFormDataValid.zipFormat = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return isValid;
        }
    }

    function _validateZip(e) {
        var validData;
        var $zip = $("#zip");
        var zip = $zip.val();
        var valueMissing = aaalife.utils.validate.missingValue($zip);
        var validZip = ValidateUtils._zipCodeCDSValidation(zip);
        var $allFields = $(
            "#campaignForm input, #campaignForm select, #campaignForm button, #talkToAnExpertForm input, #talkToAnExpertForm select, #talkToAnExpertForm button",
        );

        if (e && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }

        zip = $zip.val(zip.replace(/[^\d\-].+/, "")).val();
        validData = !valueMissing && validZip;
        _campaignFormDataValid.zipFormat = validZip;
        _highlightInvalidField(true, $zip);

        aaalife.utils.validate.zip(zip).then(_zipValidationComplete, _zipValidationError);

        return validData;
    }

    function _zipValidationComplete(data) {
        // if(!aaalife.utils.prevalidate.hasValidZip(aaalife.utils.prevalidate.zipConditions.zip,
        // aaalife.utils.prevalidate.zipName)) {
        // return;
        // }
        // aaalife.utils.prevalidate.storeZipObj(aaalife.utils.prevalidate.zipConditions.zip,
        // aaalife.utils.prevalidate.zipName);

        var $allFields = $(
            "#campaignForm input, #campaignForm select, #campaignForm button, #talkToAnExpertForm input, #talkToAnExpertForm select, #talkToAnExpertForm button",
        );
        var $zip = $("#zip");
        var zip = $zip.val();
        var valueMissing = aaalife.utils.validate.missingValue($zip);
        var validZip = aaalife.utils.validate.zipFormat(zip);
        const _zipRestrictions = data;

        if (_zipRestrictions.allTermNotOffered) {
            _disableFieldsExceptZip();
            _showInlineError(true);
            _showErrorModal(aaalife.fromServer.errorModalsData.allTermNotOffered);

            var params = retrieveUrlParameters();
            getClubAndCampaignProfile(zip, params.cmp, params.lead)
                .then(updateDynamicPhone)
                .catch((error) => console.error("Error updating phone", error));

            sendEvent("TTAKO");

            _campaignFormDataValid.zipFormat = false;
            _applyKnockOut("y", false);
        } else {
            const validData = !valueMissing && validZip;
            _campaignFormDataValid.zipFormat = validData;
            if ($allFields.attr("disabled")) {
                _enableFields();
            }
            _applyKnockOut("n", false);
        }

        function _disableFieldsExceptZip() {
            var $zipFieldCampaign = $("#campaignForm input[name=zip]");
            var $zipFieldExpert = $("#talkToAnExpertForm input[name=zip]");

            $allFields.attr("disabled", "disabled");
            $zipFieldCampaign.removeAttr("disabled").addClass("invalid");
            $zipFieldExpert.removeAttr("disabled").addClass("invalid");
        }

        function _enableFields() {
            $allFields.removeAttr("disabled").removeClass("invalid");
        }
    }

    function _zipValidationError() {
        const validData = false;
        _campaignFormDataValid.zipFormat = validData;
    }

    function _applyKnockOut(value, async) {
        if (aaalife.utils.prevalidate.isSameKOStatus(value)) {
            return;
        }
        var attrData = transformData("itc", "");
        attrData = appendData(attrData, "ko", value);
        var groupData = transformData("attr", attrData);
        sendData(groupData, async);
    }

    /**
     * _validatePhoneNumber
     *
     * @returns {*}
     * @private
     */
    function _validatePhoneNumber(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            const phone = parseUnformatted10DigitPhone($input.val());
            isValid = aaalife.utils.validate.phoneFormat(phone);
            _expertFormDataValid.phoneFormat = isValid;
            _campaignFormDataValid.phoneFormat = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }
            if (isValid) {
                checkAndSetPhoneSource(phone);
            }

            return isValid;
        }
    }

    function _validateGender(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            isValid = aaalife.utils.validate.gender($input);
            _campaignFormDataValid.gender = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return isValid;
        }
    }

    function _validateIsMember(e) {
        var isMemberValid = aaalife.utils.validate.validateIsMember($(this));

        _highlightInvalidField(isMemberValid, $('[name="isMember"]'));

        return isMemberValid;
    }

    function _validateBestTimeToCall(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            isValid = aaalife.utils.validate.bestTimeToCall($input);
            _campaignFormDataValid.bestTimeToCall = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return isValid;
        }
    }
    function _validateState(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            isValid = aaalife.utils.validate.state($input);
            _campaignFormDataValid.state = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return isValid;
        }
    }
    function _validateFeet(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            isValid = aaalife.utils.validate.heightFeet($input);
            _campaignFormDataValid.feet = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return isValid;
        }
    }
    function _validateInch(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            isValid = aaalife.utils.validate.heightInches($input);
            isValid = !aaalife.utils.validate.missingValue($input);
            _campaignFormDataValid.inch = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return isValid;
        }
    }
    function _validateTermLength(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            isValid = aaalife.utils.validate.termLength($input);
            _campaignFormDataValid.termLength = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return isValid;
        }
    }
    function _validateCoverageAmt(e, shouldValidate) {
        var isValid;
        var $input = $(this);

        if ($input.length === 0) {
            isValid = true;
            return isValid;
        } else {
            isValid = aaalife.utils.validate.coverageAmt($input);
            _campaignFormDataValid.coverageAmt = isValid;

            if (shouldValidate) {
                _highlightInvalidField(isValid, $input);
            } else {
                _highlightInvalidField(true, $input);
            }

            return isValid;
        }
    }

    /**
     * _hasValue
     *
     * @returns {boolean}
     * @private
     */
    function _hasValue(e, shouldValidate) {
        var $el = $(this);

        if ($el.length === 0) {
            isValid = true;
            return isValid;
        } else {
            var isValid = !aaalife.utils.validate.missingValue($el);

            if (shouldValidate) {
                _highlightInvalidField(isValid, $el);
            } else {
                _highlightInvalidField(true, $el);
            }
            return isValid;
        }
    }

    /**
     * _atLeastOneChecked - check that at least one of the given checkboxes is
     * selected (if visible)
     *
     * @returns {boolean}
     * @private
     */
    function _atLeastOneChecked() {
        var isValid = false;
        var $checkboxes = $(this).find("input[type=radio]:visible");

        if ($checkboxes.length === 0) {
            isValid = true;
            return isValid;
        }

        if ($checkboxes.is(":checked")) {
            isValid = true;
        }

        _highlightInvalidField(isValid, $checkboxes);

        return isValid;
    }

    function _populateDropdowns(dropdown, startVal, maxVal, dirMod) {
        var i;
        for (i = startVal; startVal > maxVal ? i >= maxVal : i <= maxVal; i += dirMod) {
            dropdown.append($("<option></option>").val(i).html(i));
        }
    }

    function _populateDaysDropdown() {
        var month = $("#month").val() * 1 || new Date().getMonth();
        var year = $("#year").val() * 1 || new Date().getFullYear();
        var $day = $("#day");
        var oldDay = $day.val();
        var numDays = new Date(year, month, 0).getDate();

        $day.find("option").not(":first-child").remove();

        _populateDropdowns($day, 1, numDays, 1);

        if (oldDay && oldDay <= numDays) {
            $day.val(oldDay);
        } else if ($day.hasClass("dirty") || $day.hasClass("pristine")) {
            $day.val("");
            if ($day.hasClass("dirty") && oldDay) {
                $day.removeClass("valid").addClass("invalid");
            }
            _campaignFormDataValid.birthdate = false;
            _expertFormDataValid.birthdate = false;
        }
    }

    function _markDirty() {
        $(this).removeClass("pristine").addClass("dirty");
    }

    /**
     * _highlightInvalidField
     *
     * @param isValid
     * @param $argElement
     * @private
     */
    function _highlightInvalidField(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            _showInlineError(false);
            $element.removeClass("invalid").addClass("valid");
        } else {
            _showInlineError(true);
            $element.removeClass("valid").addClass("invalid");
        }
    }

    /**
     * _showInlineError
     *
     * @param show
     * @private
     */
    function _showInlineError(show) {
        $(".inlineValidation").toggleClass("show", show);
    }

    function _showErrorModal(text) {
        $(".error-modal .error-modal-heading").html(text.heading);
        $(".error-modal .error-desc").html(text.desc);
        $(".error-modal .error-secondarydesc").html(text.desc2);

        _inlineModal.open();
    }
}
