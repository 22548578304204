import $ from "jquery";
import { sendData, appendData } from "../collector";
import { browserStorage } from "./aaalife-utils-browser-storage";

export function agentQuoter() {
    if ($("#agentQuoteForm").length > 0) {
        $(init);
    }

    function init() {
        _populateYearOptions();
        _populateAllAgentQuoteValuesIfSaved();
        _initEventListeners();
    }

    function _populateYearOptions() {
        const yearSelect = document.getElementById("year");
        if (!yearSelect) {
            // Skip populating years on agent-quoter pages without #year.
            return;
        }

        const minYear = new Date().getFullYear() - 17;
        for (let yearDifference = 0; yearDifference < 74; yearDifference++) {
            let year = minYear - yearDifference;
            yearSelect.options.add(new Option(year, year));
        }
    }

    function _initEventListeners() {
        $("#quoteInputSubmit").on("click", _saveAgentQuoteInputValues);
        $("#quoteResultsNext").on("click", _saveAgentQuoteResultsValues);
        $("#quoteResultsNext").on("click", _submitAgentQuoteResults);
        $("#quoteResultsPrevious").on("click", _saveAgentQuoteResultsValues);
        $("#quoteSaveSubmit").on("click", _saveAgentQuoteSaveValues);
        $(".startNewQuote").on("click", _clearSavedData);
        $("#startNewQuote").on("click", _clearSavedData);

        // window.addEventListener('click', _setAgentQuoteTimeout);
        // window.addEventListener('keyup', _setAgentQuoteTimeout);
    }

    function _populateAllAgentQuoteValuesIfSaved() {
        _populateAgentQuoteInputValuesIfSaved();
        _populateQuoteResultsUserValues();
        _populateAgentQuoteSaveValuesIfSaved();
        _populateAgentQuotePreviewValuesIfSaved();
        _populateAgentQuoteConfirmationValuesIfSaved();
        if ($("#agentQuotePrint").length > 0) {
            _populateAgentQuotePrintValuesIfSaved();
        }
    }

    function _populateAgentQuoteInputValuesIfSaved() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;

        $("#state").val(agentQuote.state).trigger("change");
        $("#gender").val(agentQuote.gender).trigger("change");
        $("#day").val(agentQuote.dobDay).trigger("change");
        $("#month").val(agentQuote.dobMonth).trigger("change");
        $("#year").val(agentQuote.dobYear).trigger("change");
        $("#riskClass").val(agentQuote.riskClass).trigger("change");
        $("#isMember").val(agentQuote.isMember).trigger("change");
    }

    function _getAgentQuoteData() {
        var agentQuote = browserStorage.getObject("agentQuote");
        if (!agentQuote) {
            agentQuote = aaalife.data.agentQuote || {};
        }
        return agentQuote;
    }

    function _saveAgentQuoteInputValues() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;
        delete agentQuote.quoteResults.quoteResultsString;
        delete agentQuote.quoteResults.quoteResultsStringROP;

        agentQuote.state = $("#state").val();
        agentQuote.gender = $("#gender").val();
        agentQuote.dobDay = $("#day").val();
        agentQuote.dobMonth = $("#month").val();
        agentQuote.dobYear = $("#year").val();
        agentQuote.riskClass = $("#riskClass").val();
        agentQuote.isMember = $("#isMember").val();

        browserStorage.setObject("agentQuote", aaalife.data.agentQuote);

        _collectAndSendInputData();
    }

    function _populateQuoteResultsUserValues() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;
        var isAaaMember = "";
        if (agentQuote.isMember == "Yes") {
            isAaaMember = "Member";
        } else {
            isAaaMember = "Non-Member";
        }
        var formattedDay = agentQuote.dobDay < 10 ? "0" + agentQuote.dobDay : agentQuote.dobDay;
        var formattedMonth = agentQuote.dobMonth < 10 ? "0" + agentQuote.dobMonth : agentQuote.dobMonth;
        var informationString =
            agentQuote.gender +
            ", " +
            agentQuote.riskClass +
            ", " +
            "Date of Birth: " +
            formattedMonth +
            "/" +
            formattedDay +
            "/" +
            agentQuote.dobYear +
            ", " +
            isAaaMember;

        $("#specs").text(informationString);
        $("#coverageAmt").text(_formatAsDollarAmount(agentQuote.quoteResults.coverageAmount));
    }

    function _formatAsDollarAmount(amount) {
        var dollarAmount = "$" + amount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return dollarAmount;
    }

    function _saveAgentQuoteResultsValues() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;

        agentQuote.quoteResults.quoteResultsString = results;
        agentQuote.quoteResults.quoteResultsStringROP = resultsROP;
        agentQuote.quoteResults.coverageAmount = $("#coverageAmount").val();

        var counter = 0;
        $(".monthlyPremiums td").each(function () {
            if (counter === 1) {
                agentQuote.quoteResults.premiumAmount10Year = $(this).text();
            } else if (counter === 2) {
                agentQuote.quoteResults.premiumAmount15Year = $(this).text();
            } else if (counter === 3) {
                agentQuote.quoteResults.premiumAmount20Year = $(this).text();
            } else if (counter === 4) {
                agentQuote.quoteResults.premiumAmount25Year = $(this).text();
            } else if (counter === 5) {
                agentQuote.quoteResults.premiumAmount30Year = $(this).text();
            }
            counter++;
        });

        counter = 0;
        $(".monthlyPremiumsWithROP td").each(function () {
            if (counter === 1) {
                agentQuote.quoteResults.ropPremiumAmount10Year = $(this).text();
            } else if (counter === 2) {
                agentQuote.quoteResults.ropPremiumAmount15Year = $(this).text();
            } else if (counter === 3) {
                agentQuote.quoteResults.ropPremiumAmount20Year = $(this).text();
            } else if (counter === 4) {
                agentQuote.quoteResults.ropPremiumAmount30Year = $(this).text();
            }
            counter++;
        });

        browserStorage.setObject("agentQuote", aaalife.data.agentQuote);

        _collectAndSendResultsData();
    }

    function _submitAgentQuoteResults() {
        $('form[name="quoteResultsForm"]').submit();
    }

    function _populateAgentQuoteSaveValuesIfSaved() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;

        $("#firstName").val(agentQuote.client.firstName);
        $("#lastName").val(agentQuote.client.lastName);
        $("#phone").val(agentQuote.client.phone);
        $("#email").val(agentQuote.client.email);
        $("#comments1").val(agentQuote.client.messageToMember);

        $("#fname2").val(agentQuote.agent.firstName);
        $("#lname2").val(agentQuote.agent.lastName);
        $("#phone2").val(agentQuote.agent.phone);
        $("#email2").val(agentQuote.agent.email);
    }

    function _saveAgentQuoteSaveValues() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;

        agentQuote.client.firstName = $("#firstName").val();
        agentQuote.client.lastName = $("#lastName").val();
        agentQuote.client.phone = $("#phone").val();
        agentQuote.client.email = $("#email").val();
        agentQuote.client.messageToMember = $("#comments1").val();

        agentQuote.agent.firstName = $("#fname2").val();
        agentQuote.agent.lastName = $("#lname2").val();
        agentQuote.agent.phone = $("#phone2").val();
        agentQuote.agent.email = $("#email2").val();

        browserStorage.setObject("agentQuote", aaalife.data.agentQuote);

        _collectAndSendSaveData();
    }

    function _populateAgentQuotePreviewValuesIfSaved() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;

        $("#coverageAmtPreview").text("Coverage Amount: " + _formatAsDollarAmount(agentQuote.quoteResults.coverageAmount));

        $("#fname1").text(agentQuote.client.firstName);
        $("#lname1").text(agentQuote.client.lastName);
        $("#phone1").text(agentQuote.client.phone);
        $("#email1").text(agentQuote.client.email);
        $("#comments1").text(agentQuote.client.messageToMember);

        $("#fname2").text(agentQuote.agent.firstName);
        $("#lname2").text(agentQuote.agent.lastName);
        $("#phone2").text(agentQuote.agent.phone);
        $("#email2").text(agentQuote.agent.email);
    }

    function _populateAgentQuoteConfirmationValuesIfSaved() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;

        $("#clientEmail").text(agentQuote.client.email);
        $("#firstName").text(agentQuote.client.firstName);
        $("#lastName").text(agentQuote.client.lastName);
        $("#phone").text(agentQuote.client.phone);
        $("#messageToMember").text(agentQuote.client.messageToMember);
    }

    function _populateAgentQuotePrintValuesIfSaved() {
        var agentQuote = _getAgentQuoteData();
        aaalife.data.agentQuote = agentQuote;
        var isAaaMember = "";
        if (agentQuote.isMember == "Yes") {
            isAaaMember = "Member";
        } else {
            isAaaMember = "Non-Member";
        }

        $("#printGender").text(agentQuote.gender);
        $("#printRateClass").text(agentQuote.riskClass);
        $("#printDay").text(agentQuote.dobDay);
        $("#printMonth").text(agentQuote.dobMonth);
        $("#printYear").text(agentQuote.dobYear);
        $("#isAaaMember").text(isAaaMember);

        $("#10YearPremium").text(agentQuote.quoteResults.premiumAmount10Year);
        $("#15YearPremium").text(agentQuote.quoteResults.premiumAmount15Year);
        $("#20YearPremium").text(agentQuote.quoteResults.premiumAmount20Year);
        $("#25YearPremium").text(agentQuote.quoteResults.premiumAmount25Year);
        $("#30YearPremium").text(agentQuote.quoteResults.premiumAmount30Year);
        $("#10YearRopPremium").text(agentQuote.quoteResults.ropPremiumAmount10Year);
        $("#15YearRopPremium").text(agentQuote.quoteResults.ropPremiumAmount15Year);
        $("#20YearRopPremium").text(agentQuote.quoteResults.ropPremiumAmount20Year);
        $("#30YearRopPremium").text(agentQuote.quoteResults.ropPremiumAmount30Year);

        $("#clientFirstName").text(agentQuote.client.firstName);
        $("#clientLastName").text(agentQuote.client.lastName);
        $("#clientPhone").text(agentQuote.client.phone);
        $("#clientMessage").text(agentQuote.client.messageToMember);

        $("#agentFirstName").text(agentQuote.agent.firstName);
        $("#agentLastName").text(agentQuote.agent.lastName);
        $("#agentPhone").text(agentQuote.agent.phone);

        $("body").addClass("printFriendly printAgentQuotePage");
    }

    function _clearSavedData() {
        var agentQuote = {
            state: "Select One",
            gender: "",
            dobMonth: "MM",
            dobDay: "DD",
            dobYear: "YYYY",
            riskClass: "Select One",
            quoteResults: {
                coverageAmount: "500000",
                quoteResultsString: "",
                premiumAmount10Year: "",
                premiumAmount15Year: "",
                premiumAmount20Year: "",
                premiumAmount25Year: "",
                premiumAmount30Year: "",
                quoteResultsStringROP: "",
                ropPremiumAmount10Year: "",
                ropPremiumAmount15Year: "",
                ropPremiumAmount20Year: "",
                ropPremiumAmount30Year: "",
            },
            client: {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                messageToMember: "",
            },
            agent: {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
            },
        };

        aaalife.data.agentQuote = agentQuote;

        browserStorage.setObject("agentQuote", aaalife.data.agentQuote);

        var validation = {};
        aaalife.data.validation = validation;

        browserStorage.setObject("validation", aaalife.data.validation);
    }

    function _collectAndSendInputData() {
        var currentData = {};
        currentData = appendData(currentData, "state", $("#state").val());
        currentData = appendData(currentData, "gender", $("#gender").val());
        currentData = appendData(currentData, "month", $("#month").val());
        currentData = appendData(currentData, "day", $("#day").val());
        currentData = appendData(currentData, "year", $("#year").val());
        currentData = appendData(currentData, "riskClass", $("#riskClass").val());
        var formData = {};
        formData = appendData(formData, "current", currentData);
        sendData(formData, false);
    }

    function _collectAndSendResultsData() {
        var currentData = {};
        currentData = appendData(currentData, "coverageAmount", $("#coverageAmt").text());

        var formData = {};
        formData = appendData(formData, "current", currentData);
        sendData(formData, false);
    }

    function _collectAndSendSaveData() {
        var currentData = {};
        currentData = appendData(currentData, "firstName", $("#firstName").val());
        currentData = appendData(currentData, "lastName", $("#lastName").val());
        currentData = appendData(currentData, "phone", $("#phone").val());
        currentData = appendData(currentData, "email", $("#email").val());
        currentData = appendData(currentData, "comments1", $("#comments1").val());
        currentData = appendData(currentData, "fname2", $("#fname2").val());
        currentData = appendData(currentData, "lname2", $("#lname2").val());
        currentData = appendData(currentData, "phone2", $("#phone2").val());
        currentData = appendData(currentData, "email2", $("#email2").val());

        var formData = {};
        formData = appendData(formData, "current", currentData);
        sendData(formData, false);
    }

    function _removeNonNumericChars(premValue) {
        if (premValue != null) {
            return premValue.replace(/[^0-9.]/g, "");
        }
    }
}
