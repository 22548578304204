import $ from "jquery";
import { Foundation, MediaQuery } from "foundation-sites";

var $body = $("body");

function Modal(options) {
    var self = this;

    self._options = $.extend(
        {
            className: null,
            wrapperClass: null,
            id: null,
            content: null,
            speed: 333,
            fullscreen: false,
            styles: null,
            triggerEl: null,
        },
        options,
        true,
    );

    self._isOpen = false;
    self.__id =
        Math.floor(Math.random() * 9999) +
        "-" +
        Math.floor(Math.random() * 9999) +
        "-" +
        Math.floor(Math.random() * 9999) +
        "-" +
        Math.floor(Math.random() * 9999);

    self._$wrapper = $('<div class="modal-window--wrapper"></div>');
    if (self._options.styles) {
        self._$wrapper.attr("style", self._options.styles);
    }
    if (self._options.wrapperClass) {
        self._$wrapper.addClass(self._options.wrapperClass);
    }

    self._$container = $('<div class="modal-window"></div>')
        .attr("id", this._options.id)
        .addClass(this._options.className)
        .append(self._$wrapper);

    self.$contentArea = $('<div class="modal-window--content-area"></div>').appendTo(self._$wrapper);

    if (self._options.wrapperClass == "alert-box-wrapper" || self._options.wrapperClass == "lightbox-wrapper") {
        self._$closeBtn = $(
            '<a href="#" class="btn modal-window--close-btn"><svg class="icon icon-close"><use xlink:href="' +
                aaalife.closeUrl.close +
                '" /></svg></a>',
        )
            .on("click", function (e) {
                e.preventDefault();
                self.close();
            })
            .appendTo(self._$wrapper);
    } else {
        self._$closeBtn = $(
            '<a href="#" class="btn modal-window--close-btn"><svg class="icon icon-close"><use xlink:href="' +
                aaalife.fromServer.urlStrings.iconUrl +
                '" /></svg></a>',
        )
            .on("click", function (e) {
                e.preventDefault();
                self.close();
            })
            .appendTo(self._$wrapper);
    }

    self._$bg = $('<div class="modal-bg"></div>')
        .css("opacity", 0)
        .on("click", function (e) {
            e.preventDefault();
            self.close();
        });
    if (self._options.triggerEl) {
        aaalife.data.triggerEl = self._options.triggerEl.data("modal-object", self);
    }

    $(window).on(
        "resize",
        Foundation.util.throttle(
            function () {
                self.resize();
            },
            100,
            true,
        ),
    );
}

Modal.prototype.resize = function () {
    var self = this;
    var c = self._$container;
    var w = self._$wrapper;
    var cH, wH;

    if (c.is(":visible")) {
        cH = Math.floor(c.height());
        w.height("auto");
        wH = Math.floor(w.height());

        if (!self._options.fullscreen) {
            self._$container.toggleClass("fullscreen", !Foundation.MediaQuery.atLeast("medium"));
        }

        if (wH >= cH) {
            w.height(cH);
        }
    }
};

Modal.prototype.open = function (content) {
    var self = this;
    var $content = null;

    if (typeof content !== "undefined" && content && content !== this._options.content) {
        self._options.content = content;
    }

    if (self._options.content) {
        $content = self._options.content;
        if (typeof $content === "string") {
            $content = $($content);
        }
    }

    if (self._isOpen || !self._options.content || !$content.length) {
        return false;
    }

    self._$container.removeClass("content-ready");

    $content
        .removeClass("hide")
        .appendTo(self.$contentArea)
        .find(".close-modal")
        .on("click", function (e) {
            e.preventDefault();
            self.close();
        });

    setTimeout(function () {
        $(document).trigger("modal-content-ready", [self]);
        self._$container.addClass("content-ready");
        self.resize();
    }, self._options.speed + 50);

    self._$container.css({
        opacity: 0,
    });

    self._$bg
        .css("background-color", $content.data("bg-color") ? $content.data("bg-color") : null)
        .appendTo($body)
        .animate(
            {
                opacity: 0.8,
            },
            {
                duration: self._options.speed,
                complete: function () {
                    self._$container
                        .prop("open", true)
                        .appendTo($body)
                        .animate(
                            {
                                opacity: 1,
                            },
                            {
                                duration: self._options.speed,
                                complete: function () {
                                    self._isOpen = true;
                                    $(document).trigger("modal-open", [self]);
                                    aaalife.utils.togglePreventScroll(true);
                                },
                            },
                        );
                },
            },
        );

    return self;
};

Modal.prototype.close = function () {
    var self = this;

    self._$container.animate(
        {
            opacity: 0,
        },
        {
            duration: self._options.speed,
            complete: function () {
                self._$container.detach();
                self._$bg.animate(
                    {
                        opacity: 0,
                    },
                    {
                        duration: self._options.speed,
                        complete: function () {
                            self._isOpen = false;
                            self._$bg.detach();
                            $(document).trigger("modal-close", [self]);
                        },
                    },
                );
            },
        },
    );

    aaalife.utils.togglePreventScroll(false);

    // Force re-paint of the page to avoid IE bug
    $(document.body).hide();
    setTimeout(function () {
        $(document.body).show();
    }, 0);

    return self;
};

export { Modal };
