import css from "../../css/popup-story-list/popup-story-list.css";

export function initializePopupStoryList() {
    console.log("initialized item popup list");

    Array.from(document.querySelectorAll(".open-popup-link")).forEach((link) =>
        link.addEventListener("click", (e) => {
            e.preventDefault();

            const popupSelector = link.attributes["href"].value;
            const popup = document.querySelector(popupSelector).cloneNode(true);
            if (!popup) {
                console.error(`Unable to find a popup element with selector '${popupSelector}'.`);
                return;
            }
            const overlay = document.querySelector(".overlay");
            if (!overlay) {
                console.error("Unable to find an overlay element with a class of 'overlay'.");
                return;
            }
            overlay.addEventListener("click", (e) => {
                overlay.childNodes.forEach((child) => child.remove());
                document.body.parentElement.classList.remove("noscroll");
            });
            overlay.classList.add("show");

            overlay.appendChild(popup);
            overlay.scrollTop = 0;
            popup.addEventListener("click", (e) => {
                e.stopPropagation();
            });
            const closeButton = popup.querySelector(".close-button");
            if (!closeButton) {
                console.warn("Unable to find a child element with a class of 'close-button'.");
                return;
            }
            closeButton.addEventListener("click", (e) => {
                overlay.classList.remove("show");
                overlay.childNodes.forEach((child) => child.remove());
                document.body.parentElement.classList.remove("noscroll");
            });
            popup.classList.add("show");
            document.body.parentElement.classList.add("noscroll");
        }),
    );
}
