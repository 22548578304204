function Inline(options) {
    var self = this;

    self._options = $.extend(
        {
            className: null,
            id: null,
            content: null,
            speed: 333,
            styles: null,
            onClose: function () {},
        },
        options,
        true,
    );

    self._$wrapper = $('<div class="inline-window--wrapper"></div>');
    if (self._options.styles) {
        self._$wrapper.attr("style", self._options.styles);
    }
    self._$wrapper.css({
        display: "none",
        opacity: 0,
    });

    // self._$bg = $('<div
    // class="inline-window--bg"></div>').appendTo(self._$wrapper);

    self._$bg = $('<div class="inline-window--bg"></div>')
        .on("click", function (e) {
            e.preventDefault();
            self.close();
        })
        .appendTo(self._$wrapper);

    self._$container = $('<div class="inline-window"></div>')
        .attr("id", self._options.id)
        .addClass(self._options.className)
        .appendTo(self._$wrapper);

    self.$contentArea = $('<div class="inline-window--content-area"></div>').appendTo(self._$container);
    self.$content = $('<div class="inline-window--content"></div>').appendTo(self.$contentArea);
    self.$contentInner = self._options.content;

    self.$contentInner
        .addClass("inline-window--content-inner")
        .removeClass("hide")
        .after(self._$wrapper)
        .appendTo(self.$content);

    self._$closeBtn = $(
        '<a href="#" class="btn inline-window--close-btn"><svg class="icon icon-close"><use xlink:href="' +
            aaalife.fromServer.urlStrings.iconUrl +
            '" /></svg></a>',
    )
        .on("click", function (e) {
            e.preventDefault();
            self.close();
            self._options.onClose();
        })
        .appendTo(self.$contentInner);
}

Inline.prototype.getRecycleTargetNode = function () {
    return $("div.inline-window--wrapper");
};

Inline.prototype.open = function () {
    var self = this;

    self._$wrapper.css("display", "block").animate(
        {
            opacity: 1,
        },
        {
            duration: self._options.speed,
        },
    );

    var recycleTargetNode = this.getRecycleTargetNode();
    recycleTargetNode.css("display", "block").animate(
        {
            opacity: 1,
        },
        {
            duration: self._options.speed,
        },
    );

    return self;
};

Inline.prototype.close = function () {
    var self = this;
    self._$wrapper.animate(
        {
            opacity: 0,
        },
        {
            duration: self._options.speed,
            complete: function () {
                self._options.onClose();
            },
        },
    );

    var recycleTargetNode = this.getRecycleTargetNode();
    recycleTargetNode.animate(
        {
            opacity: 0,
        },
        {
            duration: self._options.speed,
            complete: function () {
                recycleTargetNode.css("display", "none");
            },
        },
    );

    return self;
};

export { Inline };
