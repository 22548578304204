import $ from "jquery";
import { browserStorage } from "./aaalife-utils-browser-storage";

$(_init);

export default {
    initModal: initModal,
    initPushdown: initPushdown,
};

/**
 * _init - initialize Alert(s)
 *
 * @return {void} void
 */
export function _init() {
    if ($(".pushdownAlert").length > 0) {
        initPushdown();
    }
    if ($(".modalAlert").length > 0) {
        initModal();
    }
}

/**
 * initModal - opens a modal alert
 */
function initModal($modalContent) {
    var _modalAlert;

    $modalContent = $modalContent || $(".modalAlert");
    _modalAlert = new aaalife.utils.Modal({
        content: $modalContent.html(),
        styles: $modalContent.data("styles"),
        triggerEl: $modalContent,
    });
    _modalAlert.open();

    $(document).on(
        "modal-close",
        {
            type: "modal",
        },
        _closeBtnClicked,
    );
}

/**
 * initPushdown - opens a pushdown alert
 */
function initPushdown() {
    $(".pushdownAlert-close-btn").on(
        "click",
        {
            type: "pushdown",
        },
        _closeBtnClicked,
    );
}

/**
 * _closeBtnClicked - close button click handler for Push-down Alert
 *
 * @param {Object}
 *            e - jQuery event object
 * @param {Object}
 *            modal - jQuery DOM object (optional)
 * @return {void} void
 */
function _closeBtnClicked(e, modal) {
    var $thisAlert = e.data.type === "pushdown" ? $(e.target).parents(".pushdownAlert") : modal._options.triggerEl;
    var alertID = $thisAlert.attr("id");
    var days = $thisAlert.data("frequency");

    if (e.data.type === "pushdown") {
        e.preventDefault();
        $thisAlert.addClass("dismiss");
    }

    // Create a cookie with the alert's unique ID as its name
    // Existence of this particular cookie indicates the alert has been
    // dismissed
    browserStorage.set(alertID, "", days, true);
}
