import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export function FormModal({
    portalContainer,
    errorHeading,
    errorDescription,
    errorSecondaryDescriptionComponent,
    open,
    onCloseButtonClicked,
    closeIconUrl,
    overlayColor = undefined,
}) {
    const [shouldRender, setShouldRender] = useState(open);
    useEffect(() => setShouldRender(open), [open]);

    const handleOnAnimationEnd = (event) => {
        if (!shouldRender) {
            // Invoke callback to allow parent to handle open state
            onCloseButtonClicked();
        }
    };

    return createPortal(
        open && (
            <div className={`inline-window--wrapper ${shouldRender ? "opened" : ""}`} onAnimationEnd={handleOnAnimationEnd}>
                <div className="inline-window--bg" style={overlayColor && { backgroundColor: overlayColor }} />
                <div className="inline-window">
                    <div className="inline-window--content-area">
                        <div className="inline-window--content">
                            <div className="errorModalRenamed inline-window--content-inner">
                                <div className="error-modal ">
                                    <div>
                                        <h2 className="error-modal-heading">{errorHeading}</h2>
                                        <p className="error-desc">{errorDescription}</p>
                                        {/* <p className="error-secondarydesc" dangerouslySetInnerHTML={sanitizedDescriptionHtml} /> */}
                                        {errorSecondaryDescriptionComponent}
                                    </div>
                                </div>
                                <a href="#" className="btn inline-window--close-btn" onClick={() => setShouldRender(false)}>
                                    <svg className="icon icon-close">
                                        <use xlinkHref={closeIconUrl} />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ),
        portalContainer,
    );
}
