import $ from "jquery";
import { sendData, transformData, appendData } from "../collector";
import { setCookie, getCookie } from "../agentQuoter/clubUtils";

import "whatwg-fetch";
import "classlist-polyfill";
import { browserStorage } from "./aaalife-utils-browser-storage";
import { getClubAndCampaignProfile, updateDynamicPhone } from "../utils/aaalife-dynamic-phone";
import { retrieveUrlParameters } from "../utils/page";

function getDynamicIP() {
    return fetch(ipPath).then((response) =>
        response.json().then((data) => {
            callMData(data);
        }),
    );
}

function callMData(ipVal) {
    const melissaUrl = new URL(ipMelissa);
    melissaUrl.search = melissaUrl.search + "&ip=" + ipVal.ip;
    return fetch(melissaUrl).then((response) =>
        response.json().then((dataResult) => {
            if (dataResult != "undefined") {
                if (hasError(dataResult)) {
                    console.error("Melissa Data has experienced an error: " + dataResult.TransmissionResults);
                    return;
                }

                let ipObj = {};
                ipObj.zipCode = dataResult.Records[0].PostalCode;
                ipObj.ip = dataResult.Records[0].IPAddress;
                ipObj.latitude = dataResult.Records[0].Latitude;
                ipObj.longitude = dataResult.Records[0].Longitude;
                ipObj.city = dataResult.Records[0].City;
                ipObj.state = dataResult.Records[0].Region;
                ipObj.countryCode = dataResult.Records[0].CountryAbbreviation;
                ipObj.saveTimestamp = new Date().toUTCString();

                browserStorage.setObject("ipObj", ipObj);

                var dataObj = transformData("attr", ipObj);
                sendData(dataObj, false);
            }
        }),
    );
}

function hasError(melissaResponse) {
    return (
        (melissaResponse.TransmissionResults || melissaResponse.TransmissionResults.length > 0) &&
        melissaResponse.Records === null
    );
}

function defaultOverrideParams(params) {
    if (params.cmp === undefined) {
        const cookieCmp = getCookie("cmp");
        if (cookieCmp) {
            params.cmp = cookieCmp;
        }
    }
    return params;
}

export default function createGeoLocator(overrideParams, renderingDomElements) {
    overrideParams = overrideParams || defaultOverrideParams;

    var $geoLocatorLink = $(".geoLocator-link a");
    var $geoLocatorLinkText = $(".geoLocator-link a .linkText");
    var $geoLocatorInputDiv = $(".geoLocator-input");
    var $geoLocatorInputField = $(".geoLocator-input input[type=text]");
    var $geoLocatorErrorMsg = $(".geoLocator-link .error");
    var _showError = false;
    var shouldReloadIt = false;
    var _autocomplete = {
        desktop: undefined,
        mobile: undefined,
    };

    // Handle custom event that should be triggered when user changes ZIP Code
    // in any (other) form
    $(document).on("userZipChanged", _handleZipChanged);

    var hasDownBeenPressed = false;

    // Make tab key autofill and select first drop down option OR serve as <ENTER> key
    $geoLocatorInputField
        .on("focus", function () {})
        .on("keydown", function (e) {
            if (e.keyCode == "9") {
                e.preventDefault();

                if (!hasDownBeenPressed && !e.hasRanOnce) {
                    google.maps.event.trigger(e.target, "keydown", {
                        keyCode: 40,
                        hasRanOnce: true,
                    });
                }
            }
        });

    // Refresh page when input field text changes
    // $geoLocatorInputField.on('change blur', function() {
    // 	_reloadPage();
    // });

    if ($(".geoLocator").length > 0 && google) {
        let params = retrieveUrlParameters();
        params = overrideParams(params);
        init(params);
    }

    /**
     * init - initialize Geolocation component
     *
     * @return {void} void
     */
    function init(params) {
        let ipObj = browserStorage.getObject("ipObj");
        if (!ipObj) {
            initIpObj(ipObj, params);
        } else {
            _initLocation();
            updateInitPhoneNumber(ipObj, params);
        }
    }

    function initIpObj(ipObj, params) {
        if (ipPath != "" && ipPath !== "undefined" && ipMelissa != "" && ipMelissa !== "undefined") {
            getDynamicIP().then(() => {
                ipObj = browserStorage.getObject("ipObj");
                _initLocation();
                updateInitPhoneNumber(ipObj, params);
            });
        }
    }

    function updateInitPhoneNumber(ipObj, params) {
        _initAutocomplete();
        $geoLocatorLink.on("click", _showGeoLocatorField);
        $geoLocatorInputField.on("blur", _restoreLinkState);

        let cmp = params.cmp;
        let lead = params.lead;
        let zip = "";
        let user = browserStorage.getObject("user");
        let clubCode = "";

        if (user != null) {
            zip = user.zip;
        } else if (lead == "club" && ipObj) {
            zip = ipObj.zipCode;
        }

        if (zip == "" || !browserStorage.getObject("geoUserMod")) {
            clubCode = getCookie("cc");
        }
        browserStorage.setObject("geoUserMod", false);

        if (!lead) {
            lead = getCookie("lead");
        }

        getClubAndCampaignProfile(zip, cmp, lead, clubCode)
            .then(updateDynamicPhone)
            .catch((error) => console.error("Error updating phone", error));
    }

    /**
     * _initLocation - checks for a saved location, and sets if saved, otherwise
     * checked if current location is available
     *
     * @private
     */
    function _initLocation() {
        var user = _getUserData();

        // first, look for a saved location in user object
        if (_hasSavedLocation(user)) {
            // Update City, State to match currently saved zip code
            $geoLocatorLinkText.text(_formatUserLocation(user));
            _setLocationCookie(user);
        } else {
            // if any campaign page in the URL then it will not call _getCurrentLocation
            if (window.location.href.split("/")[3].includes("campaigns")) {
                return;
            }
            // if no saved location, try to get user's current location
            _getCurrentLocation();
        }
    }

    function _reloadPage() {
        setTimeout(function () {
            window.location.reload();
        }, 200);
    }

    /**
     * _initAutocomplete - set up the google maps autocomplete object(s)
     *
     * @return {void} void
     */
    function _initAutocomplete() {
        // Create the autocomplete object for each input field (in header for
        // desktop; in hamburger menu for mobile)
        $geoLocatorInputField.each(_setupSingleAutocomplete);
    }

    /**
     * _setupSingleAutocomplete - initializes a single autocomplete (called from
     * .each loop)
     *
     * @private
     */
    function _setupSingleAutocomplete() {
        var type = "desktop";
        if ($(this).closest(".mobile-menu").length > 0) {
            type = "mobile";
        }

        // the (regions) type collection instructs the Places service to return
        // any result matching the following types:
        // locality, sublocality, postal_code, country,
        // administrative_area_level_1, administrative_area_level_2
        _autocomplete[type] = new google.maps.places.Autocomplete(this, {
            types: ["(regions)"],
        });

        // filter results to US only
        var componentRestrictions = {
            country: "us",
        };
        _autocomplete[type].setComponentRestrictions(componentRestrictions);

        // When the user selects an address from the dropdown, populate the
        // location with city, state, zip

        _autocomplete[type].addListener("place_changed", _displayLocation);
    }

    /**
     * _showGeoLocatorField - show the input field for ZIP Code
     *
     * @return {void} void
     */
    function _showGeoLocatorField(e) {
        browserStorage.setObject("geoUserMod", true);
        var linkText = "";
        var $field = $(this).parents(".geoLocator").find(".geoLocator-input input");

        e.preventDefault();
        var user = _getUserData();
        if (_hasSavedLocation(user)) {
            linkText = _formatUserLocation(user);
        }

        // hide the link and any error message
        $geoLocatorLink.addClass("hide");
        $geoLocatorErrorMsg.addClass("hide");
        _showError = false;

        // show the input field
        $geoLocatorInputDiv.removeClass("hide");
        $geoLocatorInputField.val(linkText);
        $field.val("");
        $field.focus();
    }

    /**
     * _restoreLinkState - return the geolocator link to its former text when
     * input field is blurred
     *
     * @private
     */
    function _restoreLinkState() {
        var linkText = "";
        var user = _getUserData();

        if (!_showError) {
            linkText = _formatUserLocation(user);
            _reloadPage();
        } else {
            linkText = "Enter your ZIP Code";
        }
        _setLocationCookie(user);
        _setDisplayState(linkText);
    }

    function _setLocationCookie(user) {
        if (user) {
            const city = user.city ? user.city.replace(/\s/g, "^") : "";
            document.cookie = "_location=" + city + "#" + user.state + "#" + user.zip;
        }
    }

    /**
     * _setDisplayState - set the link text, show it and hide the input
     *
     * @param locationString
     * @private
     */
    function _setDisplayState(locationString) {
        if (locationString) {
            $geoLocatorLinkText.text(locationString);

            // remove the focus from the input field (to fix IE bug)
            $geoLocatorInputField.each(function () {
                if ($(this).is(":focus")) {
                    $(this).blur();
                }
            });

            if (_showError) {
                $geoLocatorErrorMsg.removeClass("hide");
            } else {
                $geoLocatorErrorMsg.addClass("hide");
            }

            // Show the link with location text
            $geoLocatorLink.removeClass("hide");
            $geoLocatorInputDiv.addClass("hide");
        }
    }

    /**
     * _displayLocation - extract city, state, and zip from the selected
     * autocomplete result; display in the link
     *
     * @return {void} void
     */
    function _displayLocation() {
        var ac_type = _autocomplete.desktop === this ? "desktop" : "mobile";
        var user = _getUserData();
        var locationString = null;

        // Get the place details from the autocomplete object.
        var place = _autocomplete[ac_type].getPlace();
        if (place && _isValidFormat(place.name)) {
            // if place doesn't have address_components, but it does have a
            // name, enter was clicked on input and place.name is the input
            // value
            if (place.name && !place.address_components) {
                const createZipComponentGeocoderRequest = (zip) => ({
                    componentRestrictions: {
                        country: "US",
                        postalCode: zip,
                    },
                });

                const geocoderRequest = aaalife.utils.validate.zipFormat(place.name)
                    ? createZipComponentGeocoderRequest(place.name)
                    : { address: place.name };

                new google.maps.Geocoder().geocode(geocoderRequest, _getGeoCoderAddress);
            } else {
                // if place has address_components, extract the city/state/zip
                locationString = _getAddress(place, user);
                if (locationString) {
                    _setDisplayState(locationString);
                }
            }
            // if no 'place' show the prompt and an error message
        } else {
            _showError = true;
            _setDisplayState("Enter your ZIP Code");
        }
    }

    function _isValidFormat(placeName) {
        var valid = true;
        if (_containsOnlyDigits(placeName)) {
            valid = aaalife.utils.validate.zipFormat(placeName);
        }
        return valid;
    }

    function _containsOnlyDigits(placeName) {
        return /^\d+$/.test(placeName);
    }

    /**
     * _getCurrentLocation - get current location from geolocation if available
     *
     * @private
     */
    function _getCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    var geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    var point = new google.maps.LatLng(geolocation.lat, geolocation.lng);

                    new google.maps.Geocoder().geocode(
                        {
                            latLng: point,
                        },
                        _getGeoCoderAddress,
                    );
                },
                function () {
                    // Show default text if geolocation is disabled
                    _setDisplayState("Enter your ZIP Code");
                },
            );
        }
    }

    /**
     * _gotCurPosition - handle return from location request from
     * navigator.geolocation.getCurrentPosition
     *
     * @param position
     * @private
     */
    function _gotCurPosition(position) {
        var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };
        var point = new google.maps.LatLng(geolocation.lat, geolocation.lng);

        new google.maps.Geocoder().geocode(
            {
                latLng: point,
            },
            _getGeoCoderAddress,
        );
    }

    /**
     * _errorGettingCurPosition - handle error from location request from
     * navigator.geolocation.getCurrentPosition
     *
     * @param position
     * @private
     */
    function _errorGettingCurPosition() {
        // Show default text if geolocation is disabled
        _setDisplayState("Enter your ZIP Code");
    }

    /**
     * _getAddress - get an address from place and save to user object, call
     * display function
     *
     * @param place
     * @param user
     * @returns {string}
     * @private
     */
    function _getAddress(place, user) {
        var userAddress = _getAddressRaw(place, user);

        if (userAddress) {
            if (userAddress.zip) {
                return _formatUserLocation(userAddress);
            } else {
                return null;
            }
        } else {
            _showError = true;
            return "Enter your ZIP Code";
        }
    }

    function _getAddressRaw(place, user) {
        var component;
        var componentType;
        var city;
        var state;
        var zip;
        var country;
        var i;

        // find the various components of the current Place to build the
        // location string
        if (place && place.address_components) {
            for (i = 0; i < place.address_components.length; i++) {
                component = place.address_components[i];
                componentType = component.types[0];

                if (componentType === "country") {
                    // collect country info only
                    // to make sure we're in the
                    // US
                    country = component.short_name;
                }
                if (componentType === "locality") {
                    city = component.long_name;
                }
                if (componentType === "administrative_area_level_1") {
                    state = component.short_name;
                }
                if (componentType === "postal_code") {
                    zip = component.long_name;
                }
            }

            // If there is no zip code (e.g. user entered city, state),
            // find zip code for the lat/long returned for the city
            if (zip === "" || typeof zip === "undefined") {
                var lat = place.geometry.location.lat();
                var lng = place.geometry.location.lng();
                var point = new google.maps.LatLng(lat, lng);
                shouldReloadIt = true;

                new google.maps.Geocoder().geocode(
                    {
                        latLng: point,
                    },
                    _getGeoCoderAddress,
                );
            }
        }

        if (country === "US") {
            // do nothing if outside of US
            user.city = city;
            user.state = state;
            let storedZip = user.zip;
            user.zip = zip;
            _saveUserData(user);
            let params = retrieveUrlParameters();

            let cmp = params.cmp;
            let lead = params.lead;
            if (!lead) {
                lead = getCookie("lead");
            }

            if (storedZip != user.zip) {
                var locationData = transformData("zip", user.zip);
                var currentData = transformData("current", locationData);
                sendDataToKBMandGTM(currentData, user);
            }

            return user;
        } else {
            _showError = true;
            return null;
        }
    }

    function sendDataToKBMandGTM(currentData, user) {
        sendData(currentData, false);
        var dl = window.dataLayer;

        if (typeof dl.user != "undefined") {
            dl.user = appendData(dl.user, "zip", user.zip);
            dl.user = appendData(dl.user, "state", user.state);
        } else {
            dl.user = {};
            dl.user = appendData(dl.user, "zip", user.zip);
            dl.user = appendData(dl.user, "state", user.state);
        }
        var event = "zipCodeGeoLocationUpdate";

        dataLayer.push({
            event: event,
            user: dl.user,
        });
    }

    /**
     * _getGeoCoderAddress - handle response from geocode request
     *
     * @param res
     * @param status
     * @private
     */
    function _getGeoCoderAddress(res, status) {
        var user = _getUserData();
        var place;

        if (status == google.maps.GeocoderStatus.OK && typeof res !== "undefined" && res.length > 0) {
            place = res[0];
            _showError = false;
            _setDisplayState(_getAddress(place, user));
            if (user.zip) {
                $("#zip").val(user.zip);
                $("#zip").blur();
            }
            // JDP: new location?

            var userAddress = _getAddressRaw(place, user);
            var params = retrieveUrlParameters();

            let cmp = params.cmp;
            var lead = params.lead;

            _setLocationCookie(user);
        } else {
            _showError = true;
            _setDisplayState("Enter your ZIP Code");
        }
    }

    /**
     * _getUserData
     *
     * @return {user} - user object
     * @private
     */
    function _getUserData() {
        var user = browserStorage.getObject("user");
        if (!user) {
            user = aaalife.data.user || {};
        }
        return user;
    }

    /**
     * _saveUserData
     *
     * @return {void}
     * @param user -
     *            user object
     * @private
     */
    function _saveUserData(user) {
        aaalife.data.user = user;
        browserStorage.setObject("user", aaalife.data.user);
    }

    /**
     * _hasSavedLocation - is there a saved location in the session user object?
     *
     * @return {boolean} true or false
     * @param user -
     *            user object
     * @private
     */
    function _hasSavedLocation(user) {
        var hasLocation = false;
        if (user.state && user.zip) {
            hasLocation = true;
        }
        return hasLocation;
    }

    /**
     * _formatUserLocation - create formatted string consisting of user's City,
     * State, and ZIP code
     *
     * @return {string} location in format "Ann Arbor, MI 48103"
     * @param user -
     *            user object
     * @private
     */
    function _formatUserLocation(user) {
        var locationString = user.city + ", " + user.state + (user.zip ? " " + user.zip : "");
        return locationString;
    }

    /**
     * _handleZipChanged - update geoLocation displayed on custom event
     * 'userZipChanged' (triggered by other forms)
     *
     * @return void
     * @private
     */
    function _handleZipChanged() {
        const user = _getUserData();
        new google.maps.Geocoder().geocode(
            {
                componentRestrictions: {
                    country: "US",
                    postalCode: user.zip,
                },
            },
            _getGeoCoderAddress,
        );

        const lead = getCookie("lead");
        getClubAndCampaignProfile(user.zip, "", lead)
            .then(updateDynamicPhone)
            .catch((error) => console.error("Error updating phone", error));
    }
}
