import $ from "jquery";
import { appendData, collectFormData, sendData, sendDataAsync, sendEvent, transformData, sendReceiveData } from "../collector";
import { transferQuoteToEt2 } from "../utils/expressterm-link";
import { checkAndSetEmailSource } from "../utils/data-source-code";
import { ageLastBirthday, createDate } from "../utils/age";
import {
    renderForExpressTermProductPage,
    renderForHomepage,
    renderForTraditionalTermProductPage,
} from "../react-components/quote-form-small-footprint/index";
import {
    getCombinedValidationData,
    getQuoteResultsData,
    COND_MAP_TRADITIONAL,
    COND_MAP_EXPRESS,
} from "./aaalife-utils-get-data";
import { browserStorage } from "./aaalife-utils-browser-storage";
import { getClubAndCampaignProfile, updateDynamicPhone } from "../utils/aaalife-dynamic-phone";
import { retrieveUrlParameters } from "../utils/page";

export function initQuoteForm() {
    // disable "this" error validation - jQuery event handlers use "this" for
    // the DOM element
    /* jshint validthis:true */

    var _quoterValid = {
        panel1: {
            zip: false,
            gender: false,
            age: false,
            isMember: false,
            emailFormat: false,
            // policyReplacement: false
        },
        panel1Fields: {
            zipField: $(".zipNumeric"),
            genderField: $("#gender"),
            ageField: $("#quoteForm .date select"),
            isMemberField: $('[name="isMember"]'),
            emailFormatField: $("#contact_email"),
            // policyReplacementField: $('[name="policy_type"]')
        },
        panel1Error: {
            zipError: $("#zipLabel"),
            genderError: $("#genderLabel"),
            ageError: $("#DOBLabel"),
            isMemberError: $("#aaaMemberLabel"),
            emailFormatError: $("#emailLabel"),
        },
        panel2: {
            typeChosen: false,
        },
        panel2Fields: {
            typeChosenField: $('[name="insurance_type"]'),
        },
        panel2Error: {
            typeChosenError: $("#insurance_typeLabel"),
        },
        panel3: {
            existingPolicy: true,
            validBMI: false,
            height: false,
            weight: false,
            usedNicotine: false,
            rateYourHealth: false,
            coverageAmount: false,
            termLength: false,
        },
        panel3Fields: {
            existingPolicyField: $('[name="policy_type"]'),
            validBMIField: $(".bmi select, .bmi input"),
            heightField: $(".bmi select"),
            weightField: $(".bmi input"),
            usedNicotineField: $('[name="nicotineUse"]'),
            rateYourHealthField: $('[name="rateYourHealth"]'),
            coverageAmountField: $("#coverageAmount"),
            termLengthField: $("#termLength"),
        },
        panel3Error: {
            existingPolicyError: $("#existingLabel"),
            validBMIError: $("#heightLabel, #weightLabel"),
            heightError: $("#heightLabel"),
            weightError: $("#weightLabel"),
            usedNicotineError: $("#nicotineLabel"),
            rateYourHealthError: $("#healthConditionLabel"),
            coverageAmountError: $("#coverageAmountLabel"),
            termLengthError: $("#termLengthLabel"),
        },
    };

    var errorFieldValidation = {
        NYStateError: false,
        stateError: false,
        existingPolicy: false,
    };

    var _zipRestrictions = {};
    var _inlineModal;
    var _disallowThirty = false;

    if ($("#quoteForm").length > 0 || $("#expressTermForm").length > 0) {
        init();
    }

    if ($("#combinedQuote-productSelection").length > 0) {
        _initQuoteFormButtons();
    }

    if ($('form[name="combinedQuoteForm"]').length > 0) {
        _initCombinedQuoteForm();
    }

    function _getUserData() {
        var user = browserStorage.getObject("user");
        if (!user) {
            user = aaalife.data.user || {};
        }
        return user;
    }

    function _getQuoteData() {
        var quote = browserStorage.getObject("quote");
        if (!quote) {
            quote = aaalife.data.quote || {};
        }
        return quote;
    }

    /**
     * init
     */
    function init() {
        var validationData = getCombinedValidationData();

        if (document.getElementsByName("panelsQuoteForm").length > 0) {
            renderForHomepage(window, validationData);
            return;
        } else if (
            document.getElementsByName("panelsQuoteFormTraditional").length > 0 &&
            !document.querySelector(`form[name="panelsQuoteFormTraditional"][action="/campaign-quote-results"]`)
        ) {
            // For now it is a special case that we do not render the component on the long form campaign page but that
            // may change in the future.
            renderForTraditionalTermProductPage(window, validationData);
            return;
        } else if (document.getElementsByName("panelsQuoteFormExpress").length > 0) {
            renderForExpressTermProductPage(window, validationData);
            return;
        }

        // Continue with original initialization code here to support old flow where contact information is collected
        // after quote results. After these other form such as the large footprint quoter. have been refactored to
        // support the new flow then the code below can be removed.

        var formReady = false;

        Promise.all([getQuoteResultsData(), validationData]).then(([resultsData, validationData]) => {
            // _quoteResultsData = resultsData;

            _initializeQuoteForm(validationData);

            // Combined Quote functions
            if ($(".qfWide").length > 0) {
                _initializeCombinedQuote();
            }
        });

        // Reset quote type so that the user can choose it again, unless the
        // server has specifically set it or if on the single quote wide form
        if ($(".qfWide").length === 0) {
            aaalife.data.quote.type = aaalife.fromServer.quoteType || "";
        }
        // or maybe it has already been selected on the wide interstitial page?
        var quote = _getQuoteData();
        aaalife.data.quote = quote;
        aaalife.data.quote.type =
            (browserStorage.getObject("quote") && browserStorage.getObject("quote").type) || aaalife.data.quote.type;

        aaalife.data.user.source = aaalife.data.user.source || aaalife.fromServer.emailSource;
        _initQuoteFormButtons();

        /**
         * _initializeQuoteForm
         *
         * @param data
         * @private
         */
        function _initializeQuoteForm(data) {
            formReady = true;

            _populateDropdowns($(".inc"), 0, 11, 1);
            _populateDropdowns(
                $("#year"),
                new Date().getFullYear() - data.restrictions.dropdownAges.minAge,
                new Date().getFullYear() - data.restrictions.dropdownAges.maxAge,
                -1,
            );
            _populateDropdowns(
                $("#needs_year"),
                new Date().getFullYear() - data.restrictions.dropdownAges.minAge,
                new Date().getFullYear() - data.restrictions.dropdownAges.maxAge,
                -1,
            );

            _populateDaysDropdown();

            if ($("#expressTermForm").length > 0) {
                _populateDropdowns($("#month"), 1, 12, 1);

                utils.init();
                checkToSeeIfRadioButtonsAreChecked();
            }
            _initEventListeners();
            _populateUserValuesIfSaved();
            //Call to zip validation to display error message if geolocator zip is invalid
            zipReturnState();

            $("input, select").addClass("pristine").on("focus blur", _markDirty);

            if ($('form[name="panelsQuoteFormTraditional"]').length > 0) {
                _setInsuranceTypeToTraditional();
            } else if (
                $('form[name="panelsQuoteFormExpress"]').length > 0 ||
                $('form[name="expressTermCampaign"]').length > 0
            ) {
                _setInsuranceTypeToExpress();
            }

            _panelSlide();

            // HACK: This change was made as a workaround to an issue found in production which
            // affects express term campaign landing pages to cause revisits to the page not
            // to show the insurance replacement question if the applicant is form a specific
            // set of state. See https://prdwikinf01.aaalife.com:8443/browse/RUM-604
            $("#zip").blur();
        }

        // Hide error text
        _showInlineError(false);
        aaalife.utils.modalInit();
        _inlineModal = new aaalife.utils.Inline({
            content: $(".errorModal"),
        });
    }

    function checkToSeeIfRadioButtonsAreChecked() {
        if ($("#isMemberYes").is(":checked")) {
            $("#isMemberYesLink").addClass("radioSelected");
        }
        if ($("#isMemberNo").is(":checked")) {
            $("#isMemberNoLink").addClass("radioSelected");
        }
        if ($("#fair").is(":checked")) {
            $("#fairLink").addClass("radioSelected");
        }
        if ($("#good").is(":checked")) {
            $("#goodLink").addClass("radioSelected");
        }
        if ($("#excellent").is(":checked")) {
            $("#excellentLink").addClass("radioSelected");
        }
        if ($("#existingPolicyYes").is(":checked")) {
            $("#existingPolicyYesLink").addClass("radioSelected");
        }
        if ($("#existingPolicyNo").is(":checked")) {
            $("#existingPolicyNoLink").addClass("radioSelected");
        }
    }

    function _initQuoteFormButtons() {
        $(".get-express-quote-button, .get-traditional-quote-button").on("click", _getQuoteClicked);
        $(".combinedForm-backBtn").on("click", _combinedFormBackClicked);
    }

    function _initCombinedQuoteForm() {
        var topOfFormPanel;
        var quote = _getQuoteData();
        aaalife.data.quote.estimatedNeed = quote.estimatedNeed;

        if (quote.type == "") {
            quote.type = "traditional_term";
            _setInsuranceTypeToTraditional();
        }

        if ($("#combinedQuoteFormTraditionalOnly").length > 0) {
            _setInsuranceTypeToTraditional();
        }

        if ($("#combinedQuoteFormExpressOnly").length > 0) {
            _setInsuranceTypeToExpress();
        }

        _setCoverageAndTermOptions(quote.type, true);

        // scroll to the top of the form, if needed
        topOfFormPanel = $(".combinedForm-quoteForm .formCmpnt-form").offset().top;
        if (window.scrollY > topOfFormPanel) {
            window.scrollTo(0, topOfFormPanel);
        }

        _setDisplayBasedOnInsuranceType();
    }

    function zipReturnState(e) {
        var deferred = $.Deferred();
        var zipConditions = {
            allTermNotOffered: false,
            expressTermNotOffered: false,
            replacementPolicyNotOffered: false,
            annuitiesNotOffered: false,
        };
        var geocoder = new google.maps.Geocoder();
        var state;
        var $zip = $("#zip");
        var zip = $zip.val();

        if (!aaalife.data.user.state && !!zip) {
            geocoder.geocode(
                {
                    componentRestrictions: {
                        country: "US",
                        postalCode: zip,
                    },
                },
                _processGeocodeResults,
            );
        }

        /**
         * _processGeocodeResults
         *
         * @param results
         * @param status
         * @private
         */
        function _processGeocodeResults(results, status) {
            var user = _getUserData();
            aaalife.data.user = user;

            if (status == google.maps.GeocoderStatus.OK) {
                let state = results[0].address_components.find(_searchAddrComponents);

                if (state && state.short_name) {
                    state = state.short_name;
                    aaalife.data.user.state = state;
                    browserStorage.setObject("user", aaalife.data.user);
                }
            }
        }
    }

    function _searchAddrComponents(addrComp) {
        return addrComp.types.find(_searchTypes);
    }

    function _searchTypes(type) {
        return type === "administrative_area_level_1";
    }
    // Event Handlers
    // -------------------------------------------------------------

    /**
     * _initEventListeners
     *
     * @private
     */
    function _initEventListeners() {
        // Validation Functionality Shared Between Expressterm and Term-Quote Campaigns
        $("#year").on("change", _populateDaysDropdown);
        $("#contact_email").on("change", _validateEmail);
        $("#month").on("change", _populateDaysDropdown);
        $('[name="rateYourHealth"]').on(
            "focus",
            null,
            {
                triggerEvent: "on focus",
            },
            _validateHealthConditions,
        );
        // Not on expressterm or term-quote
        $('.emailForm-inner [name="email"]').on("change", _validateEmail);

        // Validation Functionality for Expressterm Only
        if ($("#expressTermForm").length > 0) {
            $('[name="policy_type"]').on("change", _validateExistingPolicy);
            $('[name="nicotineUse"]').on("change", _validateNicotineET);
            $('[name="isMember"]').on("change", _validateIsMemberET);
            $(".bmi .weightNumeric").on("blur", _validateWeightET);
            $(".bmi .weightNumeric").on("keyup", _preventNonNumeric);
            $("#zip").on("blur", _validateZipET);
            $("#feetSelectBoxIt").on("focus", _validateHealthConditions);
            $("#inchesSelectBoxIt").on("focus", _validateHealthConditions);
            $("#landingSubmit").on("click", _landingSubmitClicked);
            $("#landingSubmit").on("click", _saveETCampaignValues);
        }

        // Validation Functionality for Term-Quote Only
        if ($("#quoteForm").length > 0) {
            $(".panel-container-backbtn").on("click", _backClicked);
            $(".panel-container-nextbtn").on("click", _nextClicked);
            $("#seeQuote").on("click", _seeQuoteClicked);
            /* ==MEMBERSHIP TOOLTIP POP UP ON CLICK QUESTION MARK== */
            $(".showtooltip svg").on("click", function () {
                $(this).parent().find(".tooltipinfo").show();
            });
            $(".tooltipinfo .tooltipclose").on("click", function () {
                $(this).parent().hide();
            });
            $('[name="zip"]').on("blur", _validateZip);
            $('[name="zip"]').on("blur", zipReturnState);
            $('[name="isMember"]').on("change", _validateIsMember);
            $("#contact_email").on("change", _validateEmail);
            $("#gender").on("change", _validateGender);
            $("#year").on("change", _validateAge);
            $("#year").on("change", _validateHealthConditionsIfAppropriate);
            $("#day").on("change", _validateAge);
            $("#month").on("change", _validateAge);
            $('[name="insurance_type"]').on("change", _validateTypeChosen);
            $(".bmi #feet").on("change", _validateHealthConditions);
            $(".bmi #feet").on("change", _hasValue);
            $(".bmi #inches").on("change", _validateHealthConditions);
            $(".bmi #inches").on("change", _hasValue);
            $(".bmi .weightNumeric").on("keyup", _preventNonNumeric);
            $(".bmi .weightNumeric").on("blur", _validateWeight);
            $(".bmi .weightNumeric").on("blur", _hasValue);
            $('[name="nicotineUse"]').on("change", _validateNicotine);
            $('[name="rateYourHealth"]').on("change", _hasValue);
            $('[name="coverageAmount"]').on("change", _validateCoverageAmountOrTerm);
            $('[name="termLength"]').on("change", _validateCoverageAmountOrTerm);
        }
    }

    // Wide Quoter page
    // -----------------------------------------------------------

    /**
     * _initializeCombinedQuote
     *
     * @private
     */
    function _initializeCombinedQuote() {
        var quote = _getQuoteData();
        aaalife.data.quote.estimatedNeed = quote.estimatedNeed;

        if (aaalife.fromServer.productSelectSkipped === "true") {
            quote.type = aaalife.fromServer.quoteType;
        }

        _setupWideQuoter();
    }

    /**
     * _getQuoteClicked
     *
     * @param e
     * @private
     */
    function _getQuoteClicked(e) {
        var quote = _getQuoteData();

        if ($(e.target).hasClass("get-express-quote-button")) {
            quote.type = "express_term";
        } else if ($(e.target).hasClass("get-traditional-quote-button")) {
            quote.type = "traditional_term";
        } else {
            quote.type = "";
        }
        $('[value="' + quote.type + '"]').click();

        aaalife.data.quote = quote;
        browserStorage.setObject("quote", aaalife.data.quote);

        var data = transformData("insurance_type", quote.type);
        var groupData = transformData("current", data);
        sendData(groupData, false);
        window.location = aaalife.fromServer.urlStrings.inputPage;
    }

    /**
     * _combinedFormBackClicked
     *
     * @param e
     * @private
     */
    function _combinedFormBackClicked(e) {
        var quote = _getQuoteData();

        e.preventDefault();

        aaalife.data.quote = quote;
        browserStorage.setObject("quote", quote);

        window.location = aaalife.fromServer.urlStrings.productSelectionPage;
    }

    /**
     * _setupWideQuoter
     *
     * @private
     */
    function _setupWideQuoter() {
        var insurance_type = "";
        var quote = browserStorage.getObject("quote") || {};
        $(".panel-panel1.hide").removeClass("hide");
        $(".panel-panel3.hide").removeClass("hide");

        if (quote.type) {
            insurance_type = aaalife.data.quote.type;
        } else {
            insurance_type = "traditional_term";
        }

        if ($("#combinedQuoteFormTraditionalOnly").length > 0) {
            insurance_type = "traditional_term";
            quote.type = insurance_type;
            browserStorage.setObject("quote", quote);
            $('[value="traditional_term"]').click();
        }

        _setCoverageAndTermOptions(insurance_type, true);
        _setDisplayBasedOnInsuranceType();
    }

    function _setDisplayBasedOnInsuranceType() {
        var quote = _getQuoteData();
        var $productNameField = $(".productName-large");
        var $productNameMobileField = $(".productName-mobile");
        var $productDescriptionField = $(".productDescription");

        if (quote.type == "express_term") {
            $productNameField.text(aaalife.fromServer.expressTerm.title);
            $productNameMobileField.text(aaalife.fromServer.expressTerm.title);
            $productDescriptionField.text(aaalife.fromServer.expressTerm.content);
        } else {
            $productNameField.text(aaalife.fromServer.traditionalTerm.title);
            $productNameMobileField.text(aaalife.fromServer.traditionalTerm.title);
            $productDescriptionField.text(aaalife.fromServer.traditionalTerm.content);
        }
    }

    // Data - Get and Save
    // --------------------------------------------------------

    /**
     * _getUserData
     *
     * @returns {?Object}
     * @private
     */
    function _getUserData() {
        var user = browserStorage.getObject("user");

        if (!user) {
            user = aaalife.data.user || {};
        }
        return user;
    }

    /**
     * _getQuoteData
     *
     * @returns {?Object}
     * @private
     */
    function _getQuoteData() {
        var quote = browserStorage.getObject("quote");

        if (!quote) {
            quote = aaalife.data.quote || {};
        }
        return quote;
    }

    /**
     * _saveValues
     *
     * @private
     */
    function _saveValues() {
        var user = _getUserData();
        var quote = _getQuoteData();

        user.zip = $("#zip").val() || user.zip;

        user.gender = $("#gender").val() || user.gender;
        user.dobMonth = $("#month").val() || user.dobMonth;
        user.dobDay = $("#day").val() || user.dobDay;
        user.dobYear = $("#year").val() || user.dobYear;

        user.isMember = $("input:radio[name=isMember]:checked").val() || user.isMember;

        user.email = $("#contact_email").val() || user.email;

        user.hasPolicy = $("input:radio[name=policy_type]:checked").val() || user.hasPolicy;

        user.heightFeet = $("#feet").val() || user.heightFeet;
        user.heightInches = $("#inches").val() || user.heightInches;
        user.weight = $(".weightNumeric").val() || user.weight;

        user.health = $("#rateYourHealth").val() || user.health;
        user.nicotine = usedNicotineValue() || user.nicotine;
        user.nicotineUsageKey = (
            document.querySelector("#nicotineUse option:checked").attributes.name || { value: undefined }
        ).value;

        quote.coverage = $("#coverageAmount").val() || quote.coverage;
        quote.term = $("#termLength").val() || quote.term;
        quote.type = $("input[name=insurance_type]:checked").val() || quote.type;

        const evt =
            $(".combinedForm-quoteForm").length > 0
                ? "TQLKO" // Event for combined forms
                : "TQSKO1"; // Event for panel forms

        // set _quoterValid with overall validity
        var ageValidation = aaalife.utils.validate.age(user.dobMonth, user.dobDay, user.dobYear);
        _quoterValid.panel1.age = ageValidation.valid;

        if (!ageValidation.valid) sendEvent(evt);

        aaalife.data.user = user;
        browserStorage.setObject("user", user);

        // Trigger custom event so the geolocation can update if needed
        if (!aaalife.fromServer.urlStrings.isCampaignPage) {
            $(document).trigger("userZipChanged");
        }

        aaalife.data.quote = quote;
        browserStorage.setObject("quote", quote);
    }

    function _saveETCampaignValues() {
        var user = _getUserData();
        var quote = _getQuoteData();

        user.zip = $("#zip").val() || user.zip;

        user.gender = $("#genderSelectBoxItText").val() || user.gender;
        user.dobMonth = $("#monthSelectBoxItText").val() || user.dobMonth;
        user.dobDay = $("#daySelectBoxItText").val() || user.dobDay;
        user.dobYear = $("#yearSelectBoxItText").val() || user.dobYear;

        user.isMember = $("input:radio[name=isMember]:checked").val() || user.isMember;

        user.email = $("#emailAddress").val() || user.email;

        // user.hasPolicy = $('input:radio[name=policy_type]:checked').val() ||
        // user.hasPolicy;

        user.heightFeet = $("#feetSelectBoxItText").val() || user.heightFeet;
        user.heightInches = $("#inchesSelectBoxItText").val() || user.heightInches;
        user.weight = $(".weightNumeric").val() || user.weight;

        user.health = $("#rateYourHealth").val() || user.health;
        user.nicotine = usedNicotineValue() || user.nicotine;
        user.nicotineUsageKey = (
            document.querySelector("#nicotineUse option:checked").attributes.name || { value: undefined }
        ).value;

        // quote.coverage = $('#coverageAmount').val() || quote.coverage;
        // quote.term = $('#termLength').val() || quote.term;
        // quote.type = $('input[name=insurance_type]:checked').val() ||
        // quote.type;

        aaalife.data.user = user;
        browserStorage.setObject("user", user);

        aaalife.data.quote = quote;
        browserStorage.setObject("quote", quote);
    }

    // Data validation
    // ------------------------------------------------------------

    /**
     * _validatePanel
     *
     * @param panelToCheck
     * @returns {Object}
     * @private
     */
    function _validatePanel(panelToCheck) {
        var currentPanel = panelToCheck || _currentSlideIndex();

        if (currentPanel === 0) {
            if (!aaalife.utils.prevalidate.hasValidZip(_getUserData().zip)) {
                _applyKnockOut("y", false);
            }
            _validateGender.call($("#gender")[0], null, true);

            var day = $("#day").val();
            var month = $("#month").val();
            var year = $("#year").val();
            var ageValidation = aaalife.utils.validate.age(month, day, year);

            var ageInfo = {
                day: day,
                month: month,
                year: year,
                age: ageValidation,
            };

            if (!aaalife.utils.prevalidate.hasValidAge(ageInfo)) {
                _applyKnockOut("y", false);
            }
            _quoterValid.panel1.isMember = _validateIsMember.call($('[name="isMember"]:checked:first')[0]);
            _validateEmail.call($("#contact_email")[0]);
        } else if (currentPanel === 1) {
            _validateTypeChosen.call($('[name="insurance_type"]:checked:first')[0]);
        } else if (currentPanel === 2) {
            // Panel 3 on-demand validation
            _quoterValid.panel3.existingPolicy = _validateExistingPolicy.call($('[name="policy_type"]:checked:first')[0]);
            _quoterValid.panel3.validBMI = aaalife.utils.validate.getBMI({
                weight: $(".weightNumeric").val(),
                feet: $("#feet").val(),
                inches: $("#inches").val(),
                quoteType: aaalife.data.quote.type,
                day: $("#day").val(),
                month: $("#month").val(),
                year: $("#year").val(),
            }).valid;

            if (!_quoterValid.panel3.validBMI) {
                _applyKnockOut("y", false);
            }

            _quoterValid.panel3.height = _validateHeight();
            _quoterValid.panel3.weight = _weightValidation();
            _quoterValid.panel3.usedNicotine = usedNicotineValueSelected();
            _quoterValid.panel3.rateYourHealth = _hasValue.call(_quoterValid.panel3Fields.rateYourHealthField[0]);
            _quoterValid.panel3.coverageAmount = _hasValue.call(_quoterValid.panel3Fields.coverageAmountField[0]);
            _quoterValid.panel3.termLength = _hasValue.call(_quoterValid.panel3Fields.termLengthField[0]);
        }

        return _quoterValid;
    }

    function _validatePanelET() {
        if (!aaalife.utils.prevalidate.hasValidZip(_getUserData().zip)) {
            _applyKnockOut("y", false);
        }
        _validateGender.call($("#gender")[0], null, true);

        var day = $("#day").val();
        var month = $("#month").val();
        var year = $("#year").val();
        var ageValidation = aaalife.utils.validate.age(month, day, year);

        var feet = $("#feet").val();
        var inches = $("#inches").val();
        var weight = $("#weight").val();

        var ageInfo = {
            day: day,
            month: month,
            year: year,
            age: ageValidation,
        };

        _validateAgeET("showRed");

        if (!aaalife.utils.prevalidate.hasValidAge(ageInfo)) {
            _applyKnockOut("y", false);
        }
        _quoterValid.panel1.isMember = _validateIsMember.call($('[name="isMember"]:checked:first')[0]);
        _validateEmail.call($("#contact_email")[0]);

        _quoterValid.panel2.typeChosen = true;

        _quoterValid.panel3.existingPolicy = _validateExistingPolicy.call($('[name="policy_type"]:checked:first')[0]);
        _quoterValid.panel3.validBMI = aaalife.utils.validate.getBMI({
            weight: weight,
            feet: feet,
            inches: inches,
            quoteType: aaalife.data.quote.type,
            day: $("#day").val(),
            month: $("#month").val(),
            year: $("#year").val(),
        }).valid;

        if (!_quoterValid.panel3.validBMI) {
            _applyKnockOut("y", false);
        }

        _quoterValid.panel3.height = _validateHeight();
        _quoterValid.panel3.weight = _weightValidation();
        _quoterValid.panel3.usedNicotine = usedNicotineValueSelected();
        _quoterValid.panel3.rateYourHealth = true;
        _quoterValid.panel3.coverageAmount = true;
        _quoterValid.panel3.termLength = true;

        return _quoterValid;
    }

    function _validateHeight() {
        return $("#feet").val() !== "" && $("#inches").val() !== "";
    }

    function _weightValidation() {
        return $("#weight").val() !== "";
    }

    function usedNicotineValue() {
        return document.querySelector("#nicotineUse").value;
    }

    function usedNicotine() {
        return document.querySelector("#nicotineUse").value === "Yes";
    }

    function usedNicotineValueSelected() {
        const nicotineUse = document.querySelector("#nicotineUse").value;
        return nicotineUse === "Yes" || nicotineUse === "No";
    }

    function setOptionAsSelected(selectId, optionName) {
        const option = $(`option[name="${optionName}"]`);
        option.prop("selected", "selected").change();

        // Special cases for SelectBoxIt used in ET Campaign pages
        const selectBoxItJunk = $(`#${selectId}SelectBoxItText`);
        selectBoxItJunk.text(option.text());
        selectBoxItJunk.attr("data-val", option.val());
        selectBoxItJunk.attr("aria-label", option.text());
    }

    /**
     * _allPanelsValid
     *
     * @returns {boolean}
     * @private
     */
    function _allPanelsValid() {
        var i;
        var numPanels = 3;
        var isValid = false;

        for (i = 1; i <= numPanels; i++) {
            isValid = _checkQuoterPanelValidity(_quoterValid["panel" + i]);
            if (!isValid) {
                break;
            }
        }

        return isValid;
    }

    /**
     * _checkQuoterPanelValidity
     *
     * @param panel
     * @returns {boolean}
     * @private
     */

    function _checkQuoterPanelValidity(panel) {
        return Object.keys(panel).every(_checkQuoterValidValue);

        /**
         * _checkQuoterValidValue
         *
         * @param key
         * @returns {*}
         * @private
         */
        function _checkQuoterValidValue(key) {
            return panel[key];
        }
    }

    /**
     * _validateCoverageAmountOrTerm
     *
     * @returns {boolean}
     * @private
     */
    function _validateCoverageAmountOrTerm() {
        var coverageOrTerm = this.id === "coverageAmount" ? "coverage" : "term";
        var newVal = $(this).val();
        var isValid = !!newVal;

        if (isValid) {
            aaalife.data.quote[coverageOrTerm] = newVal;
            browserStorage.setObject("quote", aaalife.data.quote);
            _highlightInvalidField(true, $(this));
        }

        return isValid;
    }

    /**
     * _hasValue
     *
     * @returns {boolean}
     * @private
     */
    function _hasValue() {
        var isRadioOrCheckbox =
            this instanceof jQuery && (this.eq(0).attr("type") === "radio" || this.eq(0).attr("type") === "checkbox");
        var $el = $(this);
        var isValid = $el.is(":visible") ? !aaalife.utils.validate.missingValue($el) : true;
        var id = $el[0] && $el[0].id;
        var i;
        var numPanels = 3;

        if (!isRadioOrCheckbox && $el[0]) {
            for (i = 1; i <= numPanels; i++) {
                if (typeof _quoterValid["panel" + i][id] !== "undefined") {
                    _quoterValid["panel" + i][id] = isValid;
                }
            }
        } else if (isRadioOrCheckbox) {
            isValid = !!this.filter(":checked").val();
        } else {
            isValid = false;
        }
        _highlightInvalidField(true, $el);

        return isValid;
    }

    function _hasValueET() {
        var isRadioOrCheckbox =
            this instanceof jQuery && (this.eq(0).attr("type") === "radio" || this.eq(0).attr("type") === "checkbox");
        var $el = $(this);
        var isValid = $el.is(":visible") ? !aaalife.utils.validate.missingValue($el) : true;
        var id = $el[0] && $el[0].id;
        var i;
        var numPanels = 3;

        if (!isRadioOrCheckbox && $el[0]) {
            for (i = 1; i <= numPanels; i++) {
                if (typeof _quoterValid["panel" + i][id] !== "undefined") {
                    _quoterValid["panel" + i][id] = isValid;
                }
            }
        } else if (isRadioOrCheckbox) {
            isValid = !!this.filter(":checked").val();
        } else {
            isValid = false;
        }

        return isValid;
    }

    // Panel 1

    /**
     * _validateZip
     *
     * @param e
     * @returns {boolean|*}
     * @private
     */
    function _validateZip(e) {
        var validData;
        var $zip = $("#zip");
        var zip = $zip.val();
        var valueMissing = aaalife.utils.validate.missingValue($zip);
        var validZip = aaalife.utils.validate.zipFormat(zip);
        var $allFields = $("#quoteForm input, #quoteForm select, #quoteForm button");

        if (e && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }

        zip = $zip.val(zip.replace(/[^\d\-].+/, "")).val();
        validData = !valueMissing && validZip;
        _quoterValid.panel1.zip = validData;
        _highlightInvalidField(true, $zip);

        aaalife.utils.validate.zip(zip).then(_zipValidationComplete, _zipValidationError);

        return validData;
    }

    function _validateZipET(e) {
        var validData;
        var $zip = $("#zip");
        var zip = $zip.val();
        var valueMissing = aaalife.utils.validate.missingValue($zip);
        var validZip = aaalife.utils.validate.zipFormat(zip);
        var $allFields = $("#expressTermForm input, #expressTermForm select, #expressTermForm button");

        if (e && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }

        zip = $zip.val(zip.replace(/[^\d\-].+/, "")).val();
        validData = !valueMissing && validZip;
        _quoterValid.panel1.zip = validData;

        aaalife.utils.validate.zip(zip).then(_zipValidationCompleteET, _zipValidationError);

        return validData;
    }

    function _showInvalidFieldError(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            _showInlineError(false);
            $element.removeClass("show").addClass("hide");
        } else {
            if ($("#quoteForm").length > 0) {
                _showInlineErrorForQuoteForm(true);
            } else {
                _showInlineError(true);
            }
            $element.removeClass("hide").addClass("show");
        }
    }

    /**
     * _zipValidationComplete
     *
     * @param data
     * @private
     */
    function _zipValidationComplete(data) {
        var expressInvalid;
        var $allFields = $("#quoteForm input, #quoteForm select, #quoteForm button");

        _zipRestrictions = data;

        var itc = $("#quoteForm").data("panel-two");
        var source = $("#quoteForm").data("source-prime");
        aaalife.data.user.source = source || aaalife.fromServer.emailSource;
        $("#panel1-next").data("itc", itc);
        $("#panel1-next").data("source", source);

        $("#panel3-back").data("itc", itc);
        $("#panel3-back").data("source", source);

        if (_zipRestrictions.allTermNotOffered) {
            _enableFields();
            _disableFieldsExceptZip();

            if ($("#quoteForm").length > 0) {
                _showInlineErrorForQuoteForm(true);
            } else {
                _showInlineError(true);
            }

            _showErrorModal(aaalife.fromServer.errorModalsData.allTermNotOffered, true, _zipRestrictions.zip);
            _showInvalidFieldError(false, $("#stateError"));
            _quoterValid.panel1.zip = false;
            _applyKnockOut("y", false);
            setKOValidation();
        } else {
            if ($allFields.not(".zipNumeric").attr("disabled")) {
                _enableFields();
            }

            _applyKnockOut("n", false);
        }

        if (_zipRestrictions.expressTermNotOffered) {
            aaalife.data.quote = browserStorage.getObject("quote") || {};

            if (typeof aaalife.fromServer.quoteType === "undefined") {
                if (aaalife.data.quote.type === "express_term") {
                    _handleExpressTermNotOffered_ExpressTerm(_zipRestrictions);
                } else {
                    _handleExpressTermNotOffered_TraditionalTerm(aaalife.data.quote);
                }
            } else {
                if (aaalife.fromServer.quoteType === "express_term") {
                    _handleExpressTermNotOffered_ExpressTerm(_zipRestrictions);
                } else {
                    _handleExpressTermNotOffered_TraditionalTerm(aaalife.data.quote);
                }
            }
        }

        if (_zipRestrictions.replacementPolicyNotOffered) {
            $(".replaceExistingPolicy").removeClass("hide");
        } else {
            $(".replaceExistingPolicy").addClass("hide");
            $('[name="policy_type"]').prop("checked", false);
        }

        expressInvalid = _zipRestrictions.expressTermNotOffered && aaalife.data.quote.type === "express_term";
        _quoterValid.panel1.zip = _quoterValid.panel1.zip && !_zipRestrictions.allTermNotOffered && !expressInvalid;

        function _disableFieldsExceptZip() {
            var $zipField = $("#quoteForm input[name=zip]");

            $allFields.attr("disabled", "disabled");
            $zipField.removeAttr("disabled").addClass("invalid");
        }
        function _enableFields() {
            $allFields.removeAttr("disabled").removeClass("invalid");
        }

        function _handleExpressTermNotOffered_TraditionalTerm(quote) {
            if ($allFields.not(".zipNumeric").attr("disabled")) {
                _enableFields();
            }
            quote.type = "traditional_term";
            $('[value="' + quote.type + '"]').click();
            browserStorage.setObject("quote", quote);
            itc = $("#quoteForm").data("panel-three");
            source = $("#quoteForm").data("source-prime");
            aaalife.data.user.source = source;
            $("#panel1-next").data("itc", itc);
            $("#panel1-next").data("source", source);

            itc = $("#quoteForm").data("panel-one");
            $("#panel3-back").data("itc", itc);
            $("#panel3-back").data("source", source);
        }

        function _handleExpressTermNotOffered_ExpressTerm(_zipRestrictions) {
            _enableFields();
            _disableFieldsExceptZip();
            _showErrorModal(aaalife.fromServer.errorModalsData.expressTermNotOffered, true, _zipRestrictions.zip);
            _applyKnockOut("y", false);
            setKOValidation();
        }
    }

    function setKOValidation() {
        const evt =
            $(".combinedForm-quoteForm").length > 0
                ? "TQLKO" // Event for combined forms
                : "TQSKO1"; // Event for panel forms

        sendEvent(evt);
    }

    function _zipValidationCompleteET(data) {
        var expressInvalid;
        var $allFields = $("#expressTermForm input, #expressTermForm select, #expressTermForm button");

        _zipRestrictions = data;
        _enableFields();

        var itc = $("#expressTermForm").data("panel-two");
        var source = $("#expressTermForm").data("source-prime");
        aaalife.data.user.source = source;
        $("#panel1-next").data("itc", itc);
        $("#panel1-next").data("source", source);

        $("#panel3-back").data("itc", itc);
        $("#panel3-back").data("source", source);

        if (_zipRestrictions.allTermNotOffered) {
            errorFieldValidation.NYStateError = true;
            _applyKnockOut("y", false);
            setKOValidation();
        } else {
            errorFieldValidation.NYStateError = false;
            _applyKnockOut("n", false);
        }

        if (_zipRestrictions.expressTermNotOffered) {
            aaalife.data.quote = browserStorage.getObject("quote") || {};
            errorFieldValidation.stateError = true;
            if (aaalife.data.quote.type == "express_term") {
                _applyKnockOut("y", false);
                setKOValidation();
            } else {
                aaalife.data.quote.type = "traditional_term";
                $('[value="' + aaalife.data.quote.type + '"]').click();
                browserStorage.setObject("quote", aaalife.data.quote);
                itc = $("#expressTermForm").data("panel-three");
                source = $("#expressTermForm").data("source-alt");
                aaalife.data.user.source = source;
                $("#panel1-next").data("itc", itc);
                $("#panel1-next").data("source", source);

                itc = $("#expressTermForm").data("panel-one");
                $("#panel3-back").data("itc", itc);
                $("#panel3-back").data("source", source);
            }
        } else {
            errorFieldValidation.stateError = false;
        }

        if (_zipRestrictions.replacementPolicyNotOffered) {
            $(".replaceExistingPolicy").removeClass("hide");
            errorFieldValidation.existingPolicy = true;
        } else {
            $(".replaceExistingPolicy").addClass("hide");
            errorFieldValidation.existingPolicy = false;
            $('[name="policy_type"]').prop("checked", false);
        }

        function _disableFieldsExceptZip() {
            var $zipField = $("#expressTermForm input[name=zip]");

            $allFields.attr("disabled", "disabled");
            $zipField.removeAttr("disabled").addClass("invalid");
        }
        function _enableFields() {
            $allFields.removeAttr("disabled").removeClass("invalid");
        }
    }

    /**
     * _zipValidationError
     *
     * @private
     */
    function _zipValidationError() {
        var validData;
        var $zip = $("#zip");

        validData = false;
        _quoterValid.panel1.zip = validData;
    }

    /**
     *
     * @param e
     * @param shouldValidate
     * @private
     */
    function _validateGender(e, shouldValidate) {
        var isValid = aaalife.utils.validate.gender($(this));

        if (shouldValidate) {
            _highlightInvalidField(isValid, $(this));
            _quoterValid.panel1.gender = isValid;
        } else {
            _highlightInvalidField(true, $(this));
        }
    }

    /**
     * _validateAge
     *
     * @param colorFlag
     * @private
     */
    function _validateAge(colorFlag) {
        var day = $("#day").val();
        var month = $("#month").val();
        var year = $("#year").val();
        var ageValidation = aaalife.utils.validate.age(month, day, year);
        const age = ageLastBirthday(createDate(year, month, day), new Date());
        const evt =
            $(".combinedForm-quoteForm").length > 0
                ? "TQLKO" // Event for combined forms
                : "TQSKO1"; // Event for panel forms

        // set _quoterValid with overall validity
        _quoterValid.panel1.age = ageValidation.valid;

        var ageInfo = {
            day: day,
            month: month,
            year: year,
            age: age,
        };
        if (!aaalife.utils.prevalidate.hasValidAge(ageInfo)) {
            if (!ageValidation.valid && ageValidation.allSelected) {
                _applyKnockOut("y", false);
            } else if (ageValidation.valid) {
                _applyKnockOut("n", false);
            }
        }

        if (day !== "" && month !== "" && year !== "" && !ageValidation.valid) {
            if (aaalife.fromServer.urlStrings.isCampaignPage) {
                _showErrorModal(aaalife.fromServer.errorModalsData.ageGuidelinesNotMet);
            } else {
                _showErrorModal(aaalife.fromServer.errorModalsData.ageGuidelinesNotMet, true, $("#zip").val());
            }
            _showInlineError(true);

            // highlight age with error condition
            $(".date select").removeClass("valid").addClass("invalid");

            sendEvent(evt);
        } else {
            [$("#day"), $("#month"), $("#year")].forEach(function ($el) {
                if ($el.val() === "" && $el.hasClass("dirty") && colorFlag === "showRed") {
                    $el.removeClass("valid").addClass("invalid");
                    _quoterValid.panel1.age = false;
                } else if ($el.val() !== "") {
                    $el.removeClass("invalid").addClass("valid");
                    if (day !== "" && month !== "" && year !== "") {
                        _showInlineError(false);
                    }
                }
            });
        }

        var nicotine = usedNicotineValue();
        _disallowThirty = aaalife.utils.validate.ageNicotine(nicotine, age, 45);

        // Update coverage amount and term length dropdowns if BMI info is
        // filled out
        // _validateHealthConditions();
        _setCoverageAndTermOptions(aaalife.data.quote.type, true);
    }

    function _validateAgeET(colorFlag) {
        var day = $("#day").val();
        var month = $("#month").val();
        var year = $("#year").val();
        var ageValidation = aaalife.utils.validate.age(month, day, year);
        const age = ageLastBirthday(createDate(year, month, day), new Date());
        var nicotine = usedNicotineValue();

        // set _quoterValid with overall validity
        _quoterValid.panel1.age = ageValidation.valid;

        var ageInfo = {
            day: day,
            month: month,
            year: year,
            age: age,
        };

        if (!aaalife.utils.prevalidate.hasValidAge(ageInfo)) {
            if (!ageValidation.valid && ageValidation.allSelected) {
                _applyKnockOut("y", false);
            } else if (ageValidation.valid) {
                _applyKnockOut("n", false);
            }
        }

        if (day !== "" && month !== "" && year !== "" && !ageValidation.valid) {
            // _showErrorModal(aaalife.fromServer.errorModalsData.ageGuidelinesNotMet);

            $("#ageError").toggleClass("hide", false);

            _showInlineError(true);

            // highlight age with error condition
            $(".date select").removeClass("valid").addClass("invalid");
        } else {
            $("#ageError").toggleClass("hide", true);
        }

        _disallowThirty = aaalife.utils.validate.ageNicotine(nicotine, age, 45);

        // Update coverage amount and term length dropdowns if BMI info is
        // filled out
        // _validateHealthConditions();
        _setCoverageAndTermOptions(aaalife.data.quote.type, false);
    }

    /**
     * _validateIsMember
     *
     * @returns {boolean}
     * @private
     */
    function _validateIsMember(e) {
        var isMemberValid = aaalife.utils.validate.validateIsMember($(this));

        _highlightInvalidField(isMemberValid, $('[name="isMember"]'));

        return isMemberValid;
    }

    function _validateIsMemberET(e) {
        var isMemberValid = aaalife.utils.validate.validateIsMember($(this));
        return isMemberValid;
    }

    /**
     * _validateEmail
     *
     * @private
     */
    function _validateEmail() {
        var isValid;
        var $input = $(this);
        var email = $input.val();

        isValid = aaalife.utils.validate.emailFormat(email);

        _quoterValid.panel1.emailFormat = isValid;

        if (isValid) {
            _showInlineError(false);
            $input.removeClass("invalid").addClass("valid");
            checkAndSetEmailSource(email);
        } else {
            _showInlineError(true);
            $input.removeClass("valid").addClass("invalid");
        }
    }

    // Panel 2 (Interstitial)

    /**
     * _validateTypeChosen
     *
     * @private
     */
    function _validateTypeChosen() {
        var isValid;
        var $inputs = $('[name="insurance_type"]');

        isValid = aaalife.utils.validate.validateRadio();

        if (isValid) {
            _showInlineError(false);
            $inputs.removeClass("invalid").addClass("valid");
        } else {
            _showInlineError(true);
            $inputs.removeClass("valid").addClass("invalid");
        }

        _quoterValid.panel2.typeChosen = isValid;
    }

    // Panel 3

    /**
     * _validateWeight
     *
     * @private
     */
    function _validateWeight() {
        var $el = $(this);
        _highlightInvalidField(aaalife.utils.validate.weight($el.val()), $el);
        _validateHealthConditions.call(this);
    }
    function _validateWeightET() {
        var $el = $(this);
        _validateHealthConditions.call(this);
    }

    function _preventNonNumeric() {
        var $el = $(this);
        $el.val($el.val().replace(/\D+/g, ""));
    }

    /**
     * _validateNicotine
     *
     * @private
     */
    function _validateNicotine() {
        var month = $("#month").val();
        var day = $("#day").val();
        var year = $("#year").val();
        const age = ageLastBirthday(createDate(year, month, day), new Date());
        var nicotine = usedNicotineValue();

        if (nicotine) {
            _highlightInvalidField(true, $('[name="nicotineUse"]'));
            _disallowThirty = aaalife.utils.validate.ageNicotine(nicotine, age, 45);
            if (_disallowThirty) {
                // remove 30 yr term length
                $("#termLength").find('option[val="30"]').remove();
            }
            _validateHealthConditions.call($(this));
            _setCoverageAndTermOptions(aaalife.data.quote.type, false);
        } else {
            // Nicotine Use Yes/No not selected
            return false;
        }
    }

    function _validateNicotineET() {
        var month = $("#month").val();
        var day = $("#day").val();
        var year = $("#year").val();
        const age = ageLastBirthday(createDate(year, month, day), new Date());

        var nicotine = usedNicotineValue();

        if (nicotine) {
            _disallowThirty = aaalife.utils.validate.ageNicotine(nicotine, age, 45);
            if (_disallowThirty) {
                // remove 30 yr term length
                $("#termLength").find('option[val="30"]').remove();
            }
            _validateHealthConditions.call($(this));
            _setCoverageAndTermOptions(aaalife.data.quote.type, false);
        } else {
            // Nicotine Use Yes/No not selected
            return false;
        }
    }

    function _applyKnockOut(value, async) {
        if (aaalife.utils.prevalidate.isSameKOStatus(value)) {
            return;
        }
        var attrData = transformData("itc", "");
        attrData = appendData(attrData, "ko", value);
        var groupData = transformData("attr", attrData);
        sendData(groupData, async);
    }

    function _validateHealthConditionsIfAppropriate() {
        var heightFeet = $("#feet").length > 0;
        var heightInches = $("#inches").length > 0;
        var weight = $("#weight").length > 0;
        var nicotine = usedNicotineValueSelected();

        if (heightFeet && heightInches && weight && nicotine) {
            _validateHealthConditions();
        }
    }

    /**
     * _validateHealthConditions
     *
     * @private
     */
    function _validateHealthConditions(e) {
        var healthConditions;
        var BMIdata;
        var userData;
        var user = _getUserData();
        var $rateYourHealth = $("#rateYourHealth");
        var shouldShowModal = aaalife.fromServer.quoteSize === "qfWide" || _currentSlideIndex() === 2; // only show error message if on
        // final panel
        var $feet = $("#feet");
        var $inches = $("#inches");
        var $rateYourHealthContainer = $(".quoteForm-rateYourHealth");

        if (aaalife.utils.validate.missingValue($(this)) && !!e && typeof e.data != "undefined") {
            if (e.data.triggerEvent !== "on focus") {
                _highlightInvalidField(true, $(this));
                $rateYourHealthContainer.addClass("hide");
            }
        } else {
            userData = {
                weight: $("#weight").val(),
                feet: $feet.val(),
                inches: $inches.val(),
                height: $feet.val() * 12 + $inches.val() * 1,
                month: $("#month").val(),
                day: $("#day").val(),
                year: $("#year").val(),
                nicotine: usedNicotineValue(),
                quoteType: aaalife.data.quote.type,
            };

            if (userData.weight !== "" && userData.feet !== "" && userData.inches !== "" && userData.nicotine !== undefined) {
                BMIdata = aaalife.utils.validate.getBMI(userData);

                // Validate BMI and return insurance ranges
                healthConditions = aaalife.utils.validate.getHealthConditions(userData);

                healthConditions = (healthConditions && healthConditions.allowableHealthConditions) || [];
                _quoterValid.panel3.validBMI = BMIdata.valid;

                var attrData = transformData("itc", "");
                if (!aaalife.utils.prevalidate.hasValidBMI(userData)) {
                    if (BMIdata.valid === true) {
                        _applyKnockOut("n", false);
                    } else {
                        _applyKnockOut("y", false);
                    }
                }

                if (aaalife.data.quote.type === "traditional_term" && healthConditions.length > 0) {
                    _populateAndShowHealthConditions(healthConditions, healthConditions.length === 1, aaalife.data.quote.type);

                    if (healthConditions.length === 1) {
                        user.eligibleForOnlyFair = "yes";
                        browserStorage.setObject("user", user);
                        $rateYourHealth.val(healthConditions[0]);
                    } else {
                        user.eligibleForOnlyFair = "no";
                        browserStorage.setObject("user", user);
                    }
                } else {
                    _populateAndShowHealthConditions(healthConditions, true, aaalife.data.quote.type);
                }
            } else {
                $rateYourHealthContainer.addClass("hide");
            }
        }
    }

    // Helper Functions --------------------------------------------------

    /**
     * _currentSlideIndex
     *
     * @returns {*|jQuery}
     * @private
     */
    function _currentSlideIndex() {
        var currentSlideEle = $(".panel-container").children(".active");
        var currentSlide = $(currentSlideEle).index(); // For currentSlide
        // Number
        return currentSlide;
    }

    /**
     * _populateUserValuesIfSaved
     *
     * @private
     */
    function _populateUserValuesIfSaved() {
        var user = _getUserData();
        var quote = _getQuoteData();
        aaalife.data.user = user;
        aaalife.data.quote = quote;

        if ($('form[name="panelsQuoteFormTraditional"]').length > 0) {
            _setInsuranceTypeToTraditional();
        } else if ($('form[name="panelsQuoteFormExpress"]').length > 0 || $('form[name="expressTermCampaign"]').length > 0) {
            _setInsuranceTypeToExpress();
        }

        if (user.zip !== "") {
            $(".zipNumeric").val(user.zip);
            $("#zip").val(user.zip);
        }

        if (user.gender !== "") {
            $("#gender").val(user.gender);
            $("#genderSelectBoxItText").text(user.gender);
            $("#genderSelectBoxItText").attr("data-val", user.gender);
            $("#genderSelectBoxItText").attr("aria-label", user.gender);
        }

        _populateMonth(user);

        _populateDay(user);

        if (user.dobYear !== "") {
            $("#year").val(user.dobYear).trigger("change");
            $("#yearSelectBoxItText").text(user.dobYear);
            $("#yearSelectBoxItText").attr("data-val", user.dobYear);
            $("#yearSelectBoxItText").attr("aria-label", user.dobYear);
        }

        if (user.isMember === "Yes") {
            $("#isMemberYes").click();
        } else if (user.isMember === "No") {
            $("#isMemberNo").click();
        }

        if (user.email) {
            $("#contact_email").val(user.email);
            $("#emailAddress").val(user.email);
        }

        if (quote.type) {
            $('[value="' + aaalife.data.quote.type + '"]').click();
        }

        $("#feet").val(user.heightFeet);
        $("#feetSelectBoxItText").text(user.heightFeet);
        $("#feetSelectBoxItText").attr("data-val", user.heightFeet);
        $("#feetSelectBoxItText").attr("aria-label", user.heightFeet);

        $("#inches").val(user.heightInches);
        $("#inchesSelectBoxItText").text(user.heightInches);
        $("#inchesSelectBoxItText").attr("data-val", user.heightInches);
        $("#inchesSelectBoxItText").attr("aria-label", user.heightInches);

        $(".weightNumeric").val(user.weight);

        if (user.nicotineUsageKey) {
            setOptionAsSelected("nicotineUse", user.nicotineUsageKey);
        } else if (user.nicotine === "Yes") {
            setOptionAsSelected("nicotineUse", "nicotineUse-LessThan12MonthsAgo");
        }
        let health = "";
        if (user.health !== undefined) {
            health = user.health.toLowerCase();
            $("#rateYourHealth").val(health);
        }
        if (health === "fair") {
            $("#fair").click();
        } else if (health === "good") {
            $("#good").click();
        } else if (health === "excellent") {
            $("#excellent").click();
        }

        var itc = $("#quoteForm").data("panel-two");
        var source = "0";
        $("#panel1-next").data("itc", itc);
        $("#panel1-next").data("source", source);

        $("#panel3-back").data("itc", itc);
        $("#panel3-back").data("source", source);

        _setInitialDisplayState();
    }

    function _populateDay(user) {
        if (user.dobDay !== "") {
            $("#day").val(user.dobDay);
            $("#daySelectBoxItText").text(user.dobDay);
            $("#daySelectBoxItText").attr("data-val", user.dobDay);
            $("#daySelectBoxItText").attr("aria-label", user.dobDay);
        }
    }

    function _populateMonth(user) {
        if (user.dobMonth !== "") {
            $("#month").val(user.dobMonth).trigger("change");
            $("#monthSelectBoxItText").text(user.dobMonth);
            $("#monthSelectBoxItText").attr("data-val", user.dobMonth);
            $("#monthSelectBoxItText").attr("aria-label", user.dobMonth);
        }
    }

    function _setInsuranceTypeToTraditional() {
        var quote = _getQuoteData();
        aaalife.data.quote = quote;

        aaalife.data.quote.type = "traditional_term";

        browserStorage.setObject("quote", aaalife.data.quote);

        $('[value="traditional_term"]').click();
    }

    function _setInsuranceTypeToExpress() {
        var quote = _getQuoteData();
        aaalife.data.quote = quote;

        aaalife.data.quote.type = "express_term";

        browserStorage.setObject("quote", aaalife.data.quote);

        $('[value="express_term"]').click();
    }

    function _ETCampaignValidationProcess() {
        var i;
        var numPanels = 3;
        var topOfResultsPanel;
        var replacementPolicyFieldFilled;

        // validate all panels
        _validatePanelET();

        if ($('form[name="combinedQuoteForm"]').length > 0) {
            $("#expressTermForm").append('<input type="hidden" name="insurance_type" value="' + aaalife.data.quote.type + '">');
        }

        for (i = 1; i <= numPanels; i++) {
            _highlightErrorsET(i);
        }

        if (usedNicotine() || !_quoterValid.panel3.validBMI) {
            $("#healthConditionLabel").toggleClass("valid", true).toggleClass("invalid", false);
        }

        $("#BMIError").addClass("hide");
        $("#ageError").addClass("hide");
        $("#stateError").addClass("hide");
        $("#NYStateError").addClass("hide");
        $("#healthError").addClass("hide");
        $("#existingError").addClass("hide");

        if (_allPanelsValid() && !(errorFieldValidation.NYStateError || errorFieldValidation.stateError)) {
            _saveValues();
            _showInlineError(false);

            if (aaalife.fromServer.urlStrings.pageUrl.indexOf("homepage") !== -1) {
                aaalife.data.quote.origin = "/";
            } else {
                aaalife.data.quote.origin = aaalife.fromServer.urlStrings.pageUrl;
            }

            aaalife.data.quote.results = "";
            browserStorage.setObject("quote", aaalife.data.quote);

            var itc = $("#seeQuote").data("itc");
            if (itc != "") {
                $("#expressTermForm").append('<input type="hidden" name="interactionCode" value="' + itc + '">');
            }

            if (typeof aaalife.fromServer.emailSource != "undefined") {
                $("#expressTermForm").append(
                    '<input type="hidden" name="attr-source" value="' + aaalife.fromServer.emailSource + '">',
                );
            }

            return true;
        } else {
            _showInlineError(true);
            // show errors in all panels

            // email

            if (checkToSeeIdFieldsHaveValues()) {
                if (!_quoterValid.panel1.emailFormat) {
                    _showInlineError(false);
                    $("#DOBLabel").removeClass("invalid");
                    $("#heightLabel").removeClass("invalid");
                    $("#weightLabel").removeClass("invalid");
                    $("#zipLabel").removeClass("invalid");
                    $("#existingLabel").removeClass("invalid");
                    $("#emailLabel").removeClass("invalid");

                    $("#ageError").addClass("hide");
                    $("#stateError").addClass("hide");
                    $("#NYStateError").addClass("hide");
                    $("#healthError").addClass("hide");
                    $("#existingError").addClass("hide");
                    $("#BMIError").addClass("hide");
                    $("#emailError").removeClass("hide");
                } else {
                    $("#emailError").addClass("hide");
                }
            }

            if (checkToSeeIdFieldsHaveValues()) {
                var yes = document.getElementById("existingPolicyYesLink");
                if (_hasClass(yes, "radioSelected") && errorFieldValidation.existingPolicy) {
                    _showInlineError(false);
                    $("#existingLabel").removeClass("invalid");
                    $("#BMIError").addClass("hide");
                    $("#ageError").addClass("hide");
                    $("#NYStateError").addClass("hide");
                    $("#stateError").addClass("hide");
                    $("#healthError").addClass("hide");
                    $("#emailError").addClass("hide");
                    $("#existingError").removeClass("hide");
                } else {
                    $("#existingError").addClass("hide");
                }
            }

            if (checkToSeeIdFieldsHaveValues()) {
                if (errorFieldValidation.NYStateError) {
                    _showInlineError(false);
                    $("#zipLabel").removeClass("invalid");
                    $("#existingLabel").removeClass("invalid");

                    $("#BMIError").addClass("hide");
                    $("#ageError").addClass("hide");
                    $("#stateError").addClass("hide");
                    $("#healthError").addClass("hide");
                    $("#existingError").addClass("hide");
                    $("#emailError").addClass("hide");
                    $("#NYStateError").removeClass("hide");
                } else {
                    if (errorFieldValidation.stateError) {
                        $("#zipLabel").removeClass("invalid");
                        $("#existingLabel").removeClass("invalid");
                        $("#BMIError").addClass("hide");
                        $("#ageError").addClass("hide");
                        $("#NYStateError").addClass("hide");
                        $("#healthError").addClass("hide");
                        $("#existingError").addClass("hide");
                        $("#emailError").addClass("hide");
                        $("#stateError").removeClass("hide");
                    } else {
                        $("#stateError").addClass("hide");
                        $("#NYStateError").addClass("hide");
                    }
                }
            }

            // age

            if (checkToSeeIdFieldsHaveValues()) {
                if (!_quoterValid.panel1.age) {
                    _showInlineError(false);
                    $("#DOBLabel").removeClass("invalid");
                    $("#heightLabel").removeClass("invalid");
                    $("#weightLabel").removeClass("invalid");
                    $("#zipLabel").removeClass("invalid");
                    $("#existingLabel").removeClass("invalid");

                    $("#BMIError").addClass("hide");
                    $("#stateError").addClass("hide");
                    $("#NYStateError").addClass("hide");
                    $("#healthError").addClass("hide");
                    $("#existingError").addClass("hide");
                    $("#emailError").addClass("hide");
                    $("#ageError").removeClass("hide");
                } else {
                    $("#ageError").addClass("hide");
                }
            }

            // BMI

            if (checkToSeeIdFieldsHaveValues()) {
                if (!_quoterValid.panel3.validBMI) {
                    _showInlineError(false);
                    $("#DOBLabel").removeClass("invalid");
                    $("#heightLabel").removeClass("invalid");
                    $("#weightLabel").removeClass("invalid");
                    $("#zipLabel").removeClass("invalid");
                    $("#existingLabel").removeClass("invalid");

                    $("#ageError").addClass("hide");
                    $("#stateError").addClass("hide");
                    $("#NYStateError").addClass("hide");
                    $("#healthError").addClass("hide");
                    $("#existingError").addClass("hide");
                    $("#emailError").addClass("hide");
                    $("#BMIError").removeClass("hide");
                } else {
                    $("#BMIError").addClass("hide");
                }
            }

            return false;
        }
    }

    function _hasClass(element, cls) {
        return (" " + element.className + " ").indexOf(" " + cls + " ") > -1;
    }

    function checkToSeeIdFieldsHaveValues() {
        // jjk-b
        var zip = $("#zip").val();
        var gender = $("#gender").val();
        var day = $("#day").val();
        var month = $("#month").val();
        var year = $("#year").val();
        var ageValidation = aaalife.utils.validate.age(month, day, year);
        var feet = $("#feet").val();
        var inches = $("#inches").val();
        var weight = $("#weight").val();

        var isMember = $('[name="isMember"]:checked:first').val();

        if (
            weight != "" &&
            inches != "" &&
            feet != "" &&
            zip != "" &&
            (gender != "" || gender != "Unknown" || gender != null) &&
            year != "" &&
            month != "" &&
            day != "" &&
            usedNicotineValueSelected() &&
            (isMember === "No" || isMember === "Yes")
        ) {
            //jjk-e
            var overallHealth = document.getElementById("overAllHealth");
            var replacement = document.getElementById("replaceExistingPolicy");
            var shouldHighlight = true;
            if (!_hasClass(overallHealth, "hide") && !checkOverallHealth()) {
                shouldHighlight = false;
            }
            if (!_hasClass(replacement, "hide") && !checkReplacement()) {
                shouldHighlight = false;
            }
            return shouldHighlight;
        }
        return false;
    }

    function checkOverallHealth() {
        return (
            $('[name="rateYourHealth"]:checked:first').val() === "FAIR" ||
            $('[name="rateYourHealth"]:checked:first').val() === "GOOD" ||
            $('[name="rateYourHealth"]:checked:first').val() === "EXCELLENT"
        );
    }

    function checkReplacement() {
        var yes = document.getElementById("existingPolicyYesLink");
        var no = document.getElementById("existingPolicyNoLink");
        return _hasClass(yes, "radioSelected") || _hasClass(no, "radioSelected");
    }

    function calculateBmi() {
        return aaalife.utils.validate.getBMI({
            weight: $(".weightNumeric").val(),
            feet: $("#feet").val(),
            inches: $("#inches").val(),
            quoteType: aaalife.data.quote.type,
        });
    }

    function shouldDisplayHealthQuestions() {
        if (calculateBmi().bmi >= 17.5 && calculateBmi().bmi <= 31 && usedNicotineValue() === "No") {
            return true;
        }
        return false;
    }

    async function _seeQuoteClicked() {
        var i;
        var numPanels = 3;
        var topOfResultsPanel;
        var replacementPolicyFieldFilled;

        // validate all panels
        for (i = 0; i < numPanels; i++) {
            _validatePanel(i);
        }

        if ($('form[name="combinedQuoteForm"]').length > 0) {
            $("#quoteForm").append('<input type="hidden" name="insurance_type" value="' + aaalife.data.quote.type + '">');
        }

        if ($('form[name="combinedQuoteForm"]').length > 0 || $('form[name="panelsQuoteFormTraditional"]').length > 0) {
            if (aaalife.data.quote.type === "traditional_term") {
                $("#quoteForm").data("special-action", "sf-p");
            }
        }

        if (_allPanelsValid()) {
            _saveValues();
            _showInlineErrorForQuoteForm(false);
            if (aaalife.fromServer.urlStrings.pageUrl.indexOf("homepage") !== -1) {
                aaalife.data.quote.origin = "/";
            } else {
                aaalife.data.quote.origin = aaalife.fromServer.urlStrings.pageUrl;
            }

            if (
                $('form[name="panelsQuoteForm"]').length > 0 ||
                $('form[name="panelsQuoteFormTraditional"]').length > 0 ||
                $('form[name="panelsQuoteFormExpress"]').length > 0
            ) {
                aaalife.data.quote.panelFlag = "true";
            }
            aaalife.data.quote.results = "";
            browserStorage.setObject("quote", aaalife.data.quote);

            var itc = $("#seeQuote").data("itc");
            if (itc != "") {
                $("#quoteForm").append('<input type="hidden" name="interactionCode" value="' + itc + '">');
            }

            if (typeof aaalife.fromServer.emailSource != "undefined") {
                $("#quoteForm").append(
                    '<input type="hidden" name="attr-source" value="' + aaalife.fromServer.emailSource + '">',
                );
                checkAndSetEmailSource(aaalife.data.user.source);
            }

            if (aaalife.data.quote.type === "express_term") {
                // HACK: Send blank payload to aggregation to see if aggregationSessionId in response has changed
                const response = await sendReceiveData({}, aaalife.ctx + "restservices/aggregate", false);
                if (document.getElementById("sessionID").value != response.aggregationSessionId) {
                    window.location.href = "/session-expired";
                    return;
                }

                try {
                    const { etSubdomain, etQuoteTransferApiUrl: transferedQuoteApiUrl } = aaalife.fromServer.urlStrings;
                    const et2Url = await transferQuoteToEt2(transferedQuoteApiUrl, window.location.href, etSubdomain);
                    window.location.href = et2Url;
                } catch (error) {
                    console.error(error);
                    window.location.href = "/unexpected-error";
                }
            } else {
                $("#quoteForm").submit();
            }
        } else {
            replacementPolicyFieldFilled = _hasValue.call(_quoterValid.panel3Fields.existingPolicyField);
            if (
                replacementPolicyFieldFilled &&
                !_quoterValid.panel3.existingPolicy &&
                _zipRestrictions.replacementPolicyNotOffered
            ) {
                _showErrorModal(aaalife.fromServer.errorModalsData.existingPolicy, true, $("#zip").val());
            }

            _showInlineErrorForQuoteForm(true);

            if (isPanelBMIInvalidWithData()) {
                _showErrorModal(aaalife.fromServer.errorModalsData.noQuoteAvailable, true, $("#zip").val());
            }

            // show errors in all panels
            for (i = 1; i <= numPanels; i++) {
                _highlightErrors(i);
            }

            var itc = $("#seeQuote").data("itc");
            $("#seeQuote").data("itc", "");
            var formData = collectFormData($("#quoteForm"), $("#seeQuote").data());
            if (
                !_quoterValid.panel3.validBMI &&
                $("#year").val() !== "" &&
                $("#feet").val() !== "" &&
                $("#inches").val() !== "" &&
                $("#weight").val() !== ""
            ) {
                formData = appendData(formData, "ko", "y");
            }
            sendData(formData, false);
            $("#seeQuote").data("itc", itc);

            if (isPanelBMIInvalidWithData()) {
                const evt =
                    $(".combinedForm-quoteForm").length > 0
                        ? "TQLKO" // Event for combined forms
                        : "TQSKO2"; // Event for panel forms
                sendEvent(evt);
            }
        }
    }

    function isPanelBMIInvalidWithData() {
        if (
            !_quoterValid.panel3.validBMI &&
            $("#year").val() !== "" &&
            $("#feet").val() !== "" &&
            $("#inches").val() !== "" &&
            $("#weight").val() !== ""
        ) {
            return true;
        }
        return false;
    }

    // Slide Panels -----------------------------------------------------

    /**
     * _getCurrentPanel
     *
     * @returns {string}
     * @private
     */
    function _getCurrentPanel() {
        var currentPanelToCheck = "panel" + (_currentSlideIndex() + 1);
        return currentPanelToCheck;
    }

    async function _landingSubmitClicked() {
        // show loading dialogue while sending user to expressterm
        $("#myModal").show();
        var form = $(this).closest("form");
        var button = $(this).closest("button");
        var formData = collectFormData(form, button.data());
        if ($("#expressTermForm").length > 0) {
            aaalife.utils.expressTerm.appendHiddenFormData("#expressTermForm");
        } else {
            aaalife.utils.expressTerm.appendHiddenFormData("#quoteForm");
        }

        var isValid = _ETCampaignValidationProcess();

        // HACK: Determine whether an artifical 100ms timeout is really necessary in either or both
        // cases below depending on isValid value.
        const timeout = new Promise((resolve, reject) => {
            setTimeout(function () {
                resolve();
            }, 100);
        });

        await timeout;

        if (isValid) {
            await sendDataAsync(formData);
            var etAction = transformData("action", "toET");
            var etData = transformData("attr", etAction);
            await sendDataAsync(etData);
            // HACK: Send blank payload to aggregation to see if aggregationSessionId in response has changed
            const response = await sendReceiveData({}, aaalife.ctx + "restservices/aggregate", false);
            if (document.getElementById("sessionID").value != response.aggregationSessionId) {
                window.location.href = "/session-expired";
                return;
            }

            try {
                const { etSubdomain, etQuoteTransferApiUrl: transferedQuoteApiUrl } = aaalife.fromServer.urlStrings;
                const et2Url = await transferQuoteToEt2(transferedQuoteApiUrl, window.location.href, etSubdomain);
                window.location.href = et2Url;
            } catch (error) {
                console.error(error);
                window.location.href = "/unexpected-error";
            }
        } else {
            // hide expressterm loading dialogue since validation failed
            $("#myModal").hide();
            _applyKnockOut("y", false);
            sendData(formData, false);
        }
    }

    /**
     * _nextClicked
     *
     * @private
     */
    function _nextClicked() {
        var isValid;
        var currentPanel = _getCurrentPanel();

        // ensure current panel is validated
        _quoterValid = _validatePanel();

        // are all validation points valid?
        isValid = Object.keys(_quoterValid[currentPanel]).every(function (key) {
            return _quoterValid[currentPanel][key];
        });

        var form = $(this).closest("form");
        var button = $(this).closest("button");
        if (isValid) {
            _saveValues();
            _showInlineErrorForQuoteForm(false);

            var formData = collectFormData(form, button.data());
            if (aaalife.data.quote.type != "") {
                var currentData = formData["current"];
                currentData["insurance_type"] = aaalife.data.quote.type;
                formData["current"] = currentData;
            }

            if (
                aaalife.data.quote.type === "traditional_term" &&
                ((aaalife.data.quote.type && aaalife.fromServer.quoteType) || _zipRestrictions.expressTermNotOffered)
            ) {
                $("#panel1-next").data("special-action", "sf-p");
            }

            const response = sendData(formData, false);
            if (response.status === 401) {
                // HACK: For now until we remove use of server side sessions we redirect
                // the user to /session-expired page in the event of a failed expert request.
                window.location.href = "/session-expired";
            } else if (response.status != 200) {
                window.location.href = "/unexpected-error";
            } else {
                _panelSlide("next");
            }
        } else {
            if (_zipRestrictions.allTermNotOffered) {
                _showErrorModal(aaalife.fromServer.errorModalsData.allTermNotOffered);
            }

            _showInlineErrorForQuoteForm(true);
            _highlightErrors();
            var itc = button.data("itc");
            button.data("itc", "");
            var formData = collectFormData(form, button.data());
            sendData(formData, false);
            button.data("itc", itc);
        }
    }

    /**
     * _backClicked
     *
     * @private
     */
    function _backClicked() {
        var button = $(this).closest("button");
        var data = transformData("itc", button.data("itc"));
        var groupData = transformData("attr", data);
        sendData(groupData, false);
        _showInlineErrorForQuoteForm(false);
        _panelSlide("back");
    }

    /**
     * _panelSlide
     *
     * @param type
     *            {string=}
     * @returns {boolean}
     * @private
     */
    function _panelSlide(type) {
        var panelContainerChildren = $(".panel-container").children();
        var currentSlide = _currentSlideIndex();
        var toBeCurrentSlide;
        var insurance_type;
        var slideLeft;
        var left;
        var i;

        // Wide format: no sliding
        if (aaalife.fromServer.quoteSize !== "qfWide") {
            if (type == "back") {
                toBeCurrentSlide = currentSlide - 1;

                if (
                    toBeCurrentSlide === 1 &&
                    ((aaalife.data.quote.type && aaalife.fromServer.quoteType) || _zipRestrictions.expressTermNotOffered)
                ) {
                    // if there is already a type, skip the interstitial
                    toBeCurrentSlide = 0;
                }
                if (toBeCurrentSlide === 0) {
                    if (!aaalife.fromServer.quoteType) {
                        aaalife.data.quote.type = "";
                    }
                    $(".quoteForm-redCircleBlock").removeClass("hide");
                    $("#item2").add($("#item3")).removeClass("hide");
                    $("#item1").removeClass("expand");
                }
            } else if (type == "next") {
                toBeCurrentSlide = currentSlide + 1;

                if (toBeCurrentSlide > 0) {
                    $(".quoteForm-redCircleBlock").addClass("hide");
                    $("#item2").add($("#item3")).addClass("hide");
                    $("#item1").addClass("expand");
                }

                /* ====ExpressTerm and TraditionalTerm panel logic=== */
                if (
                    toBeCurrentSlide === 1 &&
                    ((aaalife.data.quote.type && aaalife.fromServer.quoteType) || _zipRestrictions.expressTermNotOffered)
                ) {
                    // if there is already a type, skip the interstitial
                    toBeCurrentSlide = 2;
                    insurance_type = aaalife.data.quote.type;
                }
                if (toBeCurrentSlide === 2 && !insurance_type) {
                    insurance_type = $("input[name=insurance_type]:checked").val();
                    /* ==== Set panel 2 based on insurance type === */
                    if (insurance_type) {
                        aaalife.data.quote.type = insurance_type;
                        _validateHealthConditions();
                    } else {
                        _highlightInvalidField(false, $("input[name=insurance_type]"));
                        return false;
                    }
                }
                _setCoverageAndTermOptions(insurance_type, false);
            } else {
                toBeCurrentSlide = currentSlide;
            }

            /* = Existing policy error pop up= */
            if (toBeCurrentSlide === 3) {
                toBeCurrentSlide = currentSlide;
            }

            slideLeft = $("body").width() > 768 ? 350 : $("body").width();

            $("#quoteForm .panel-container").children().width($("#quoteForm").width());
            for (i = 0; i < panelContainerChildren.length; i++) {
                left = i * slideLeft - toBeCurrentSlide * slideLeft;
                if (toBeCurrentSlide == i) {
                    $(panelContainerChildren[i]).addClass("active").removeClass("hide");
                } else {
                    $(panelContainerChildren[i]).removeClass("active").addClass("hide");
                }

                $(panelContainerChildren[i]).css("left", left + "px");
            }

            var $activePanel = $("#quoteForm .panel-container .active");
            var $invalidFields = $activePanel.find(".invalid");
            if ($invalidFields.length > 0) {
                _showInlineError(true);
            } else {
                _showInlineError(false);
            }

            // scroll to the top of the form, if needed
            if ($("#quoteForm").length > 0) {
                _repositionWindow($("#quoteForm"));
            } else if ($("#expressTermForm").length > 0) {
                _repositionWindow($("#expressTermForm"));
            }
        }

        //Look at this
        if ($activePanel) {
            var evt = $activePanel.data("event");
            if (evt) sendEvent(evt);
        }

        // on click of next button, apply bottom blue border class as needed
        if ($("#quoteForm").length) {
            $("#accordion").toggleClass("contentBorder", toBeCurrentSlide > 0);
        }
    }

    // Modify Display
    // -------------------------------------------------------------

    function _repositionWindow(formToOffset) {
        var topOfForm = formToOffset.offset().top - 80;
        if (window.scrollY > topOfForm) {
            window.scrollTo(0, topOfForm);
        }
    }

    /**
     * _markDirty
     *
     * @private
     */
    function _markDirty() {
        $(this).removeClass("pristine").addClass("dirty");
    }

    /**
     * _highlightInvalidField
     *
     * @param isValid
     * @param $argElement
     * @private
     */
    function _highlightInvalidField(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            _showInlineError(false);
            $element.removeClass("invalid").addClass("valid");
        } else {
            _showInlineError(true);
            $element.removeClass("valid").addClass("invalid");
        }
    }

    function _showErrorModal(text, shouldUseDynamicPhone, zip) {
        $(".error-modal .error-modal-heading").html(text.heading);
        $(".error-modal .error-desc").html(text.desc);
        $(".error-modal .error-secondarydesc").html(text.desc2);

        if (shouldUseDynamicPhone) {
            var params = retrieveUrlParameters();

            var cmp = params.cmp;
            var lead = params.lead;
            getClubAndCampaignProfile(zip, cmp, lead)
                .then(updateDynamicPhone)
                .catch((error) => console.error("Error updating phone", error));
        }

        _inlineModal.open();
    }

    /**
     * _showInlineError
     *
     * @param show
     * @private
     */
    function _showInlineError(show) {
        $(".inlineValidation").toggleClass("show", show);
    }

    /**
     * _showInlineError
     *
     * @param show
     * @private
     */
    function _showInlineErrorForQuoteForm(show) {
        $(".inlineValidation-quote").toggleClass("show", show);
    }

    /**
     * _highlightErrors
     *
     * @param panel
     * @private
     */
    function _highlightErrors(panel) {
        var currentPanel = panel || _getCurrentPanel();
        if (panel) {
            currentPanel = "panel" + currentPanel;
        }

        Object.keys(_quoterValid[currentPanel]).forEach(function (key) {
            _quoterValid[currentPanel + "Fields"][key + "Field"]
                .toggleClass("valid", _quoterValid[currentPanel][key])
                .toggleClass("invalid", !_quoterValid[currentPanel][key]);
        });
    }

    function _highlightErrorsET(panel) {
        var currentPanel = panel || _getCurrentPanel();
        if (panel) {
            currentPanel = "panel" + currentPanel;
        }

        Object.keys(_quoterValid[currentPanel]).forEach(function (key) {
            if (key == "validBMI") {
                // _quoterValid[currentPanel + 'Error'][key + 'Error']
                // .toggleClass('valid', ($("#weight").val() != "" &&
                // $("#inches").val() != "" && $("#feet").val() != "" ))
                // .toggleClass('invalid', !($("#weight").val() != "" &&
                // $("#inches").val() != "" && $("#feet").val() != ""
                // ));
            } else {
                _quoterValid[currentPanel + "Error"][key + "Error"]
                    .toggleClass("valid", _quoterValid[currentPanel][key])
                    .toggleClass("invalid", !_quoterValid[currentPanel][key]);
            }
        });
    }

    /**
     * _setInitialDisplayState
     *
     * @private
     */
    function _setInitialDisplayState() {
        if ($(".zipNumeric").val()) {
            _validateZip.call($(".zipNumeric")[0]);
        }
        _validateHealthConditions();
    }

    /**
     * _populateDropdowns
     *
     * @param dropdown
     * @param startVal
     * @param maxVal
     * @param dirMod
     * @private
     */
    function _populateDropdowns(dropdown, startVal, maxVal, dirMod, doubledigits) {
        var i;
        for (i = startVal; startVal > maxVal ? i >= maxVal : i <= maxVal; i += dirMod) {
            if ($("#expressTermForm").length > 0) {
                if (i < 10) {
                    dropdown.append(
                        $("<option></option>")
                            .val("0" + i)
                            .html("0" + i),
                    );
                } else {
                    dropdown.append($("<option></option>").val(i).html(i));
                }
            } else {
                dropdown.append($("<option></option>").val(i).html(i));
            }
        }
    }

    /**
     * _populateDaysDropdown
     *
     * @private
     */
    function _populateDaysDropdown() {
        var month = $("#month").val() * 1 || new Date().getMonth();
        var year = $("#year").val() * 1 || 2008; //in the case year hasn't been entered yet, use a year that allows Feb 29 by default
        var $day = $("#day");
        var oldDay = $day.val();
        var numDays = new Date(year, month, 0).getDate();

        $day.find("option").not(":first-child").remove();

        _populateDropdowns($day, 1, numDays, 1);

        if (oldDay && oldDay <= numDays) {
            $day.val(oldDay);
        } else if ($day.hasClass("dirty") || $day.hasClass("pristine")) {
            $day.val("");
            if ($day.hasClass("dirty") && oldDay) {
                $day.removeClass("valid").addClass("invalid");
            }
            _quoterValid.panel1.age = false;
        }
    }

    function hasValueDropDown(dropDown, value) {
        var exist = false;
        if (typeof dropDown[0] != "undefined") {
            var options = dropDown[0].options;
            for (var i = 0; i < options.length; ++i) {
                if (options[i].value === value) {
                    exist = true;
                    break;
                }
            }
        }
        return exist;
    }

    /**
     * _populateAndShowHealthConditions
     *
     * @param healthConditions
     * @param shouldHide
     * @private
     */
    function _populateAndShowHealthConditions(healthConditions, shouldHide, quoteType) {
        var $dropdown = $("#rateYourHealth");
        var $container = $(".quoteForm-rateYourHealth");
        var originalHCVal = $dropdown.val();
        var nicotineClass = ".nicotine";
        var nonNicotineClass = ".nonnicotine";
        const health =
            aaalife.data.user.health != "" && aaalife.data.user.health !== undefined
                ? aaalife.data.user.health.toLowerCase()
                : "";

        $container.toggleClass("hide", shouldHide);

        if (quoteType === "traditional_term") {
            $dropdown.find("option").not(":first-child").remove();
            Object.keys(COND_MAP_TRADITIONAL).forEach(function (condition) {
                aaalife.data.triggerEl
                    .data("modal-object")
                    .$contentArea.find("." + condition)
                    .addClass("hide");
                $("." + condition).addClass("hide");

                if (healthConditions.indexOf(condition) > -1) {
                    $dropdown.append($("<option>").val(condition).text(COND_MAP_TRADITIONAL[condition]));
                    if (usedNicotine()) {
                        //first, looks into error modal and adds/removes class as appropriate there
                        aaalife.data.triggerEl
                            .data("modal-object")
                            .$contentArea.find("." + condition + nonNicotineClass)
                            .addClass("hide");
                        aaalife.data.triggerEl
                            .data("modal-object")
                            .$contentArea.find("." + condition + nicotineClass)
                            .removeClass("hide");
                        //then, looks on page and adds/removes class as appropriate. Handles case where error modal has already been shown
                        $("." + condition + nonNicotineClass).addClass("hide");
                        $("." + condition + nicotineClass).removeClass("hide");
                    } else {
                        aaalife.data.triggerEl
                            .data("modal-object")
                            .$contentArea.find("." + condition + nicotineClass)
                            .addClass("hide");
                        aaalife.data.triggerEl
                            .data("modal-object")
                            .$contentArea.find("." + condition + nonNicotineClass)
                            .removeClass("hide");

                        $("." + condition + nicotineClass).addClass("hide");
                        $("." + condition + nonNicotineClass).removeClass("hide");
                    }
                }
            });

            if (originalHCVal && $dropdown.find('option[value="' + originalHCVal + '"]').length > 0) {
                $dropdown.val(originalHCVal);
            }

            if (hasValueDropDown($dropdown, health)) {
                $dropdown.val(health);
            } else {
                $dropdown.get(0).selectedIndex = 0;
            }
        } else if (quoteType === "express_term") {
            if (shouldHide) {
                return;
            }
            $dropdown.find("option").not(":first-child").remove();
            Object.keys(COND_MAP_EXPRESS).forEach(function (condition) {
                $dropdown.append($("<option>").val(condition).text(COND_MAP_EXPRESS[condition]));
            });

            if (originalHCVal && $dropdown.find('option[value="' + originalHCVal + '"]').length > 0) {
                $dropdown.val(originalHCVal);
            }

            if (hasValueDropDown($dropdown, health)) {
                $dropdown.val(health);
            } else {
                if (typeof $dropdown.get(0) !== "undefined") {
                    $dropdown.get(0).selectedIndex = 0;
                }
            }
        }
    }

    /**
     * _validateExistingPolicy
     *
     * @returns {boolean}
     * @private
     */
    function _validateExistingPolicy(e) {
        var existingPolicyValidation = aaalife.utils.validate.validateExistingPolicy(
            $(this),
            aaalife.data.quote.type,
            _zipRestrictions.replacementPolicyNotOffered,
        );

        if ($("#expressTermForm").length > 0) {
            _highlightInvalidField(existingPolicyValidation.requiredValid, $("#existingLabel"));
        } else {
            _highlightInvalidField(existingPolicyValidation.requiredValid, $('[name="policy_type"]'));
        }

        if (!existingPolicyValidation.valid && e instanceof jQuery.Event) {
            _showErrorModal(aaalife.fromServer.errorModalsData.existingPolicy, true, _zipRestrictions.zip);
        }

        return existingPolicyValidation.requiredValid && existingPolicyValidation.valid;
    }

    /**
     * _setCoverageAndTermOptions
     *
     * @param insurance_type
     * @private
     */
    function _setCoverageAndTermOptions(insurance_type, isInitilize) {
        const age = ageLastBirthday(createDate($("#year").val(), $("#month").val(), $("#day").val()), new Date());
        var expressCoverages = _getExpressCoverages(age);
        var traditionalCoverages = _getTraditionalCoverages();
        var termLengthOptions =
            insurance_type === "express_term" ? getTermLengthOptionsForExpressTerm() : getTermLengthOptionsForTraditionalTerm();
        var $coverageAmount = $("#coverageAmount");
        var orgCoverageAmount = $coverageAmount.val();
        var $termLength = $("#termLength");
        var orgTermLength = $termLength.val();
        var quote = _getQuoteData();
        var termLengths;
        var savedCoverageAmount;
        var savedTermLength;
        var calcCoverageAmount;

        if (!isInitilize) {
            if (orgTermLength === "") {
                savedTermLength = "";
            } else {
                savedTermLength = quote.term;
            }
        } else {
            savedTermLength = quote.term;
        }

        if (!isInitilize) {
            savedCoverageAmount = "";
        } else {
            calcCoverageAmount = _determineCoverageAmount(quote, insurance_type, expressCoverages, traditionalCoverages);
            savedCoverageAmount = calcCoverageAmount;
        }

        $(".panel-panel3").removeClass("express_term traditional_term").addClass(insurance_type);

        if (insurance_type === "express_term") {
            $coverageAmount.find("option").remove().end().append('<option value="" selected hidden>Select</option>');
            expressCoverages.forEach(_createCoveragesOption);
        }
        if (insurance_type === "traditional_term") {
            $coverageAmount.find("option").remove().end();
            traditionalCoverages.forEach(_createCoveragesOption);
            if (savedCoverageAmount == "") {
                $coverageAmount.val("500,000");
            }
        }

        if (
            orgCoverageAmount &&
            $coverageAmount.find('option[value="' + orgCoverageAmount + '"]').length > 0 &&
            !$coverageAmount.val()
        ) {
            $coverageAmount.val(orgCoverageAmount);
        }

        // Get appropriate set by age
        termLengths = termLengthOptions[Object.keys(termLengthOptions).find(_findAgeGrouping)] || [];

        // RULE (as of May 2016) - If user enters age >50 or age >45 for ET2 and Nicotine = Yes,
        // remove 30 year term from Term Length dropdown
        if (_disallowThirty && termLengths.indexOf(30) !== -1) {
            termLengths.splice(termLengths.indexOf(30), 1);
        }
        $termLength.find("option").remove().end().append('<option value="" selected hidden>Select</option>');
        termLengths.forEach(_createTermLengthOption);

        if (orgTermLength && $termLength.find('option[value="' + orgTermLength + '"]').length > 0 && !$termLength.val()) {
            $termLength.val(orgTermLength);
        }

        function _determineCoverageAmount(quote, insurance_type, expressCoverages, traditionalCoverages) {
            var estimatedNeed = quote.estimatedNeed && quote.estimatedNeed.replace(/,/g, "") * 1;
            var coverageArray = [];
            var amount = quote.coverage;
            var arrayValue;
            var found;
            var i;

            if (estimatedNeed && insurance_type) {
                if (insurance_type === "express_term") {
                    coverageArray = expressCoverages;
                } else if (insurance_type === "traditional_term") {
                    coverageArray = traditionalCoverages;
                }

                found = false;
                for (i = 0; i < coverageArray.length; i++) {
                    arrayValue = coverageArray[i].replace(/,/g, "") * 1;
                    if (arrayValue >= estimatedNeed) {
                        amount = coverageArray[i];
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    amount = coverageArray[coverageArray.length - 1];
                }

                // Clear out estimateNeed after first use, so it doesn't affect
                // later calculations
                quote.estimatedNeed = "";
                aaalife.data.quote.estimatedNeed = quote.estimatedNeed;
                browserStorage.setObject("quote", aaalife.data.quote);
            }
            return amount;
        }

        // Rules (express_term) (as of NOVEMBER 2016)
        // AGES 18-59 - Include '25,000', '30,000', '35,000', '40,000',
        // '45,000', '50,000', '60,000', '70,000', '80,000', '90,000',
        // '100,000', '125,000', '150,000', '175,000', '200,000', '225,000',
        // '250,000' in drop down
        // AGES 60-64 - Include '25,000', '30,000', '35,000', '40,000',
        // '45,000', '50,000', '55,000', '60,000', '65,000', '70,000', '75,000',
        // '80,000', '85,000', '90,000', '95,000', '100,000' in drop down
        // AGES 65-69 - Include '25,000', '30,000', '35,000', '40,000',
        // '45,000', '50,000'
        // AGES 70-75 - Include '25,000' in drop down
        /**
         * _getExpressCoverages
         *
         * @params {number} age
         * @returns {Array}
         * @private
         */
        function _getExpressCoverages(age) {
            return [
                "25,000",
                "50,000",
                "75,000",
                "100,000",
                "125,000",
                "150,000",
                "175,000",
                "200,000",
                "225,000",
                "250,000",
                "300,000",
                "350,000",
                "400,000",
                "450,000",
                "500,000",
            ];
        }

        /**
         * _findAgeGrouping
         *
         * @param key
         * @returns {*}
         * @private
         */
        function _findAgeGrouping(key) {
            var min = key.split("-")[0] * 1;
            var max = key.split("-")[1] * 1;

            if (age >= min && age <= max) {
                return key;
            }
            return false;
        }

        /**
         * _createExpressCoveragesOption
         *
         * @param coverageAmount
         * @private
         */
        function _createCoveragesOption(coverageAmount) {
            var selectedString = "";
            if (coverageAmount == savedCoverageAmount) {
                selectedString = ' selected="selected"';
            }
            $coverageAmount.append(
                "<option" + selectedString + ' value="' + coverageAmount + '">$' + coverageAmount + "</option>",
            );
        }

        /**
         * _createTraditionalCoveragesOption
         *
         * @param coverageAmount
         * @private
         */
        function _createTraditionalCoveragesOption(coverageAmount) {
            var selectedString = "";
            if (coverageAmount == savedCoverageAmount) {
                selectedString = ' selected="selected"';
            }
            $coverageAmount.append(
                "<option" + selectedString + ' value="' + coverageAmount + '">$' + coverageAmount + "</option>",
            );
        }

        /**
         * _createTermLengthOption
         *
         * @param termLength
         * @private
         */
        function _createTermLengthOption(termLength) {
            var selectedString = "";
            if (termLength == savedTermLength) {
                selectedString = ' selected="selected"';
            }
            $termLength.append("<option" + selectedString + ' value="' + termLength + '">' + termLength + " Years</option>");
        }
    }

    // Rules (traditional_term) (as of May 2016)
    // ALWAYS SHOW: $100,000, $150,000, $200,000, $250,000, $300,000,
    // $350,000, $400,000, $450,000, $500,000, $550,000,
    // $600,000, $650,000, $700,000, $750,000, $800,000,
    // $850,000, $900,000, $950,000, $1,000,000, and $2,000,000
    /**
     * _getTraditionalCoverages
     *
     * @returns {string[]}
     * @private
     */
    function _getTraditionalCoverages() {
        return [
            "100,000",
            "150,000",
            "200,000",
            "250,000",
            "300,000",
            "350,000",
            "400,000",
            "450,000",
            "500,000",
            "550,000",
            "600,000",
            "650,000",
            "700,000",
            "750,000",
            "800,000",
            "850,000",
            "900,000",
            "950,000",
            "1,000,000",
            "2,000,000",
        ];
    }

    // Rules (both) (as of May 2016)
    // Ages 18-55 show 10, 15, 20, 25, 30
    // Ages 55-60 show 10, 15, 20, 25
    // Ages 60-65 show 10, 15, 20
    // Ages 65-70 show 10, 15
    // Ages 70-75 show 10
    /**
     * _getTermLengthOptions
     *
     * @returns {{0-55: number[], 55-60: number[], 60-65: number[], 65-70:
     *          number[], 70-125: number[]}}
     * @private
     */
    function getTermLengthOptionsForTraditionalTerm() {
        return {
            "0-55": [10, 15, 20, 30],
            "55-65": [10, 15, 20],
            "65-70": [10, 15],
            "70-125": [10],
        };
    }

    function getTermLengthOptionsForExpressTerm() {
        return {
            "18-55": [10, 15, 20, 30],
            "55-65": [10, 15, 20],
            "65-70": [10, 15],
            "70-75": [10],
        };
    }
}
