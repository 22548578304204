import $ from "jquery";

export function aaalifeBaseInit() {
    return {
        utils: {},
        data: {
            user: {
                zip: "",
                gender: "",
                dobMonth: "",
                dobDay: "",
                dobYear: "",
                isMember: "",
                email: "",
                hasPolicy: "",
                heightFeet: "",
                heightInches: "",
                weight: "",
                health: "",
                nicotine: "",
                eligibleForOnlyFair: "",
                firstName: "",
                lastName: "",
                phone: "",
                city: "",
                state: "",
                salary: "",
                PCIN: "",
                result: "",
                status: "",
                source: "",
            },
            quote: {
                type: "",
                term: "",
                coverage: "",
                premium: "",
                estimatedNeed: "",
                results: "",
                origin: "",
                panelFlag: "",
            },
            validation: {},
            agentQuote: {
                state: "Select One",
                gender: "",
                dobMonth: "MM",
                dobDay: "DD",
                dobYear: "YYYY",
                riskClass: "Select One",
                quoteResults: {
                    coverageAmount: "500000",
                    quoteResultsString: "",
                    premiumAmount10Year: "",
                    premiumAmount15Year: "",
                    premiumAmount20Year: "",
                    premiumAmount25Year: "",
                    premiumAmount30Year: "",
                    quoteResultsStringROP: "",
                    ropPremiumAmount10Year: "",
                    ropPremiumAmount15Year: "",
                    ropPremiumAmount20Year: "",
                    ropPremiumAmount30Year: "",
                },
                client: {
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    messageToMember: "",
                },
                agent: {
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                },
            },
        },
    };
}

export default function createAaalife() {
    var _aaalifeBase = aaalifeBaseInit();

    return $.extend({}, aaalife || {}, _aaalifeBase);
}
