import { Navigation, Pagination } from "swiper/modules";
import { register } from "swiper/swiper-element-bundle.mjs";

export function aaalifeSwiperInit() {
    register();
    window.addEventListener("load", init);
    /**
     * Initializes swiper instances by modifying individual configurations.
     * @return {void} - returns void
     */
    function init() {
        // This function is left empty on purpose as a future extension point.
    }
}
