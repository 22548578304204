import $ from "jquery";

export function initalizeReturnToAAALink($) {
    function showOrHideReturnToAAAButton() {
        const isClubUser = window.location.href.includes("lead=club") || document.cookie.includes("lead=club");
        const isGiwlConfirmationPage = window.location.href.includes("giwl-purchase-confirmation");
        const showReturnToAaaButton = isClubUser && !isGiwlConfirmationPage;
        document.querySelectorAll(".returnToAAAButton").forEach(function (element) {
            element.classList.toggle("is-hidden", !showReturnToAaaButton);
        });
    }

    $(document).ready(function () {
        return showOrHideReturnToAAAButton();
    });
}
