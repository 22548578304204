import $ from "jquery";

export function stickyHeaderInit() {
    $(document).on("ready", function () {
        const stickyHeaderElement = document.querySelector("header.header-sticky");
        if (!stickyHeaderElement) {
            return;
        }

        if (
            "IntersectionObserver" in window &&
            "IntersectionObserverEntry" in window &&
            "intersectionRatio" in window.IntersectionObserverEntry.prototype
        ) {
            const options = {
                root: null,
                threshold: [0.6],
            };

            const onIntersection = function (entries) {
                const logoSizeElement = entries[0].target.querySelector("a.header-logo-cta");
                if (entries[0].intersectionRatio <= 0.6) {
                    logoSizeElement.classList.add("small-logo");
                } else {
                    logoSizeElement.classList.remove("small-logo");
                }
            };

            window.observer = new IntersectionObserver(onIntersection, options);

            observer.observe(stickyHeaderElement);

            removeCtaFromGlobalNav();
        }
    });
}

/**
 * Removes the Get a Term Quote nav CTA on pages with an obvious product specific form.
 * @returns
 */
function removeCtaFromGlobalNav() {
    const getATermQuoteLink = document.querySelector('.main-nav-cta[href="/term-life-insurance-quote"]');
    if (!getATermQuoteLink) {
        return;
    }

    var pagesToExcludeNavCta = [
        "/term-life-insurance-quote-input",
        "/term-life-insurance-quote",
        "/whole-life-insurance/guaranteed-issue-whole-life",
        "/term-life-insurance/expressterm",
        "/term-life-insurance/traditional",
    ];

    if (pagesToExcludeNavCta.indexOf(window.location.pathname) >= 0) {
        getATermQuoteLink.parentElement.classList.add("hide");
    }
}
