import React from "react";
import DOMPurify from "dompurify";

export class RawHtmlComponent extends React.Component {
    constructor(props) {
        super(props);
        this.elementRef = React.createRef();
    }
    componentDidMount() {
        this.props.onDomRootAvailable(this.elementRef.current);
    }
    render() {
        const sanitizedHtml = { __html: DOMPurify.sanitize(this.props.html) };
        const Tag = this.props.as;
        const attributes = this.props.attributes;

        return <Tag ref={this.elementRef} {...attributes} dangerouslySetInnerHTML={sanitizedHtml} />;
    }
}
