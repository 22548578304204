import $ from "jquery";
import { browserStorage } from "./aaalife-utils-browser-storage";

export function findAgentFormInit() {
    var _findAgentValid = {
        zipFormat: false,
    };

    if ($("#contactBlock-findAgentForm").length > 0) {
        $(initFindAgent);
    }

    /**
     * init - initialize Find a Local Agent JS
     *
     * @return {void} void
     */
    function initFindAgent() {
        var user = browserStorage.getObject("user");

        if (!user) {
            user = aaalife.data.user || {};
        }
        if (user && Object.keys(user).length > 0) {
            $("#findAgent-zip").val(user.zip);
        }

        $("#contactBlock-findAgentForm").on("submit", _findAgentSubmit);
    }

    /**
     * _checkZip
     *
     * @returns {boolean}
     * @private
     */
    function _checkZip() {
        var isValid;
        var $input = $(this);
        var zip = $input.val();

        isValid = aaalife.utils.validate.zipFormat(zip);
        _findAgentValid.zipFormat = isValid;
        _highlightInvalidField(isValid, $input);

        return isValid;
    }

    function _findAgentSubmit(e) {
        var $zipField = $("#findAgent-zip");
        var zipValid = _checkZip.call($zipField);

        if (zipValid) {
            var user = browserStorage.getObject("user");
            if (!user) {
                user = aaalife.data.user || {};
            }

            user.zip = $("#findAgent-zip").val();

            aaalife.data.user = user;
            browserStorage.setObject("user", aaalife.data.user);

            // Trigger custom event so the geolocation can update if needed
            $(document).trigger("userZipChanged");
        } else {
            e.preventDefault();
        }
    }

    /**
     * _highlightInvalidField
     *
     * @param isValidT
     * @param $argElement
     * @private
     */
    function _highlightInvalidField(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            _showInlineError(false);
            $element.removeClass("invalid").addClass("valid");
        } else {
            _showInlineError(true);
            $element.removeClass("valid").addClass("invalid");
        }
    }

    /**
     * _showInlineError
     *
     * @param show
     * @private
     */
    function _showInlineError(show) {
        $("#contactBlock-findAgentForm").find(".inlineValidation").toggleClass("show", show);
    }
}
