import $ from "jquery";

export function initLoginForm() {
    $(init);

    /**
     * init - Initializes validation checking for the Login form
     *
     * @return {void} - returns void
     */
    function init() {
        $(".loginform-form").on("submit", validateForm);
        $(".loginform-form input[type=text], .loginform-form input[type=password]").on("blur", checkField);
    }

    /**
     * validateForm - Validate inputs when Login form is submitted
     *
     * @param {Object}
     *            e - jQuery event object
     * @return {void} void
     */
    function validateForm(e) {
        var $loginForm = $(".loginform-form");
        var isValid = true;
        var $username = $("#username");
        var $password = $("#password");
        var usernameIsEmpty = false;
        var passwordIsEmpty = false;
        var $errorMsg = $(".error-msg");

        if ($.trim($username.val()).length === 0) {
            usernameIsEmpty = true;
        }
        if ($.trim($password.val()).length === 0) {
            passwordIsEmpty = true;
        }

        if (usernameIsEmpty || passwordIsEmpty) {
            $errorMsg.text($errorMsg.attr("data-error-msg"));
            $errorMsg.addClass("show");
            isValid = false;

            if (passwordIsEmpty) {
                $password.addClass("error");
                $password.focus();
            }
            if (usernameIsEmpty) {
                $username.addClass("error");
                $username.focus();
            }
        }

        if (!isValid) {
            e.preventDefault();
        }
    }

    /**
     * checkField - OnBlur handler for form fields. Removes error class if field
     * is non-empty.
     *
     * @param {Object}
     *            e - jQuery event object
     * @return {void} void
     */
    function checkField(e) {
        var $theField = $(this);

        if ($theField.val().length > 0) {
            $theField.removeClass("error");
        }
    }
}
