const regex = /[?&]([^=#]+)=([^&#]*)/g;

export function retrieveUrlParameters(location = window.location) {
    const url = location.search;
    let params = {};
    var match;
    while ((match = regex.exec(url))) {
        params[match[1]] = match[2];
    }
    return params;
}
