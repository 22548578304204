function getRestUrl() {
    if (typeof restUrl == "undefined") {
        return "restservices/aggregate";
    }

    return restUrl;
}

export function sendData(data, async) {
    if (async) {
        throw new Error(
            "The sendData function was called with async parameter as true which is now obsolete. Please call sendDataAsync instead for async behavior.",
        );
    }

    var request = new XMLHttpRequest();
    // HACK: Some browsers have deprecated async false option, all calls to this should be removed in the future.
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/open#Syntax
    request.open("POST", getRestUrl(), false);
    request.setRequestHeader("Content-type", "application/json");
    request.send(JSON.stringify(data));
    return {
        status: request.status,
        response: request.status === 200 ? JSON.parse(request.response) : request.response,
    };
}

export function sendDataAsync(data) {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();

        request.onreadystatechange = function () {
            if (request.readyState !== 4) return;

            if (request.status >= 200 && request.status < 300) {
                resolve(request);
            } else {
                reject({
                    status: request.status,
                    statusText: request.statusText,
                });
            }
        };

        request.open("POST", getRestUrl(), true);
        request.setRequestHeader("Content-type", "application/json");
        request.send(JSON.stringify(data));
    });
}

export function sendReceiveData(data, urlString, async) {
    return new Promise(function (resolve, reject) {
        $.ajax({
            url: urlString,
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            type: "POST",
            async: async,
            timeout: 30000,
        })
            .done(function (response) {
                //this means my api call suceeded, so I will call resolve on the response
                return resolve(response);
            })
            .fail(function (error) {
                //this means my api call suceeded, so I will call resolve on the response
                return reject(error);
            });
    });
}

export function sendDataWithHeader(headers, data, async) {
    var deferred = $.Deferred();
    $.ajax({
        url: getRestUrl(),
        headers: headers,
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        type: "POST",
        async: async,
    });

    return deferred.promise();
}

export function sendDataAndEvent(evt, data) {
    return sendData(data, false).always(function AfterSendData() {
        sendEvent(evt);
    });
}

export function sendEvent(evt) {
    return $.ajax({
        url: aaalife.ctx + "gdata",
        contentType: "application/json",
        dataType: "json",
        type: "GET",
    }).always(function AfterGetData(data) {
        if ("user" in data == false) return;
        if (data.user == {}) return;

        dataLayer.push({
            event: evt,
            user: data.user,
        });

        // if (typeof console == 'object' && 'log' in console) // IE protection
        //     console.log('Pushed event', evt);
    });
}

function performAjax(url, data, async) {
    return $.ajax({
        url: url,
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data),
        type: "POST",
        async: async,
    });
}

export function transformData(key, data) {
    var newData = {};
    newData[key] = data;
    return newData;
}

export function appendData(obj, key, data) {
    obj[key] = data;
    return obj;
}

export function getData(obj, key) {
    return obj[key];
}

export function collectFormData(form, attr) {
    var hasPanels = form.data("panels");
    var formData;
    if (hasPanels) {
        formData = $(".active :input")
            .serializeArray()
            .reduce(function (obj, item) {
                obj[item.name] = item.value;
                return obj;
            }, {});
    } else {
        formData = form.serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});
    }

    var fullData = transformData("current", formData);

    if (attr != null) {
        fullData = appendData(fullData, "attr", attr);
    }

    return fullData;
}

export function collectGIWLFormData(data) {
    return {
        zipCode: data.zip,
        gender: data.gender,
        month: data.month,
        day: data.day,
        year: data.year,
        isAAAMember: data.isMember,
    };
}

export function allToString(object) {
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            var value = object[key];
            if ($.type(value) === "number") {
                object[key] = value.toString();
            }
        }
    }

    return object;
}

function constructGTM(userData, components) {
    var gtmObject = transformData("event", "populateDL");
    gtmObject = appendData(gtmObject, "user", userData);
    return appendData(gtmObject, "pageComponents", components);
}

function toEtAjaxCall() {
    var data = transformData("action", "toET");
    performAjax("blank", data, true);
}
