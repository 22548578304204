import $ from "jquery";

export function aaalifeSearchInit() {
    if ($(".search-sfBlock").length > 0) {
        $(_init);
    }

    /**
     * _init - initialize the Search page
     *
     * @private
     */
    function _init() {
        aaalife.utils.arrowAccordion.init();
    }
}
