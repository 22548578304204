import $ from "jquery";
console.log($);
export const COND_MAP_TRADITIONAL = {
    excellent: "Excellent",
    verygood: "Very Good",
    good: "Good",
    fair: "Fair",
};

export const COND_MAP_EXPRESS = {
    excellent: "Excellent",
    good: "Good",
    fair: "Fair",
};

/**
 * getValidationData - execute webservice call via ajax to get data, assigns
 * data to namespace and returns promise
 *
 * @returns {Promise}
 */
export function getCombinedValidationData(noBmi) {
    if (noBmi) {
        return getRestrictionsValidationData();
    }

    let quoteRestrictions = _getQuoteRestrictionsData();
    let healthConditions = fetch(aaalife.ctx + "restservices/data/bmi").then((response) => response.json());

    // Assigns validation data for quote restrictions and health conditions to namespaced data object
    return Promise.all([quoteRestrictions, healthConditions]).then(([qrData, hcData]) => {
        aaalife.data.validation.restrictions = qrData;
        aaalife.data.validation.healthConditions = hcData;

        return aaalife.data.validation;
    });
}

export function getRestrictionsValidationData() {
    return _getQuoteRestrictionsData().then((qrData) => {
        // HACK: Caching, should be done outside or used before doing lookup. Also caching the promise makes
        // consumption easier if we go that route.
        aaalife.data.validation.restrictions = qrData;
        return aaalife.data.validation;
    });
}

/**
 * getQuoteResultsData - get's quote results from webservice
 *
 * @returns {Promise}
 */
export function getQuoteResultsData(resultsString) {
    return Promise.resolve(resultsString);
}

// Private Functions
// -----------------------------------------------------------------------
/**
 * _getQuoteRestrictionsData - gets JSON object with the Term Quote
 * restrictions
 *
 * @returns {Promise}
 * @private
 */
function _getQuoteRestrictionsData() {
    if (aaalife.fromServer.urlStrings) {
        return fetch(aaalife.ctx + aaalife.fromServer.urlStrings.termQuoteRestrictionsAPIUrl)
            .then((response) => response.json())
            .then((restrictions) => ({
                dropdownAges: {
                    minAge: restrictions.dropdownMin,
                    maxAge: restrictions.dropdownMax,
                },
                ageRestrictions: {
                    minAge: restrictions.ageRestrictionMin,
                    maxAge: restrictions.ageRestrictionMax,
                },
                expressTermNotOffered: [...restrictions.expressTermNotOffered],
                allTermNotOffered: [...restrictions.allTermNotOffered],
                replacementPolicyNotOffered: [...restrictions.replacementPolicyNotOffered],
                annuitiesNotOffered: [...restrictions.annuitiesNotOffered],
                travelAccidentNotOffered: [...restrictions.travelAccidentNotOffered],
            }));
    }

    return Promise.reject(); // TODO: Confirm handling, this used to return empty string.
}
