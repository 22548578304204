import { setCookie, getCookie } from "../agentQuoter/clubUtils";

export function checkAndSetEmailSource(email) {
    if (window.aaalife.data.user.email != email) {
        setCookie("email_source", window.aaalife.data.user.source, null, true);
    }
}

export function checkAndSetPhoneSource(phone) {
    if (window.aaalife.data.user.phone != phone) {
        setCookie("phone_source", window.aaalife.data.user.source, null, true);
    }
}
