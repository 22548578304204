/**
 * @template T
 * @param {T} elseValue
 * @param {boolean} value
 * @returns {"Yes"|"No"|T}
 */
export function booleanToYesNoOrElse(elseValue, value) {
    return value ? "Yes" : !value ? "No" : elseValue;
}

/**
 * @param {string} stringValue
 * @returns {boolean|undefined}
 */
export function yesNoToBooleanOrUndefined(stringValue) {
    return /^yes$/i.test(stringValue) ? true : /^no/i.test(stringValue) ? false : undefined;
}

/**
 * @param {string} stringValue
 * @returns {number|undefined}
 */
export function stringToIntegerOrUndefined(stringValue) {
    return stringValue ? parseInt(stringValue) : undefined;
}
