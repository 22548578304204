import $ from "jquery";
import "jquery-mask-plugin";
import { sendEvent } from "../collector";
import { format10DigitNanpaPhone, parseUnformatted10DigitPhone } from "../utils/phone";
import { browserStorage } from "./aaalife-utils-browser-storage";

export function customerSupportFormInit() {
    var _contactFormDataValid = {
        firstName: false,
        lastName: false,
        emailFormat: false,
        phoneFormat: false,
        comments: false,
        captcha: false,
    };

    if ($("#customerSupportForm").length > 0) {
        init();
    }

    if ($("#customerSupportConfirmation").length > 0) {
        var topOfPanel;
        topOfPanel = $("#customerSupportConfirmation").offset().top;
        window.scrollTo(0, topOfPanel);

        sendEvent("CSC");
    }

    /**
     * init - initialize Customer Support Request JS
     *
     * @return {void} void
     */
    function init() {
        _initCustomerSupportForm();
        _initEventListeners();
    }

    /**
     * initCustomerSupportForm - pre-populate form fields with any user info
     * that has been saved
     *
     * @return {void} void
     */
    function _initCustomerSupportForm() {
        var user = browserStorage.getObject("user");
        if (!user) {
            user = aaalife.data.user || {};
        }

        // pre-populate form fields
        if (user && Object.keys(user).length > 0) {
            $("#firstName").val(user.firstName);
            $("#lastName").val(user.lastName);
            $("#email").val(user.email);
            $("#phone").val(user.phone);
        }
    }

    /**
     * _initEventListeners
     *
     * @return {void} void
     */
    function _initEventListeners() {
        if ($("#customerSupportForm").length > 0) {
            // Navigation
            $("#customerSupportForm").on("submit", _validateCustomerSupportForm);

            // Validation

            $('#customerSupportForm [name="firstName"]').mask("YYYYYYYYYYYYYYYYYYYY", {
                translation: {
                    Y: {
                        pattern: /[A-Za-z'-]/,
                    },
                },
            });
            $('#customerSupportForm [name="lastName"]').mask("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYY", {
                translation: {
                    Y: {
                        pattern: /[ A-Za-z'-]/,
                    },
                },
            });
            $('#customerSupportForm [name="phone"]').mask("YZZ(A00) A00-0000", {
                translation: {
                    A: {
                        pattern: /[2-9]/,
                    },
                    Z: {
                        pattern: /[0-1 ]/,
                        optional: true,
                    },
                    Y: {
                        pattern: /[+ ]/,
                        optional: true,
                    },
                },
            });
            $('#customerSupportForm [name="phone"]').on("change", (e) => {
                const unformatedPhone = parseUnformatted10DigitPhone(e.target.value);
                if (aaalife.utils.validate.phoneFormat(unformatedPhone)) {
                    e.target.value = format10DigitNanpaPhone(unformatedPhone);
                }
            });

            $(".customerSupport-captchaRefresh").on("click", refreshCaptcha);
        }
    }

    function _validateCustomerSupportForm(e) {
        var isValid;
        var $firstNameField = $('#customerSupportForm [name="firstName"]');
        var $lastNameField = $('#customerSupportForm [name="lastName"]');
        var $phoneField = $("#customerSupportForm #phone");
        var $emailField = $("#customerSupportForm #email");
        var $commentsField = $("#customerSupportForm #comments");
        var $captchaInputField = $("#customerSupportForm #captchaInput");

        _contactFormDataValid.firstName = _hasValue.call($firstNameField);
        _contactFormDataValid.lastName = _hasValue.call($lastNameField);
        _contactFormDataValid.phoneFormat = _validatePhoneNumber.call($phoneField);
        _contactFormDataValid.emailFormat = _checkEmail.call($emailField);
        _contactFormDataValid.comments = _hasValue.call($commentsField);
        if (aaalife.showCaptcha) {
            _contactFormDataValid.captcha = _validateCaptcha.call($captchaInputField);
        } else _contactFormDataValid.captcha = true;

        isValid = Object.keys(_contactFormDataValid).every(function (key) {
            return _contactFormDataValid[key];
        });

        if (isValid) {
            var user = browserStorage.getObject("user");
            if (!user) {
                user = aaalife.data.user || {};
            }

            user.firstName = $("#firstName").val();
            user.lastName = $("#lastName").val();
            user.phone = $("#phone").val();
            user.email = $("#email").val();

            aaalife.data.user = user;
            browserStorage.setObject("user", aaalife.data.user);
        } else {
            // form is invalid, display errors and allow user to fix
            e.preventDefault();
            $("#customerSupportForm").find(".inlineValidation").toggleClass("show", true);
        }
    }

    /**
     * _checkEmail
     *
     * @returns {boolean|*}
     * @private
     */
    function _checkEmail() {
        var isValid;
        var $input = $(this);
        var email = $input.val();
        if (email === "" || email === null) {
            _highlightInvalidField(false, $input);
            return false;
        }
        isValid = aaalife.utils.validate.emailFormat(email, false);
        _contactFormDataValid.emailFormat = isValid;
        _highlightInvalidField(isValid, $input);

        return isValid;
    }

    /**
     * _validatePhoneNumber
     *
     * @returns {*}
     * @private
     */
    function _validatePhoneNumber() {
        const $input = $(this);
        const phone = parseUnformatted10DigitPhone($input.val());

        const isValid = aaalife.utils.validate.phoneFormat(phone);
        _contactFormDataValid.phoneFormat = isValid;
        // if (isValid) {
        //     $input.val(format10DigitNanpaPhone(phone));
        // }
        _highlightInvalidField(isValid, $input);

        return isValid;
    }

    /**
     * _hasValue
     *
     * @returns {boolean}
     * @private
     */
    function _hasValue() {
        var $el = $(this);
        var isValid = !aaalife.utils.validate.missingValue($el);

        _highlightInvalidField(isValid, $el);

        return isValid;
    }

    /**
     * _highlightInvalidField
     *
     * @param isValid
     * @param $argElement
     * @private
     */
    function _highlightInvalidField(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            _showInlineError(false);
            $element.removeClass("invalid").addClass("valid");
        } else {
            _showInlineError(true);
            $element.removeClass("valid").addClass("invalid");
        }
    }

    /**
     * _showInlineError
     *
     * @param show
     * @private
     */
    function _showInlineError(show) {
        $("customerSupportForm").find(".inlineValidation").toggleClass("show", show);
    }

    function refreshCaptcha() {
        var reload = document.getElementById("fetchCaptcha");
        reload.src = reload.src;
    }

    function _validateCaptcha() {
        var captcha_response = grecaptcha.getResponse();
        var $input = $(this);
        if (captcha_response.length == 0) {
            // Captcha is not Passed
            _highlightInvalidField(false, $input);
            return false;
        } else {
            // Captcha is Passed
            _highlightInvalidField(true, $input);
            return true;
        }
    }
}
