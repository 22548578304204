import $ from "jquery";
import "js-cookie";

export function aaalifeAlertBarInit() {
    $(document).ready(function () {
        var idValue = $(".alert-bar").attr("id");
        if (Cookies.get("alert") === undefined || Cookies.get("alert") != idValue) {
            $(".alert-bar").show();
        }

        $(".alert-bar-close").click(function () {
            Cookies.set("alert", idValue, {
                expires: 99999,
            });
            $(".alert-bar").hide();
        });
    });
}
