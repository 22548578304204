import $ from "jquery";

// Override AJAX call, to check for redirects to session-expired
export default function AjaxInterceptInit() {
    var _$ajax = $.ajax;
    $.ajax = function AjaxIntercept(config) {
        //console.log('Intercepting AJAX call', config.url);
        return _$ajax.apply(this, arguments).always(function CheckForExpiration(res) {
            // If res is not an object, we got back an HTML response
            if (typeof res != "object") return true;

            // If status is not 401
            if (res.status !== 401) return true;

            var authHeader = res.getResponseHeader("WWW-Authenticate");

            // If no AuthHeader, this is an unexpected 401 unrelated to sessions
            if (authHeader == null) return true;

            // If the auth header doesn't start with Expire, this is unexpected
            if (authHeader.substring(0, 6) != "Expire") return true;

            // authHeader should be of the form "Expire param=value,param2=value2"; extract redirect param
            var pathRegex = /redirect\=(.*?)(,|$)/;
            var result = pathRegex.exec(authHeader);

            // Header was not in the expected format
            if (result.length <= 1) return true;

            // Our match doesn't look right
            if (result[1].length == 0) return true;

            // Redirect user to the path specified
            window.location.href = result[1];

            // Don't run any AJAX handlers
            return false;
        });
    };
}
