import { ageLastBirthday, createDate } from "../utils/age";

function toBmi(inches, pounds) {
    return (pounds / inches ** 2) * 703;
}

export function validateTermQuoteRequestFields(user) {
    const isMemberType = typeof user.isMember;
    if (isMemberType !== "string") {
        throw new Error(`The value for 'isMember' must be a string but was ${isMemberType}`);
    }

    if (!user.isMember.match(/(Yes|No)/)) {
        throw new Error(
            `The value for 'isMember' must be either \"Yes\" or \"No\" but was ${user.isMember} of type ${typeof user.isMember}.`,
        );
    }

    if (typeof user.weight !== "undefined") {
        if (!(typeof user.weight === "string" && user.weight.match(/\d{1,3}/))) {
            throw new Error(
                `The value for 'weight' must be a string of one to three digits but was '${user.weight}' of type ${typeof user.weight}.`,
            );
        }
    }

    if ([user.heightFeet, user.heightInches].some((value) => typeof value !== "undefined")) {
        if (!(typeof user.heightInches === "string" && user.heightInches.match(/\d{1,2}/))) {
            throw new Error(
                `The value for 'heightInches' must be a string of one or two digits but was '${user.heightInches}' of type ${typeof user.heightInches}.`,
            );
        }

        if (!(typeof user.heightFeet === "string" && user.heightFeet.match(/\d/))) {
            throw new Error(
                `The value for 'heightFeet' must be a string of one digit '${user.heightFeet}' of type ${typeof user.heightFeet}.`,
            );
        }
    }

    if ([user.dobYear, user.dobMonth, user.dobDay].some((value) => typeof value !== "undefined")) {
        if (!(typeof user.dobYear === "string" && user.dobYear.match(/(19|20)\d{2}/))) {
            throw new Error(
                `The value for 'dobYear' must be a string of four digits beginning with either \"20\" or \"19\" but was '${user.dobYear}' of type ${typeof user.dobYear}.`,
            );
        }

        if (!(typeof user.dobMonth === "string" && user.dobMonth.match(/\d{1,2}/))) {
            throw new Error(
                `The value for 'dobMonth' must be a string of one or two digits but was '${user.dobMonth}' of type ${typeof user.dobMonth}.`,
            );
        }

        if (!(typeof user.dobDay === "string" && user.dobDay.match(/\d{1,2}/))) {
            throw new Error(
                `The value for 'dobDay' must be a string of one or two digits but was '${user.dobDay}' of type ${typeof user.dobDay}.`,
            );
        }
    }
}

/**
 * Determines whether the user qualifies as a DSU lead or whether to monetize.
 * @param {User} user - The input values entered by and about user as part of a quote request.
 * @param {Date} now - The current date and time.
 * @param {ExternalOverrides} overrides - Any external overrides for trafficMonetizationIsQualityBmi or trafficMonetizationIsQualityAge
 * */
export function isUserAQualityLead(user, now, overrides = {}) {
    if (user.isMember === "Yes") {
        return true;
    }

    const isQualityBmi = overrides.trafficMonetizationIsQualityBmi || ((bmi) => bmi >= 19 && bmi <= 29);

    if ([user.heightFeet, user.heightInches, user.weight].every((value) => typeof value !== "undefined")) {
        const heightInInches = parseInt(user.heightFeet) * 12 + parseInt(user.heightInches);
        const weightInPounds = parseInt(user.weight);
        const bmi = toBmi(heightInInches, weightInPounds);

        if (!isQualityBmi(bmi)) {
            return false;
        }
    }

    const isQualityAge = overrides.trafficMonetizationIsQualityAge || ((age) => age < 60);

    if ([user.dobDay, user.dobMonth, user.dobYear].every((value) => typeof value !== "undefined")) {
        const day = parseInt(user.dobDay);
        const month = parseInt(user.dobMonth);
        const year = parseInt(user.dobYear);
        const dob = createDate(year, month, day);
        const age = ageLastBirthday(dob, now);

        if (!isQualityAge(age)) {
            return false;
        }
    }

    return true;
}
