import $ from "jquery";
import { Foundation, MediaQuery } from "foundation-sites";

export function compareProductsFormInit() {
    if ($("#compareProductsForm").length > 0) {
        $(_init);
    }

    /**
     * _init - initialize the Compare Products page
     *
     * @private
     */
    function _init() {
        _initCompareForm();

        if (["small", "medium"].indexOf(Foundation.MediaQuery.current) > -1) {
            // initialize the boxAccordion
            aaalife.utils.boxAccordion.init();
        } else {
            // Only init results accordion on large+
            _initResultsAccordion();
        }

        // watch for window resize
        $(window).on("changed.zf.mediaquery", _handleMQChange);
    }

    /**
     * _handleMQChange
     *
     * @param event
     * @param newSize
     * @param oldSize
     * @private
     */
    function _handleMQChange(event, newSize, oldSize) {
        // resizing from mobile to desktop
        if (["small", "medium"].indexOf(oldSize) > -1 && ["large", "xlarge", "xxlarge"].indexOf(newSize) > -1) {
            $(".boxAccordion-group-body").removeClass("hide");
            _initResultsAccordion();
            _desktopRowShading();
        }

        // resizing from desktop to mobile
        if (["large", "xlarge", "xxlarge"].indexOf(oldSize) > -1 && ["small", "medium"].indexOf(newSize) > -1) {
            _mobileRowShading();
        }
    }

    /**
     * _initCompareForm - initialize the Compare Products checkboxes
     *
     * @private
     */
    function _initCompareForm() {
        // Initialize checked / unchecked boxes based on passed-in parameters
        var channel = getParameterByName("channel");
        var $checkboxGroups = $(".boxAccordion-group");
        var $selectedGroup;
        var topOfResults;

        if (channel !== null && channel !== "") {
            // check all the checkboxes for products in the channel
            $selectedGroup = $checkboxGroups.filter("." + channel);
            $selectedGroup.find("input[type=checkbox]").attr("checked", "checked");

            // open the mobile accordion section for products in that channel
            $checkboxGroups.removeClass("open");
            $selectedGroup.addClass("open");

            // show the selected products
            _displayComparison($selectedGroup.find("input[type=checkbox]:checked"));
            topOfResults = $(".compareProducts-comparison").offset().top;
            window.scrollTo(0, topOfResults);
        }

        $(".compareProducts-compareButton").on("click", _validateCompare);
    }

    /**
     * _initResultsAccordion - initialize the desktop-only accordion wrapping
     * the Compare Products results
     *
     * @private
     */
    function _initResultsAccordion() {
        var $categoryHeaders = $(".compareProducts-accordion-categoryHeader");
        var $categoryHeaderLinks = $(".compareProducts-accordion-categoryHeader a");

        $categoryHeaders.each(_initAccordionRows);
        $categoryHeaderLinks.off("click");
        $categoryHeaderLinks.on("click", _accordionHeaderClicked);

        /**
         * _initAccordionRows - set the initial state of the rows within an
         * accordion category (shown or hidden)
         *
         * @private
         */
        function _initAccordionRows() {
            var theCategory = $(this).find("a").attr("class");
            var $categoryRows = $(".row." + theCategory);

            if ($(this).hasClass("open")) {
                $categoryRows.removeClass("hide");
            } else {
                $categoryRows.addClass("hide");
            }
        }

        /**
         * _accordionHeaderClicked - show or hide the accordion section when the
         * header is clicked
         *
         * @param {event}
         *            e
         * @private
         */
        function _accordionHeaderClicked(e) {
            var $categoryHeader = $(this).parent();
            var theCategory = $(this).attr("class");
            var $categoryRows = $(".row." + theCategory);

            e.preventDefault();

            if ($categoryHeader.hasClass("open")) {
                $categoryHeader.removeClass("open");
                $categoryRows.addClass("hide");
            } else {
                $categoryHeader.addClass("open");
                $categoryRows.removeClass("hide");
                _markFirstLastColumns();
            }
        }
    }

    /**
     * _validateCompare - click handler for "Compare" button
     *
     * @param e
     * @private
     */
    function _validateCompare(e) {
        var $checkedBoxes = $("#compareProductsForm").find("input[type=checkbox]:checked");
        var isValid = false;

        e.preventDefault();

        if ($checkedBoxes.length == 2 || $checkedBoxes.length == 3) {
            isValid = true;
        }

        if (isValid) {
            // show the columns corresponding to the checked boxes
            _displayComparison($checkedBoxes);
            _showInlineError(false);
        } else {
            // form is invalid, display errors and allow user to fix
            _showInlineError(true);
        }
    }

    /**
     * _displayComparison
     *
     * @param $checkedBoxes
     * @private
     */
    function _displayComparison($checkedBoxes) {
        var $container = $(".compareProducts-comparison");

        $container.removeClass(
            "ExpressTerm TraditionalTerm ReturnOfPremium SimpleWholeLife GuaranteedIssue LifetimeUniversalLife AccumulatorUniversalLife",
        );
        $container.removeClass("hide");

        $checkedBoxes.each(function () {
            var cbName = $(this).attr("name");
            var cbNameClass = "." + cbName;

            if ($checkedBoxes.length == 2) {
                $container.find(cbNameClass).removeClass("large-4").addClass("large-6");
            } else {
                $container.find(cbNameClass).removeClass("large-6").addClass("large-4");
            }

            $container.addClass(cbName);
        });

        if (["small", "medium"].indexOf(Foundation.MediaQuery.current) > -1) {
            _mobileRowShading();
            setTimeout(_swipersInitAndEvents, 50);
        } else {
            _desktopRowShading();
        }

        _markFirstLastColumns();
    }

    function _swipersInitAndEvents() {
        var swiperDetails = $(".compareProducts .swiper-container.swiper-details")[0].swiper;
        var swiperHeadings = $(".compareProducts .swiper-container.swiper-headings")[0].swiper;
        var $swiperHeadingLinks = $(".compareProducts .swiper-container.swiper-headings a");

        swiperHeadings.update();
        swiperHeadings.slideTo(0);
        swiperDetails.update();

        swiperHeadings.on("onSlideChangeStart", _handleHeadingSlideChangeStart);
        swiperDetails.on("onSlideChangeStart", _handleDetailSlideChangeStart);
        $swiperHeadingLinks.on("click", function (e) {
            e.preventDefault();
        });

        // use swiperDetails, find which instance of the header is active, find
        // first details slide with that class, go to that index
        function _handleHeadingSlideChangeStart(swiper) {
            var activeSlide = swiper.slides[swiper.activeIndex];
            var activeHeading = $(activeSlide).data("sw-heading");
            var currentDetailHeading = $(swiperDetails.slides[swiperDetails.activeIndex]).data("sw-detail");

            if (activeHeading !== currentDetailHeading) {
                swiperDetails.slideTo(
                    $(swiperDetails.slides)
                        .filter('[data-sw-detail="' + activeHeading + '"]')
                        .eq(0)
                        .index() -
                        $(swiperDetails.slides)
                            .filter('[data-sw-detail="' + activeHeading + '"]')
                            .eq(0)
                            .prevAll(".hide-from-swiper").length,
                );
            }
            //console.log('heading slides changing',
            //		swiper.slides[swiper.activeIndex]);
        }

        // use swiperHeadings, find which heading of the details slides is
        // active, find first heading slide with that class, go to that index if
        // different than detail
        function _handleDetailSlideChangeStart(swiper) {
            var activeSlide = swiper.slides[swiper.activeIndex];
            var detailHeading = $(activeSlide).data("sw-detail");
            var currentHeading = $(swiperHeadings.slides[swiperHeadings.activeIndex]).data("sw-heading");

            if (detailHeading !== currentHeading) {
                swiperHeadings.slideTo(
                    $(swiperHeadings.slides)
                        .filter('[data-sw-heading="' + detailHeading + '"]')
                        .eq(0)
                        .index(),
                );
            }

            //console.log('detail slides changing',
            //		swiper.slides[swiper.activeIndex]);
        }
    }

    /**
     * _mobileRowShading
     *
     * @private
     */
    function _mobileRowShading() {
        var $container = $(".compareProducts-comparison");
        var classList = $container.attr("class").split(/\s+/);
        var $oddRows = $container.find("." + classList[2] + ", ." + classList[4]);

        $(".compareProducts-accordion-content-item").removeClass("odd");
        $(".compareProducts-accordion-productHeader").removeClass("odd");
        $oddRows.filter(".compareProducts-accordion-productHeader").addClass("odd");
        $oddRows.find(".compareProducts-accordion-content-item").addClass("odd");
    }

    /**
     * _desktopRowShading
     *
     * @private
     */
    function _desktopRowShading() {
        var $categoryHeaders = $(".compareProducts-accordion-categoryHeader");

        $categoryHeaders.each(function () {
            var theCategory = $(this).find("a").attr("class");
            var $oddRows = $(".swiper-slide." + theCategory + ":even");
            var $evenRows = $(".swiper-slide." + theCategory + ":odd");
            var $headers = $(".compareProducts-accordion-productHeader");

            $headers.removeClass("odd");
            $evenRows.find(".compareProducts-accordion-content-item").removeClass("odd");
            $oddRows.find(".compareProducts-accordion-content-item").addClass("odd");
        });
    }

    /**
     * _markFirstLastColumns
     *
     * @private
     */
    function _markFirstLastColumns() {
        var numberOfColumns = $(".compareProducts-productCard:visible").length;

        $(".compareProducts-productCard, .compareProducts-accordion-content-inner").removeClass("first last");

        $(".compareProducts-productCard:visible").eq(0).addClass("first");
        $(".compareProducts-productCard:visible")
            .eq(numberOfColumns - 1)
            .addClass("last");

        $(".compareProducts-accordion-content").each(function () {
            $(this).find(".compareProducts-accordion-content-inner:visible").eq(0).addClass("first");
            $(this)
                .find(".compareProducts-accordion-content-inner:visible")
                .eq(numberOfColumns - 1)
                .addClass("last");
        });
    }

    /**
     * _showInlineError
     *
     * @param show
     * @private
     */
    function _showInlineError(show) {
        $(".inlineValidation").toggleClass("show", show);
    }

    /**
     * getParameterByName from
     * http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
     *
     * @param {string}
     *            name
     * @param {string}
     *            url (optional)
     * @returns {string}
     */
    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");

        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        var results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return "";

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
}
