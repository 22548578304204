import $ from "jquery";
import { isEmailAddressFormatValid } from "../utils/email-format-validation";
import { isPhoneFormatValid } from "../utils/phone-format-validation";
import { ageLastBirthday, createDate } from "../utils/age";

export default {
    zip: zip,
    age: age,
    weight: weight,
    gender: validateGender,
    bestTimeToCall: validateBestTimeToCall,
    state: validateState,
    heightFeet: validateFeet,
    heightInches: validateInches,
    termLength: validateTermLength,
    coverageAmt: validateCoverageAmt,
    zipFormat: zipFormat,
    validateIsMember: validateIsMember,
    emailFormat: emailFormat,
    phoneFormat: phoneFormat,
    validateExistingPolicy: validateExistingPolicy,
    missingValue: missingRequired,
    ageNicotine: ageNicotine,
    getBMI: getBMI,
    getHealthConditions: getHealthConditions,
    validateRadio: validateRadio,
    _zipCodeCDSValidation: _zipCodeCDSValidation,
};

/**
 * zip - takes a valid zip code, gets a state from Google GeoCoder,
 * validates against AAALife state exclusions
 *
 * @param {string}
 *            zip string from text input
 * @returns {boolean}
 */
function zip(zip) {
    var deferred = $.Deferred();
    var zipConditions = {
        allTermNotOffered: false,
        expressTermNotOffered: false,
        replacementPolicyNotOffered: false,
        annuitiesNotOffered: false,
        travelAccidentNotOffered: false,
    };

    if (_zipCodeCDSValidation(zip)) {
        Object.keys(zipConditions).forEach(_testRestrictions);
        aaalife.utils.prevalidate.storeZipObj(zip, aaalife.utils.prevalidate.geoZipName);
        zipConditions.zip = zip;
        deferred.resolve(zipConditions);
    } else {
        deferred.reject(zipConditions);
    }

    return deferred;

    /**
     * _testRestrictions
     *
     * @param restriction
     * @private
     */
    function _testRestrictions(restriction) {
        aaalife.data.validation.restrictions[restriction].some(_anyRestrictionMatchs);

        function _anyRestrictionMatchs(restrictedState) {
            if (aaalife.data.user.state === restrictedState) {
                zipConditions[restriction] = true;
            }
            return aaalife.data.user.state === restrictedState;
        }
    }
}

function _zipCodeCDSValidation(zip) {
    if (aaalife.data.user.zip) {
        if (zip === aaalife.data.user.zip) {
            return true;
        }
    }
    var isValidZip = false;
    $.ajax({
        url: "/services/cds-services/stateAbbreviation",
        contentType: "application/json",
        dataType: "json",
        type: "GET",
        data: {
            zipCode: zip,
        },
        async: false,
        success: function (data) {
            if (_checkState(data)) {
                isValidZip = true;
                aaalife.data.user.state = data;
            }
        },
        error: function (data) {
            isValidZip = false;
        },
    });
    return isValidZip;
}

function _checkState(state) {
    var stateArray = [
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DC",
        "DE",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MP",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VI",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
    ];
    return stateArray.includes(state);
}

/**
 * _searchAddrComponents - Search Address Components for state type
 *
 * @param addrComp
 * @private
 */
function _searchAddrComponents(addrComp) {
    return addrComp.types.find(_searchTypes);
}

/**
 * _searchTypes - Search Address Components for administrative_area_level_1
 * (state) type
 *
 * @param type
 * @private
 */
function _searchTypes(type) {
    return type === "administrative_area_level_1";
}

/**
 * Checks the element for a missing value
 *
 * @param {element}
 *            $element - the element to test
 * @returns {boolean} true if the element is missing
 */
function missingRequired($element) {
    return (
        typeof $element === "undefined" ||
        $element.val() === "" ||
        $element.val() === null ||
        typeof $element.val() === "undefined"
    );
}

/**
 * validateRadio - validate radio buttons
 *
 * @private
 */
function validateRadio() {
    return $("input#panel2radio1")[0].checked || $("input#panel2radio2")[0].checked;
}

/**
 * zipFormat - takes a zip code, gets a state from Google GeoCoder,
 * validates against AAALife state exclusions
 *
 * @param {string}
 *            zip string from text input
 * @returns {boolean}
 */
function zipFormat(zip) {
    var ZIP_REGEXP = /^[0-9]{5}([\s-]{1}[0-9]{4})?$/;

    return ZIP_REGEXP.test(zip);
}

/**
 * validateGender - validate user gender selection
 *
 * @param $genderBox
 * @returns {boolean}
 */
function validateGender($genderBox) {
    return $genderBox.val() === "Male" || $genderBox.val() === "Female" || $genderBox.val() === "M" || $genderBox.val() === "F";
}

function validateBestTimeToCall($bestTimeToCallBox) {
    return (
        $bestTimeToCallBox.val() === "Morning" ||
        $bestTimeToCallBox.val() === "Afternoon" ||
        $bestTimeToCallBox.val() === "Evening"
    );
}

/** *************************TODO****************************** */
function validateState($stateBox) {
    return (
        $stateBox.val() === "AL" ||
        $stateBox.val() === "AK" ||
        $stateBox.val() === "AZ" ||
        $stateBox.val() === "AR" ||
        $stateBox.val() === "CA" ||
        $stateBox.val() === "CO" ||
        $stateBox.val() === "CT" ||
        $stateBox.val() === "DE" ||
        $stateBox.val() === "DC" ||
        $stateBox.val() === "FL" ||
        $stateBox.val() === "GA" ||
        $stateBox.val() === "HI" ||
        $stateBox.val() === "ID" ||
        $stateBox.val() === "IL" ||
        $stateBox.val() === "IN" ||
        $stateBox.val() === "IA" ||
        $stateBox.val() === "KS" ||
        $stateBox.val() === "KY" ||
        $stateBox.val() === "LA" ||
        $stateBox.val() === "ME" ||
        $stateBox.val() === "MD" ||
        $stateBox.val() === "MA" ||
        $stateBox.val() === "MI" ||
        $stateBox.val() === "MN" ||
        $stateBox.val() === "MS" ||
        $stateBox.val() === "MO" ||
        $stateBox.val() === "MT" ||
        $stateBox.val() === "NE" ||
        $stateBox.val() === "NV" ||
        $stateBox.val() === "NH" ||
        $stateBox.val() === "NJ" ||
        $stateBox.val() === "NM" ||
        $stateBox.val() === "NY" ||
        $stateBox.val() === "NC" ||
        $stateBox.val() === "ND" ||
        $stateBox.val() === "OH" ||
        $stateBox.val() === "OK" ||
        $stateBox.val() === "OR" ||
        $stateBox.val() === "PA" ||
        $stateBox.val() === "RI" ||
        $stateBox.val() === "SC" ||
        $stateBox.val() === "SD" ||
        $stateBox.val() === "TN" ||
        $stateBox.val() === "TX" ||
        $stateBox.val() === "UT" ||
        $stateBox.val() === "VT" ||
        $stateBox.val() === "VA" ||
        $stateBox.val() === "WA" ||
        $stateBox.val() === "WV" ||
        $stateBox.val() === "WI" ||
        $stateBox.val() === "WY"
    );
}
function validateFeet($feetBox) {
    return $feetBox.val() === "4" || $feetBox.val() === "5" || $feetBox.val() === "6";
}
function validateInches($inchesBox) {
    return (
        $inchesBox.val() === "0" ||
        $inchesBox.val() === "1" ||
        $inchesBox.val() === "2" ||
        $inchesBox.val() === "3" ||
        $inchesBox.val() === "4" ||
        $inchesBox.val() === "5" ||
        $inchesBox.val() === "6" ||
        $inchesBox.val() === "7" ||
        $inchesBox.val() === "8" ||
        $inchesBox.val() === "9"
    );
}
function validateTermLength($termLengthBox) {
    return (
        $termLengthBox.val() === "10" ||
        $termLengthBox.val() === "15" ||
        $termLengthBox.val() === "20" ||
        $termLengthBox.val() === "25" ||
        $termLengthBox.val() === "30"
    );
}
function validateCoverageAmt($coverageAmtBox) {
    return (
        $coverageAmtBox.val() === "100,000" ||
        $coverageAmtBox.val() === "150,000" ||
        $coverageAmtBox.val() === "200,000" ||
        $coverageAmtBox.val() === "250,000" ||
        $coverageAmtBox.val() === "300,000" ||
        $coverageAmtBox.val() === "350,000" ||
        $coverageAmtBox.val() === "400,000" ||
        $coverageAmtBox.val() === "450,000" ||
        $coverageAmtBox.val() === "500,000" ||
        $coverageAmtBox.val() === "550,000" ||
        $coverageAmtBox.val() === "600,000" ||
        $coverageAmtBox.val() === "650,000" ||
        $coverageAmtBox.val() === "700,000" ||
        $coverageAmtBox.val() === "750,000" ||
        $coverageAmtBox.val() === "800,000" ||
        $coverageAmtBox.val() === "850,000" ||
        $coverageAmtBox.val() === "900,000" ||
        $coverageAmtBox.val() === "950,000" ||
        $coverageAmtBox.val() === "1,000,000" ||
        $coverageAmtBox.val() === "2,000,000"
    );
}

/** *************************TODO****************************** */
/**
 * validateIsMember - validate user has checked an answer to "Are you a AAA
 * member?"
 *
 * @param $genderBox
 * @returns {boolean}
 */
function validateIsMember() {
    return $('input[name="isMember"]')[0].checked || $('input[name="isMember"]')[1].checked;
}

/**
 * emailFormat - validates an email against email regex
 * the regex below does not ad-hear to the RFC 5322 Official Standard was a requirement from buisiness.
 * This work was done on card DW-1240 ;) .) 'D ...
 * 10/19/2017 WAS
 */
function emailFormat(value, required) {
    return isEmailAddressFormatValid(value) || (!required && value === "");
}

/**
 * phoneFormat - determine whether value is a valid 10 digit phone number.
 *
 * @param value
 * @returns {boolean}
 */
function phoneFormat(value) {
    return isPhoneFormatValid(value);
}

/**
 * age - validates age is over 18
 *
 * @param month
 *            {string} age string month
 * @param day
 *            {string} age string day
 * @param year
 *            {string} age string year
 * @returns {Object}
 */
function age(month, day, year) {
    var AGE_MIN = aaalife.data.validation.restrictions.ageRestrictions.minAge;
    var AGE_MAX = aaalife.data.validation.restrictions.ageRestrictions.maxAge;
    const age = ageLastBirthday(createDate(year, month, day), new Date());

    return {
        underMin: age < AGE_MIN,
        valid: !!month && !!day && !!year && age >= AGE_MIN && age <= AGE_MAX,
        allSelected: !!month && !!day && !!year,
        overMax: age >= AGE_MAX,
    };
}

/**
 * Weight must be a number between 2-3 digits
 *
 * @param number
 *            {string} weight
 * @returns {boolean} true when criteria met
 */
function weight(number) {
    return !isNaN(number) && number > 9 && number <= 999;
}

/**
 * validateExistingPolicy - validate existing policy if not
 * traditional_term, if Yes is checked and express_term and
 * replacementPolicyNotOffered
 *
 * @param $el
 * @param type
 * @param replacementPolicyNotOffered
 * @returns {{valid: boolean, requiredValid: (boolean|*)}}
 */
function validateExistingPolicy($el, type, replacementPolicyNotOffered) {
    return {
        valid:
            type === "traditional_term" ||
            !($el.is(":checked") && $el.val() == "Yes" && type === "express_term" && replacementPolicyNotOffered),
        requiredValid:
            type === "traditional_term" ||
            (type === "express_term" && !replacementPolicyNotOffered) ||
            ($el.length > 0 && type === "express_term" && replacementPolicyNotOffered),
    };
}

/**
 * Evaluate BMI from user data
 *
 * @param {object}
 *            userData object containing { weight, height, month, day, year }
 * @returns {Object}
 */
function getBMI(userData) {
    var valid;
    var height = userData.feet * 12 + userData.inches * 1;
    var weight = userData.weight * 1;
    var bmi = (703 * weight) / (height * height);

    if (userData.quoteType === "express_term") {
        valid = true;
    } else {
        const userAge = ageLastBirthday(createDate(userData.year, userData.month, userData.day), new Date());
        valid = aaalife.data.validation.healthConditions.values.some(function (condition) {
            return (
                bmi >= condition.criteria.minBMI * 1 &&
                bmi <= condition.criteria.maxBMI * 1 &&
                condition.criteria.maxAge >= userAge
            );
        });
    }

    if (!userData.inches) {
        valid = false;
    }

    // aaalife.data.validation.bmi = valid;

    return {
        valid: valid,
        bmi: bmi,
    };
}

/**
 * validateAgeNicotine RULE (as of May 2016) - If user enters age >50 and
 * Nicotine = Yes, remove 30 year term from Term Length dropdown
 */
function ageNicotine(nicotine, age, maxAgeFor30YearTerm = 50) {
    return age > maxAgeFor30YearTerm && nicotine === "Yes"; // return true -> ineligible
    // for 30 yr
}

/**
 * validateBMI - calculates BMI and returns if it is in the proper ranges
 * for insurance
 *
 * @param {Object}
 *            userData object containing { weight, height, month, day, year }
 *
 * BMI is calculated from the entered Height and Weight, in this formula:
 * (703 * WL) / (HI * HI) where WL = Weight in Pounds and HI = Height in
 * Inches
 *
 * @returns {Array}
 */

function getHealthConditions(userData) {
    const age = ageLastBirthday(createDate(userData.year, userData.month, userData.day), new Date());

    return aaalife.data.validation.healthConditions.values.find(_checkCondition);

    /**
     * _checkCondition
     *
     * @param condition
     * @returns {boolean}
     * @private
     */
    function _checkCondition(condition) {
        var maxAge = condition.criteria.maxAge;
        var nicotineAllowed = condition.criteria.nicotineUsedLast12Months === "true";
        var nicotineUsed = typeof userData.nicotine !== "undefined" && userData.nicotine !== "" && userData.nicotine === "Yes";
        var bmi = getBMI(userData).bmi;

        // Check against criteria for age, bmi and nicotine use, return
        // first set of conditions where it matches the criteria
        if (
            maxAge - age >= 0 &&
            bmi >= condition.criteria.minBMI * 1 &&
            bmi <= condition.criteria.maxBMI * 1 &&
            nicotineUsed === nicotineAllowed
        ) {
            return true;
        }
        return false;
    }
}
