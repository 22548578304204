import { transferQuoteToEt2 } from "../utils/expressterm-link";
import { sendReceiveData } from "../collector";

async function navigateToEt2(e) {
    // HACK: Send blank payload to aggregation to see if aggregationSessionId in response has changed
    const response = await sendReceiveData({}, aaalife.ctx + "restservices/aggregate", false);
    if (document.getElementById("sessionID").value != response.aggregationSessionId) {
        window.location.href = "/session-expired";
        return;
    }

    return transferQuoteToEt2(
        aaalife.fromServer.urlStrings.etQuoteTransferApiUrl,
        window.location.href,
        aaalife.fromServer.urlStrings.etSubdomain,
    )
        .then((et2Url) => {
            window.location.href = et2Url;
        })
        .catch((error) => {
            console.error(error);
            window.location.href = "/unexpected-error";
        });
}

function setBehaviorForStartNowButton(startNowButton) {
    if (startNowButton) {
        startNowButton.addEventListener("click", navigateToEt2);
        startNowButton.setAttribute("type", "button");
    }
}

export function render() {
    const startNowButton = document.querySelector("#organicLandingForm .btnLandingPageStart");
    setBehaviorForStartNowButton(startNowButton);
}
