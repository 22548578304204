/**
 * @typedef {Object} Panel1ToPanel2TransitionPayload - The type describing the state returned by the form level reducer
 * @property {string} zip
 * @property {"Male"|"Female"} gender
 * @property {string} state
 * @property {string} membership
 * @property {string} age
 * @property {string} year
 * @property {string} month
 * @property {string} day
 * @property {string=} email
 */

/**
 * @typedef {Object} AggregateResponse - The type describing the format returned by the aggregate rest endpoint
 * @property {string} aggregationSessionId
 * @property {string} visitorId
 *
 */

/**
 * @typedef {Object} InteractionTypeCodes - The type describing the interaction codes for the steps within a quote form
 * @property {string} panelOne: - The interaction type code (itc) value associated with a visit to the first panel
 * @property {string} panelTwo: - The interaction type code (itc) value associated with a visit to the second panel
 * @property {string} panelThree: - The interaction type code (itc) value associated with a visit to the third panel
 * @property {string} panelFour: - The interaction type code (itc) value associated with a visit to the fourth panel
 * @property {string} quoteResults: - The interaction type code (itc) value associated with a submission of data to receive a quote
 *
 */

/**
 * Aggregation service client for abstracting calls to the /aggregation endpoint
 */
export class AggregationClient {
    /**
     *
     * @param {InteractionTypeCodes} interactionCodes
     */
    constructor(interactionCodes) {
        this.interactionCodes = interactionCodes;
    }

    /**
     * @returns {Promise.<AggregateResponse>}
     */
    reportPanel1Visit() {
        return postToAggregate({
            attr: { itc: this.interactionCodes.panelOne },
        });
    }

    /**
     * @param {Panel1ToPanel2TransitionPayload} arg(0)
     * @returns {Promise.<AggregateResponse>}
     */
    reportPanel2Visit({ zip, gender, state, membership, age, year, month, day, email }, isbackNavigation, source) {
        return isbackNavigation
            ? postToAggregate({
                  attr: { itc: this.interactionCodes.panelTwo },
              })
            : postToAggregate({
                  current: {
                      zip: zip,
                      gender: gender,
                      month: month,
                      day: day,
                      year: year,
                      isMember: membership,
                      email: email,
                  },
                  attr: { itc: this.interactionCodes.panelTwo, source: source, loadPage: "/restservices/aggregate" },
              });
    }

    reportPanel3Visit({ zip, gender, state, membership, age, year, month, day, email, productType }, isbackNavigation, source) {
        return isbackNavigation
            ? postToAggregate({
                  attr: { itc: this.interactionCodes.panelThree },
              })
            : postToAggregate({
                  current: {
                      zip: zip,
                      gender: gender,
                      month: month,
                      day: day,
                      year: year,
                      isMember: membership,
                      email: email,
                      insurance_type: productType,
                  },
                  attr: {
                      itc: this.interactionCodes.panelThree,
                      source: source,
                      specialAction: "sf-p",
                      loadPage: "/restservices/aggregate",
                  },
              });
    }

    reportPanel4Visit({ feet, inches, weight, nicotineUse, rateYourHealth, coverageAmount, termLength }, source) {
        return postToAggregate({
            current: {
                feet,
                inches,
                weight,
                nicotineUse,
                rateYourHealth,
                coverageAmount,
                termLength,
            },
            // TODO: We may want a new interaction code (itc) here for the contact information panel, perhaps AL31C
            attr: { source: source, specialAction: "sf-p", loadPage: "/restservices/aggregate" },
        });
    }

    /**
     * @typedef {Object} AggregationRestResponse - The type describing the state returned by the form level reducer
     * @property {string} aggregationSessionId - The session identifier used by the aggregation system
     * @property {string} visitorId - The visitor identifier used by HippoCMS/brXM
     */

    /**
     * @param {import("./reducer").FormState} data - The data entered by the user that will be issued along with a request for a quote
     * @return {Promise<AggregationRestResponse>} This is the result
     */
    submitQuoteRequest(data, { source, aaaLifeReturnPage, leadRouting }) {
        return postToAggregate({
            current: {
                zip: data.zip,
                gender: data.gender,
                month: data.month.toString(),
                day: data.day.toString(),
                year: data.year.toString(),
                isMember: data.isMember ? "Yes" : "No",
                email: data.email,
                insurance_type: data.productType,

                aaaLifeReturnPage: aaaLifeReturnPage,

                feet: data.feet.toString(),
                inches: data.inches.toString(),
                weight: data.weight.toString(),
                nicotineUse: data.nicotine === "nicotineUse-LessThan12MonthsAgo" ? "Yes" : "No",
                rateYourHealth: data.healthRating || "",
                coverageAmount: data.coverageAmount,
                termLength: data.termLength,

                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,

                leadRouting: leadRouting,

                invalidData: false,
            },
            attr: {
                itc: this.interactionCodes.quoteResults,
                source: source,
                loadPage: "/restservices/aggregate",
                specialAction: "sf-p",
            },
        });
    }

    reportQuoteResultVisit(premiums, selectedPremium, productType) {
        const current =
            productType === "traditional_term"
                ? {
                      premiumAmountTT10Year: premiums[0],
                      premiumAmountTT15Year: premiums[1],
                      premiumAmountTT20Year: premiums[2],
                      premiumAmountTT30Year: premiums[3],
                      premiumAmount: selectedPremium,
                  }
                : {
                      premiumAmount10Year: premiums[0],
                      premiumAmount15Year: premiums[1],
                      premiumAmount20Year: premiums[2],
                      premiumAmount25Year: premiums[3],
                      premiumAmount30Year: premiums[4],
                      premiumAmount: selectedPremium,
                  };

        return postToAggregate({
            current: current,
            attr: { /*source: source,*/ specialAction: "sf-p", loadPage: "/restservices/aggregate" },
        });
    }

    reportFullContactLead() {
        return postToAggregate({
            attr: { itc: "AL34", /*source: source,*/ specialAction: "sf", loadPage: "/restservices/aggregate" },
        });
    }
}

/**
 * @typedef {Object} AggregateAttr - The
 * @property {string} [itc]
 * @property {string} [source]
 * @property {"sf-p"|"sf"} [specialAction]
 * @property {string} [loadPage]
 */

/**
 * @typedef {Object} AggregateRequestBody - The type describing the format of the JSON body for requests to aggregate
 * @property {AggregateAttr} attr
 * @property {Object} [current]
 */

/**
 * @param {AggregateRequestBody} body
 * @returns {Promise<AggregateResponse>}
 */
function postToAggregate(body) {
    return fetch(aaalife.ctx + "restservices/aggregate", {
        headers: {
            accept: "*/*",
            "content-type": "application/json",
        },
        body: JSON.stringify(body),
        method: "POST",
        mode: "cors",
        credentials: "include",
    }).then((response) => {
        return response.json();
    });
}
