function getOverride(propertyName) {
    return window.aaaLifeExternalOverrides && window.aaaLifeExternalOverrides[propertyName];
}

export function getOverrides() {
    return {
        trafficMonetizationIsQualityBmi: getOverride("trafficMonetizationIsQualityBmi"),
        trafficMonetizationIsQualityAge: getOverride("trafficMonetizationIsQualityAge"),
    };
}
