import React, { useState } from "react";
import { HealthSectionContent } from "./HealthSectionContent";

const healthConditionData = {
    fair: {
        nicotine: "13-35",
        bloodPressure: ["150/95 for ages 18-69", "155/90 for ages 70+"],
        hypertensionMedications: "Well controlled with 3 or fewer medications",
        cholesterol: "140 to 325",
        hdl: ["7.0 for ages 18-69", "8.0 for ages 70+"],
        drugAbuseHistory: "None for 6+ years",
        heartDisease: "2 parents/siblings died from cancer or heart disease prior to age 60",
        movingViolations: "4 or less in last 3 years",
        duiHistory: "None in the last 3 years",
    },
    good: {
        nicotineRange: "36-59",
        bloodPressure: ["145/90 for ages 18-69", "145/90 for ages 70+"],
        hypertensionMedications: "Well controlled with 2 or fewer medications",
        cholesterol: "140 to 325",
        hdl: ["5.5", ""],
        drugAbuseHistory: "None for 10+ years",
        heartDisease: "No family history of death from heart disease or cancer prior to age 60",
        movingViolations: "3 or less in last 3 years",
        duiHistory: "None in the last 5 years",
    },
    excellent: {
        nicotineRange: "60+",
        bloodPressure: ["135/85 for ages 18-69", "140/85 for ages 70+"],
        hypertensionMedications: "Well controlled with 1 medication only",
        cholesterol: "140 to 325",
        hdl: ["4.5", ""],
        drugAbuseHistory: "None for 10+ years",
        heartDisease: "No family history or diagnosis of heart disease or cancer prior to age 60",
        movingViolations: "2 or less in last 3 years",
        duiHistory: "None in the last 5 years",
    },
};

export function TraditionalTermHealthConditionsPopup({ downArrowSvgIconUrl, usedNicotine, conditions, selectedHealthRating }) {
    const nicotine = "nicotine";
    const nonnicotine = "nonnicotine";
    const excellent = "excellent";
    const verygood = "verygood";
    const good = "good";
    const fair = "fair";

    const selectedNicotineClass = usedNicotine ? nicotine : nonnicotine;

    const showHealthCondition = (conditionClass) => {
        return conditions && conditions.indexOf(conditionClass) >= 0;
    };

    const sections = [
        {
            title: "Fair",
            healthRating: fair,
        },
        {
            title: "Good",
            healthRating: good,
        },
        {
            title: "Very Good",
            healthRating: verygood,
        },
        {
            title: "Excellent",
            healthRating: excellent,
        },
    ].filter((section) => showHealthCondition(section.healthRating, section.nicotineClass));

    const [selectedSection, setSelectedSection] = useState(
        sections.findIndex((section) => section.healthRating === selectedHealthRating),
    );

    const getSectionLabelClass = (sectionIndex) => {
        return sectionIndex === 0 ? "test show-for-small" : "show-for-small hide-for-xlarge";
    };

    return (
        <div className="traditionalTerm healthConditionsModal">
            <div className="SecondaryError-modal modalDialog modalOutBlock open">
                <div>
                    <div className="row collapse">
                        <div className="large-10 large-offset-2 columns">
                            <h2 className="SecondaryError-modal-title">Overall Health Guidelines for Traditional Term</h2>
                            <p className="SecondaryError-modal-mainContent">
                                This chart outlines some of the key criteria used to evaluate your health condition status.
                                While we cannot fully determine your rate class until your policy has been underwritten, the
                                information below will provide you with a general idea.
                            </p>
                        </div>
                    </div>
                    <div className="row large-up-2 small-up-12 collapse content">
                        {/* <div class="columns"> */}
                        <div id="errorPopup" className="accordion popupSmall">
                            {sections.map((section, i) => (
                                <section
                                    key={section.healthRating}
                                    className={`${selectedSection === i ? "" : "ac_hidden"} ${[
                                        section.healthRating,
                                        selectedNicotineClass,
                                    ].join(" ")}`}
                                >
                                    <h3 onClick={() => setSelectedSection(selectedSection !== i ? i : i + 1)}>
                                        <a href="#">{section.title}</a>
                                        <svg className="icon icon-arrow-down hide-for-xlarge">
                                            <use xlinkHref={downArrowSvgIconUrl} />
                                        </svg>
                                    </h3>
                                    <div className="content">
                                        <HealthSectionContent
                                            usedNicotine={usedNicotine}
                                            labelClass={getSectionLabelClass(i)}
                                            {...healthConditionData[section.healthRating]}
                                        />
                                    </div>
                                    <div className="clearfix" />
                                </section>
                            ))}
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
