import React, { useReducer, useEffect, useState } from "react";
import { Panel1 } from "./panels/Panel1";
import { Panel2 } from "./panels/Panel2";
import { Panel3 } from "./panels/Panel3";
import { Panel4 } from "./panels/Panel4";
import { reducer } from "./reducer";
import { NAVIGATE_NEXT, NAVIGATE_BACK, LOCATION_RESTRICTED, ZIP_CHANGED } from "./actions";

export function Form({
    prefillData,
    restrictions,
    effects: { panelTransitionHandler, presentQuoteHandler, zipInfoLookupEffect, triggerZipChanged, openModalEffect },
    resources: resources,
    productTypeOptions,
    Knockout,
    disclaimersHtml,
}) {
    const [state, dispatch] = useReducer(reducer, {
        activePanel: 1,
        panels: { 1: {}, 2: {}, 3: {}, 4: {} },

        year: prefillData.dobYear,
        month: prefillData.dobMonth,
        day: prefillData.dobDay,
        zip: prefillData.zip,
        email: prefillData.email,
        gender: prefillData.gender,
        isMember: prefillData.isMember,

        productType: prefillData.productType,
        termLength: prefillData.termLength,
        coverageAmount: prefillData.coverageAmount,
        replacingPolicy: prefillData.replacingPolicy,
        feet: prefillData.feet,
        inches: prefillData.inches,
        weight: prefillData.weight,
        nicotine: prefillData.nicotine,
        healthRating: prefillData.healthRating,
        firstName: prefillData.firstName,
        lastName: prefillData.lastName,
        phone: prefillData.phone,

        productTypeOptions: productTypeOptions,

        // DEBUG: Allow persistence between refreshes for development
        ...(document.location.href.includes && document.location.href.includes("dev_only")
            ? JSON.parse(localStorage.getItem("small-footprint-form-state"))
            : {}),
    });
    const activePanel = state.activePanel;

    // HACK: Remove after a/b test and during implementation
    const isProduction = !(
        document.location.hostname.includes("localhost") ||
        document.location.hostname.includes("dev") ||
        document.location.hostname.includes("tst")
    );

    useEffect(() => {
        // DEBUG: Allow persistence between refreshes for development
        if (!isProduction && document.location.href.includes && document.location.search.includes("dev_only")) {
            localStorage.setItem("small-footprint-form-state", JSON.stringify(state));
        }
    }, [state]);

    useEffect(() => {
        // TODO: Possibly constrain state to an indpendent object schema
        panelTransitionHandler(state);
    }, [state.activePanel]);

    useEffect(() => {
        if (!state.shouldPresentQuote) {
            return;
        }
        presentQuoteHandler(state);
    }, [state.shouldPresentQuote]);

    const onNext = (panelState) => dispatch({ type: NAVIGATE_NEXT, payload: { panelState } });
    const onBack = (panelState) => dispatch({ type: NAVIGATE_BACK, payload: { panelState } });

    const zipInfoLookupEffectIntercept = (zip) =>
        zipInfoLookupEffect(zip)
            .then((result) => {
                dispatch({ type: LOCATION_RESTRICTED, payload: { ...result } });
                // Changes the ZIP code at the form level even though it hasn't been submitted on the panel. This is
                // done in order to match pre-existing behavior.
                dispatch({ type: ZIP_CHANGED, payload: { zip } });
                return result;
            })
            .catch((error) => {
                dispatch({ type: LOCATION_RESTRICTED, payload: {} });
                throw error;
            });

    const [knockoutCode, setKnockoutCode] = useState(null);
    const [knockoutOpen, setKnockoutOpen] = useState(false);

    const knockoutEffect = (newKnockoutCode, zip) => {
        setKnockoutCode(newKnockoutCode);
        setKnockoutOpen(true);
    };

    return (
        <>
            <Panel1
                active={activePanel === 1}
                onNext={onNext}
                initialState={{
                    zip: state.zip,
                    dobYear: state.year,
                    dobMonth: state.month,
                    dobDay: state.day,
                    email: state.email,
                    gender: state.gender,
                    isMember: state.isMember,
                }}
                knockoutEffect={knockoutEffect}
                zipInfoLookupEffect={zipInfoLookupEffectIntercept}
                zipChangedEffect={triggerZipChanged}
                restrictions={restrictions}
                resources={resources}
            />
            <Panel2
                active={activePanel === 2}
                onNext={onNext}
                onBack={onBack}
                initialState={{ productType: state.productType }}
                resources={resources}
            />
            <Panel3
                active={activePanel === 3}
                onNext={onNext}
                onBack={onBack}
                initialState={{
                    replacingPolicy: state?.locationRestrictions?.replacementPolicyNotOffered
                        ? state.replacingPolicy
                        : undefined,
                    feet: state.feet,
                    inches: state.inches,
                    weight: state.weight,
                    nicotine: state.nicotine,
                    healthRating: state.healthRating,
                    coverageAmount: state.coverageAmount,
                    termLength: state.termLength,

                    ...state.panels[3],
                }}
                knockoutEffect={knockoutEffect}
                openModalEffect={openModalEffect}
                restrictions={restrictions}
                replacementPolicyNotOffered={state?.locationRestrictions?.replacementPolicyNotOffered || undefined}
                zip={state.zip}
                productType={state.productType}
                dob={{ year: state.year, month: state.month, day: state.day }}
                age={state.age}
                resources={resources}
            />
            <Panel4
                active={activePanel === 4}
                onNext={onNext}
                onBack={onBack}
                initialState={{
                    firstName: state.firstName,
                    lastName: state.lastName,
                    phone: state.phone,
                    email: state.email,

                    ...state.panels[4],
                }}
                disclaimersHtml={disclaimersHtml}
                resources={resources}
            />
            {knockoutCode && (
                <Knockout
                    knockoutCode={knockoutCode}
                    zip={state.zip}
                    open={knockoutOpen}
                    onCloseButtonClicked={() => setKnockoutOpen(false)}
                />
            )}
        </>
    );
}
