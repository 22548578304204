import $ from "jquery";
import { appendData, collectFormData, getData, sendData, transformData } from "../collector";
import { ageLastBirthday, createDate } from "../utils/age";
import { browserStorage } from "./aaalife-utils-browser-storage";

export function initEstimatedNeedsForm() {
    var _estimationFormValid = {
        birthdate: false,
        salary: false,
        emailFormat: false,
    };

    var estimatedAge;
    var estimatedCoverageAmount;
    var estimatedTermLength;

    if ($("#calculateYourNeedsForm").length > 0) {
        $(_init);
    }
    if ($("#calculateNeeds-shortForm").length > 0) {
        $(_initShortForm);
    }
    if ($(".calculateYourNeeds-longForm").length > 0) {
        $(_initLongForm);
    }

    /**
     * _init - initialize the General Estimate form at the top of the Calculate
     * Your Needs page
     *
     * @private
     */
    function _init() {
        _initEstimatedNeedsForm();
        _initEventListeners();

        if (_allValuesDefined()) {
            _calculateAndDisplayEstimates();
        }
        $(".calculateYourNeeds-submitButton").on("click", _getGeneralEstimate);
        $("#calculateYourNeeds-getGeneralQuote").on("submit", _goGetEstimatedQuote);
        $("#calculateYourNeeds-getDetailedQuote").on("submit", _goGetDetailedQuote);
    }

    /**
     * _initShortForm - initialize the short form in the Calc Your Needs panel
     * on Homepage A
     *
     * @private
     */
    function _initShortForm() {
        _initEstimatedNeedsForm();
        _initEventListeners();

        $("#salary").on("keyup blur", _preventNonNumeric);
        $("#salary").on("keyup blur", _formatFieldAsDollars);
        $("#salary").on("keyup blur", formatSalary);

        $("#calculateNeeds-shortForm").on("submit", _submitShortForm);
    }

    function formatSalary() {
        var salary = $("#salary");
        var salaryValue = salary.val();
        salaryValue = salaryValue.replace(/,/g, "");
        salaryValue = salaryValue.replace("$", "");
        if (salaryValue.length > 9) {
            salaryValue = salaryValue.slice(0, 9);
            $("#salary").val(salaryValue);
            $("#salary").focus();
            $("#salary").blur();
        }
    }

    /**
     * _initLongForm - initialize the detailed form on the lower part of the
     * Calculate Your Needs page
     *
     * @private
     */
    function _initLongForm() {
        $("#salary").on("keyup blur", _preventNonNumeric);
        $("#salary").on("keyup blur", _formatFieldAsDollars);

        populateValuesfromLocalStorage();

        $("#looseEnds-input").on("keyup blur", _calculateLooseEnds);
        $("#looseEnds-input").on("keyup blur", _formatFieldAsDollars);
        $("#looseEnds-percent").on("change", _calculateLooseEnds);
        $("#looseEnds-input").on("blur", _collectAndSendCalculateNeedsData);

        $("#clearSlate-input").on("keyup blur", _calculateClearSlate);
        $("#clearSlate-input").on("keyup blur", _formatFieldAsDollars);
        $("#clearSlate-percent").on("change", _calculateClearSlate);
        $("#clearSlate-input").on("blur", _collectAndSendCalculateNeedsData);

        $("#provideFamily-input").on("keyup blur", _calculateProvideFamily);
        $("#provideFamily-input").on("keyup blur", _formatFieldAsDollars);
        $("#provideFamily-years").on("change", _calculateProvideFamily);
        $("#provideFamily-input").on("blur", _collectAndSendCalculateNeedsData);

        $("#protectHome-input").on("keyup blur", _calculateProtectHome);
        $("#protectHome-input").on("keyup blur", _formatFieldAsDollars);
        $("#protectHome-percent").on("change", _calculateProtectHome);
        $("#protectHome-input").on("blur", _collectAndSendCalculateNeedsData);

        $("#kidsCollege-input").on("keyup blur", _calculateKidsCollege);
        $("#kidsCollege-input").on("keyup blur", _formatFieldAsDollars);
        $("#kidsCollege-percent").on("change", _calculateKidsCollege);
        $("#kidsCollege-numberOfKids").on("change", _calculateKidsCollege);
        $("#kidsCollege-input").on("blur", _collectAndSendCalculateNeedsData);

        $("#extraMile-input").on("keyup blur", _calculateExtraMile);
        $("#extraMile-input").on("keyup blur", _formatFieldAsDollars);
        $("#extraMile-input").on("blur", _collectAndSendCalculateNeedsData);

        $("#alreadyComingIn-input").on("keyup blur", _calculateAlreadyComingIn);
        $("#alreadyComingIn-input").on("keyup blur", _formatFieldAsDollars);
        $("#alreadyComingIn-input").on("blur", _collectAndSendCalculateNeedsData);

        /*calculate all fields incase user is returning to page using the back button*/
        _calculateLooseEnds();
        _calculateClearSlate();
        _calculateProvideFamily();
        _calculateProtectHome();
        _calculateKidsCollege();
        _calculateExtraMile();
        _calculateAlreadyComingIn();
    }

    function populateValuesfromLocalStorage() {
        checkForUndefinedAndSetField(sessionStorage.looseEnds, "#looseEnds-input");
        checkForUndefinedAndSetField(sessionStorage.clearState, "#clearSlate-input");
        checkForUndefinedAndSetField(sessionStorage.provideFamily, "#provideFamily-input");
        checkForUndefinedAndSetField(sessionStorage.protectHome, "#protectHome-input");
        checkForUndefinedAndSetField(sessionStorage.kidsCollege, "#kidsCollege-input");
        checkForUndefinedAndSetField(sessionStorage.extraMile, "#extraMile-input");
        checkForUndefinedAndSetField(sessionStorage.alreadyComingIn, "#alreadyComingIn-input");
    }

    function checkForUndefinedAndSetField(valToCheck, fieldToSet) {
        if (!$.isEmptyObject(valToCheck)) {
            $(fieldToSet).val(valToCheck);
        }
    }

    /**
     * _initEstimatedNeedsForm
     *
     * @private
     */
    function _initEstimatedNeedsForm() {
        _populateDropdowns($("#dobYear"), new Date().getFullYear() - 13, new Date().getFullYear() - 100, -1);
        _populateDaysDropdown();

        $("input, select").addClass("pristine").on("focus blur", _markDirty);

        _populateUserValuesIfSaved();
    }

    /**
     * _initEventListeners
     *
     * @private
     */
    function _initEventListeners() {
        $(".showtooltip svg").on("click", function () {
            $(this).parent().find(".tooltipinfo").show();
        });
        $(".tooltipinfo .tooltipclose").on("click", function () {
            $(this).parent().hide();
        });

        /* == Validate Age == */
        $("#dobYear").on("change", _validateAge);
        $("#dobYear").on("change", _populateDaysDropdown);
        $("#dobDay").on("change", _validateAge);
        $("#dobMonth").on("change", _validateAge);
        $("#dobMonth").on("change", _populateDaysDropdown);

        $("#email").on("blur", _validateEmail);
    }

    function _preventNonNumeric() {
        var $el = $(this);
        $el.val($el.val().replace(/\D+/g, ""));
    }

    function _formatFieldAsDollars() {
        var $el = $(this);
        var dollars = $el.val();
        var parts = dollars.toString().split(".");
        var separator = ",";

        parts[0] = parts[0]
            .toString() // make it a string
            .replace(/\D*/g, "") // replace non-numbers
            .replace(/\B(?=(\d{3})+(?!\d))/g, separator); // insert commas

        dollars = "$" + parts.join(".");

        $el.val(dollars);
    }

    /**
     * _goGetEstimatedQuote - submit handler for "Get a Quote" button associated
     * with the general estimate
     *
     * @private
     */
    function _goGetEstimatedQuote() {
        _collectAndSendCalculateNeedsData();

        var estAmount = _stripDollarFormatting($(".estimatedCoverageAmount").text());
        _goGetAQuote(estAmount);
    }

    /**
     * _goGetDetailedQuote - submit handler for "Get a Quote" button associated
     * with the detailed estimate
     *
     * @private
     */
    function _goGetDetailedQuote() {
        _collectAndSendCalculateNeedsData();

        var calcAmount = _stripDollarFormatting($("#calculateYourNeeds-grandTotal2").text());
        _goGetAQuote(calcAmount);
    }

    function _collectAndSendCalculateNeedsData() {
        var button = $("#calculateYourNeeds-submitButton");

        var formData = collectFormData($("#calculateYourNeedsForm"), button.data());
        let data = getData(formData, "current");

        data = appendData(data, "looseEnds", _stripDollarFormatting($("#looseEnds-total").text()));
        data = appendData(data, "clearSlate", _stripDollarFormatting($("#clearSlate-total").text()));
        data = appendData(data, "provideFamily", _stripDollarFormatting($("#provideFamily-total").text()));
        data = appendData(data, "protectHome", _stripDollarFormatting($("#protectHome-total").text()));
        data = appendData(data, "kidsCollege", _stripDollarFormatting($("#kidsCollege-total").text()));
        data = appendData(data, "extraMile", _stripDollarFormatting($("#extraMile-total").text()));
        data = appendData(data, "alreadyComingIn", _stripDollarFormatting($("#alreadyComingIn-total").text()));
        data = appendData(data, "grandTotal", _stripDollarFormatting($("#calculateYourNeeds-grandTotal1").text()));
        formData = appendData(formData, "current", data);
        saveFormValuesToLocalStorage();
        sendData(formData, false);
    }

    function saveFormValuesToLocalStorage() {
        sessionStorage.looseEnds = $("#looseEnds-input").val();
        sessionStorage.clearState = $("#clearSlate-input").val();
        sessionStorage.provideFamily = $("#provideFamily-input").val();
        sessionStorage.protectHome = $("#protectHome-input").val();
        sessionStorage.kidsCollege = $("#kidsCollege-input").val();
        sessionStorage.extraMile = $("#extraMile-input").val();
        sessionStorage.alreadyComingIn = $("#alreadyComingIn-input").val();
    }

    /**
     * _goGetAQuote - shared submit handler for "Get a Quote" buttons
     *
     * @private
     */
    function _goGetAQuote(amount) {
        var quote = _getQuoteData();
        quote.estimatedNeed = amount;

        aaalife.data.quote = quote;
        browserStorage.setObject("quote", aaalife.data.quote);
    }

    /**
     * _populateUserValuesIfSaved
     *
     * @private
     */
    function _populateUserValuesIfSaved() {
        var user = _getUserData();
        aaalife.data.user = user;

        $("#dobMonth").val(user.dobMonth);
        $("#dobDay").val(user.dobDay);
        $("#dobYear").val(user.dobYear);
        $("#salary").val(user.salary);
        $("#email").val(user.email);
    }

    /**
     * _getUserData
     *
     * @returns {?Object}
     * @private
     */
    function _getUserData() {
        var user = browserStorage.getObject("user");
        if (!user) {
            user = aaalife.data.user || {};
        }
        return user;
    }

    /**
     * _getQuoteData
     *
     * @returns {?Object}
     * @private
     */
    function _getQuoteData() {
        var quote = browserStorage.getObject("quote");
        if (!quote) {
            quote = aaalife.data.quote || {};
        }
        return quote;
    }

    /**
     * _allValuesDefined
     *
     * @returns {boolean}
     * @private
     */
    function _allValuesDefined() {
        var allDefined = false;
        var user = _getUserData();
        if (user && user.dobMonth && user.dobDay && user.dobYear && user.salary) {
            allDefined = true;
        }
        return allDefined;
    }

    /**
     * _populateDropdowns
     *
     * @param dropdown
     * @param startVal
     * @param maxVal
     * @param dirMod
     * @private
     */
    function _populateDropdowns(dropdown, startVal, maxVal, dirMod) {
        var i;
        for (i = startVal; startVal > maxVal ? i >= maxVal : i <= maxVal; i += dirMod) {
            dropdown.append($("<option></option>").val(i).html(i));
        }
    }

    /**
     * _populateDaysDropdown
     *
     * @private
     */
    function _populateDaysDropdown() {
        var month = $("#dobMonth").val() * 1 || new Date().getMonth();
        var year = $("#dobYear").val() * 1 || new Date().getFullYear();
        var $day = $("#dobDay");
        var oldDay = $day.val();
        var numDays = new Date(year, month, 0).getDate();

        $day.find("option").not(":first-child").remove();

        _populateDropdowns($day, 1, numDays, 1);

        if (oldDay && oldDay <= numDays) {
            $day.val(oldDay);
        } else if ($day.hasClass("dirty") || $day.hasClass("pristine")) {
            $day.val("");
            if ($day.hasClass("dirty") && oldDay) {
                $day.removeClass("valid").addClass("invalid");
            }
            _estimationFormValid.birthdate = false;
        }
    }

    /**
     * _submitShortForm
     *
     * @private
     */
    function _submitShortForm(e) {
        var isValid;
        var $emailField = $("#calculateNeeds-ShortForm #email");
        var $salaryField = $("#calculateNeeds-ShortForm #salary");

        _validateAge(true);

        _estimationFormValid.salary = _validateSalary.call($salaryField);
        _estimationFormValid.emailFormat = _validateEmail.call($emailField);

        isValid = Object.keys(_estimationFormValid).every(function (key) {
            return _estimationFormValid[key];
        });

        _storeUserValues();

        var calcSource = $("#calculateNeeds-shortForm").data("calc-source");
        calcSource = calcSource.toString();
        var formData;
        var form = $(this).closest("form");
        var button = $(this).closest("button");
        formData = collectFormData(form, button.data());

        if (isValid) {
            if (formData.current.email !== null && formData.current.email !== "") {
                $("#calculateNeeds-shortForm").append(
                    '<input type="hidden" id="attr-source" name="attr-source" value="' + calcSource + '">',
                );
            }
        } else {
            e.preventDefault();
            $(".inlineValidation-calcNeedsPanel").toggleClass("show", true);

            if (formData.current.email !== null && formData.current.email !== "") {
                var attrData = transformData("source", calcSource);
                formData = collectFormData($("#calculateNeeds-shortForm"), attrData);
            }
            sendData(formData, false);
        }
    }

    /**
     * _getGeneralEstimate
     *
     * @private
     */
    function _getGeneralEstimate() {
        var isValid;
        var $emailField = $("#calculateYourNeedsForm #email");
        var $salaryField = $("#calculateYourNeedsForm #salary");
        var form = $(this).closest("form");
        var button = $(this).closest("button");

        _validateAge(true);

        _estimationFormValid.salary = _validateSalary.call($salaryField);
        _estimationFormValid.emailFormat = _validateEmail.call($emailField);

        isValid = Object.keys(_estimationFormValid).every(function (key) {
            return _estimationFormValid[key];
        });

        var formData = collectFormData(form, button.data());
        sendData(formData, false);

        if (isValid) {
            _storeUserValues();
            _calculateAndDisplayEstimates();
        } else {
            $(".inlineValidation").toggleClass("show", true);
        }
    }

    /**
     * _calculateAndDisplayEstimates
     *
     * @private
     */
    function _calculateAndDisplayEstimates() {
        var user = _getUserData();
        var month = user.dobMonth;
        var day = user.dobDay;
        var year = user.dobYear;
        var salary = user.salary;
        var formattedCoverageAmount;
        var topOfExpand = $(".calculateYourNeeds-expand").offset().top;

        const age = ageLastBirthday(createDate(year, month, day), new Date());
        var estimatedTermLength = _calculateTermLength(age);

        $(".estimatedAge").text(age);

        salary = _stripDollarFormatting(salary);
        formattedCoverageAmount = _addDollarFormatting(Math.round(salary) * 7);
        $(".estimatedCoverageAmount").text(formattedCoverageAmount);

        if (estimatedTermLength === 0) {
            $(".suggestedCoverage").addClass("hide");
        } else {
            $(".estimatedTermLength").text(estimatedTermLength);
            $(".suggestedCoverage").removeClass("hide");
        }

        $(".calculateYourNeeds-expand").removeClass("hide");

        window.scrollTo(0, topOfExpand);
    }

    /**
     * _storeUserValues
     *
     * @private
     */
    function _storeUserValues() {
        var user = browserStorage.getObject("user");
        if (!user) {
            user = aaalife.data.user || {};
        }

        user.dobMonth = $("#dobMonth").val();
        user.dobDay = $("#dobDay").val();
        user.dobYear = $("#dobYear").val();
        user.salary = $("#salary").val();
        user.email = $("#email").val();

        aaalife.data.user = user;
        browserStorage.setObject("user", aaalife.data.user);
    }

    /**
     * _validateAge
     *
     * @private
     */
    function _validateAge(isSubmit) {
        var day = $("#dobDay").val();
        var month = $("#dobMonth").val();
        var year = $("#dobYear").val();
        var anyInvalid = false;

        [$("#dobDay"), $("#dobMonth"), $("#dobYear")].forEach(function ($el) {
            if ($el.val() === "" && ($el.hasClass("dirty") || isSubmit === true)) {
                $el.removeClass("valid").addClass("invalid");
                anyInvalid = true;
            } else if ($el.val() !== "") {
                $el.removeClass("invalid").addClass("valid");
            }
        });

        if (anyInvalid) {
            _estimationFormValid.birthdate = false;
        } else {
            _estimationFormValid.birthdate = true;
        }
    }

    /**
     * _calculateTermLength
     *
     * @param {int}
     *            age
     * @returns {int}
     * @private
     */
    function _calculateTermLength(age) {
        var termLength = 0;

        if (age >= 18 && age <= 35) {
            termLength = 30;
        } else if (age >= 36 && age <= 40) {
            termLength = 25;
        } else if (age >= 41 && age <= 45) {
            termLength = 20;
        } else if (age >= 46 && age <= 50) {
            termLength = 15;
        } else if (age >= 51 && age <= 75) {
            termLength = 10;
        }

        return termLength;
    }

    /**
     * _validateSalary
     *
     * @returns {boolean}
     * @private
     */
    function _validateSalary() {
        var isValid = true;
        var $input = $(this);
        var inputValue = $input.val();
        var salary;
        var formattedCoverageAmount;

        if (inputValue === "" || inputValue == "$") {
            isValid = false;
        }

        _estimationFormValid.salary = isValid;
        _highlightInvalidField(isValid, $input);

        return isValid;
    }

    /**
     * _validateEmail
     *
     * @returns {boolean}
     * @private
     */
    function _validateEmail() {
        var isValid;
        var $input = $(this);
        var email = $input.val();

        isValid = aaalife.utils.validate.emailFormat(email, false);
        _estimationFormValid.emailFormat = isValid;
        _highlightInvalidField(isValid, $input);

        return isValid;
    }

    /**
     * _hasValue
     *
     * @returns {boolean}
     * @private
     */
    function _hasValue() {
        var $el = $(this);
        var isValid = !aaalife.utils.validate.missingValue($el);

        _highlightInvalidField(isValid, $el);

        return isValid;
    }

    /**
     * _markDirty
     *
     * @private
     */
    function _markDirty() {
        $(this).removeClass("pristine").addClass("dirty");
    }

    /**
     * _highlightInvalidField
     *
     * @param isValid
     * @param $argElement
     * @private
     */
    function _highlightInvalidField(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            _showInlineError(false);
            $element.removeClass("invalid").addClass("valid");
        } else {
            _showInlineError(true);
            $element.removeClass("valid").addClass("invalid");
        }
    }

    /**
     * _showInlineError
     *
     * @param show
     * @private
     */
    function _showInlineError(show) {
        $(".inlineValidation").toggleClass("show", show);
    }

    /**
     * _calculateLooseEnds - calculate the subtotal for "Tie up loose ends" in
     * the detailed Calculate Your Needs form
     *
     * @private
     */
    function _calculateLooseEnds() {
        var $input = $("#looseEnds-input");
        var inputValue = $input.val();
        var percent = $("#looseEnds-percent").val() * 0.01;
        var total;
        var $totalDiv = $("#looseEnds-total");

        total = _stripDollarFormatting(inputValue) * percent;

        $totalDiv.html(_addDollarFormatting(Math.round(total)));
        _updateGrandTotal();
    }

    /**
     * _calculateClearSlate - calculate the subtotal for "Clear the slate" in
     * the detailed Calculate Your Needs form
     *
     * @private
     */
    function _calculateClearSlate() {
        var $input = $("#clearSlate-input");
        var inputValue = $input.val();
        var percent = $("#clearSlate-percent").val() * 0.01;
        var total;
        var $totalDiv = $("#clearSlate-total");

        total = _stripDollarFormatting(inputValue) * percent;

        $totalDiv.html(_addDollarFormatting(Math.round(total)));
        _updateGrandTotal();
    }

    /**
     * _calculateProvideFamily - calculate the subtotal for "Provide for your
     * family" in the detailed Calculate Your Needs form
     *
     * @private
     */
    function _calculateProvideFamily() {
        var $input = $("#provideFamily-input");
        var inputValue = $input.val();
        var years = $("#provideFamily-years").val() * 1.0;
        var total;
        var $totalDiv = $("#provideFamily-total");

        total = _stripDollarFormatting(inputValue) * 12.0 * years;

        $totalDiv.html(_addDollarFormatting(Math.round(total)));
        _updateGrandTotal();
    }

    /**
     * _calculateProtectHome - calculate the subtotal for "Protect your home" in
     * the detailed Calculate Your Needs form
     *
     * @private
     */
    function _calculateProtectHome() {
        var $input = $("#protectHome-input");
        var inputValue = $input.val();
        var percent = $("#protectHome-percent").val() * 0.01;
        var total;
        var $totalDiv = $("#protectHome-total");

        total = _stripDollarFormatting(inputValue) * percent;

        $totalDiv.html(_addDollarFormatting(Math.round(total)));
        _updateGrandTotal();
    }

    /**
     * _calculateKidsCollege - calculate the subtotal for "Send your kids to
     * college" in the detailed Calculate Your Needs form
     *
     * @private
     */
    function _calculateKidsCollege() {
        var $input = $("#kidsCollege-input");
        var inputValue = $input.val();
        var percent = $("#kidsCollege-percent").val() * 0.01;
        var numberOfKids = $("#kidsCollege-numberOfKids").val() * 1.0;
        var total;
        var $totalDiv = $("#kidsCollege-total");

        total = _stripDollarFormatting(inputValue) * percent * numberOfKids;

        $totalDiv.html(_addDollarFormatting(Math.round(total)));
        _updateGrandTotal();
    }

    /**
     * _calculateExtraMile - calculate the subtotal for "Go the extra mile" in
     * the detailed Calculate Your Needs form
     *
     * @private
     */
    function _calculateExtraMile() {
        var $input = $("#extraMile-input");
        var inputValue = $input.val();
        var $totalDiv = $("#extraMile-total");
        var total;

        total = _stripDollarFormatting(inputValue);

        $totalDiv.html(_addDollarFormatting(total));
        _updateGrandTotal();
    }

    /**
     * _calculateAlreadyComingIn - calculate the subtotal for "Subtract what's
     * already coming in" in the detailed Calculate Your Needs form
     *
     * @private
     */
    function _calculateAlreadyComingIn() {
        var $input = $("#alreadyComingIn-input");
        var inputValue = $input.val();
        var $totalDiv = $("#alreadyComingIn-total");
        var total;

        total = _stripDollarFormatting(inputValue);

        $totalDiv.html(_addDollarFormatting(total));
        _updateGrandTotal();
    }

    /**
     * _updateGrandTotal - calculate the grand total for the detailed Calculate
     * Your Needs form
     *
     * @private
     */
    function _updateGrandTotal() {
        var $grandTotalDiv = $("#calculateYourNeeds-grandTotal1, #calculateYourNeeds-grandTotal2");
        var looseEnds = _stripDollarFormatting($("#looseEnds-total").html()) * 1.0;
        var clearSlate = _stripDollarFormatting($("#clearSlate-total").html()) * 1.0;
        var provideFamily = _stripDollarFormatting($("#provideFamily-total").html()) * 1.0;
        var protectHome = _stripDollarFormatting($("#protectHome-total").html()) * 1.0;
        var kidsCollege = _stripDollarFormatting($("#kidsCollege-total").html()) * 1.0;
        var extraMile = _stripDollarFormatting($("#extraMile-total").html()) * 1.0;
        var alreadyComingIn = _stripDollarFormatting($("#alreadyComingIn-total").html()) * 1.0;

        var grandTotal = looseEnds + clearSlate + provideFamily + protectHome + kidsCollege + extraMile - alreadyComingIn;

        if (grandTotal < 0) {
            grandTotal = 0;
        }
        $grandTotalDiv.html(_addDollarFormatting(Math.round(grandTotal)));
    }

    /**
     * _stripDollarFormatting - remove $ and commas
     *
     * @param {string}
     * @returns {int}
     * @private
     */
    function _stripDollarFormatting(dollarString) {
        var dollarNumber = dollarString.replace("$", "").replace(/,/g, "");
        return dollarNumber;
    }

    /**
     * _addDollarFormatting - insert $ and commas
     *
     * @param {int}
     * @returns {string}
     * @private
     */
    function _addDollarFormatting(dollarNumber) {
        var dollarString = "$" + dollarNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return dollarString;
    }
}
