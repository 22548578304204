/**
 * @typedef {Object} ValidationError - A validation error
 * @property {Array.<string>} fields - The names of fields associated with the validation error
 * @property {string} message - The error message for debugging purposes
 * @property {string=} knockout - The knockout error code used to identify the knockout user experience to use
 */

/**
 * @param {ValidationError} error - The validation error fields
 * @returns {ValidationError} - The resulting error object
 */
export function createValidationError({ message, fields, knockout }) {
    return {
        fields: [...fields],
        message: message,
        knockout: knockout,
    };
}
