import $ from "jquery";

export function aaalifeFooterSearch() {
    $(document).ready(function () {
        $(".btn-search, .footer-searchBtn").click(function OnSearchClick() {
            var input = $(this).parents("form").find('[name="q"]');

            if (input.val() == "") {
                input.addClass("error");
                return false;
            }
        });
    });
}
