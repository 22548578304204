import $ from "jquery";
import "whatwg-fetch";
import "classlist-polyfill";

export function clubProfileLookup(zip, cmp, lead, clubCode) {
    let params = "?";

    if (zip != undefined && zip != "") {
        params += "zip=" + zip;
    }
    if (cmp != undefined && cmp != "") {
        params += "&cmp=" + cmp;
    }
    if (clubCode != undefined && clubCode != "") {
        params += "&clubCode=" + clubCode;
    }
    if (lead != undefined && lead != "") {
        params += "&lead=" + lead;
    }

    return fetch(aaalife.ctx + "restservices/data/clubprofile" + params).then((response) => {
        return response.json();
    });
}

export function extendUtils(utilsToExtend) {
    var preScroll = 0;
    var _modalInitRan = false;
    var _inlineModalInitRan = false;

    utilsToExtend = $.extend({}, utilsToExtend, {
        togglePreventScroll: togglePreventScroll,
        modalInit: modalInit,
        inlineInit: inlineInit,
        getClass: getClass,
        formatDollars: formatDollars,
        clubProfileLookup: clubProfileLookup,
    });
    return utilsToExtend;

    /**
     * aaalife.utils.togglePreventScroll - toggles the browser's scrollability
     * (usually when the mobile menu opens/closes)
     *
     * @param {boolean}
     *            activate - whether to active the scroll prevention or not
     */
    function togglePreventScroll(activate) {
        if (typeof activate === "undefined") {
            activate = false;
        }

        if (activate) {
            preScroll = $(window).scrollTop();
        }

        $("body, html").toggleClass("prevent-scroll", activate);

        if (!activate) {
            $(window).scrollTop(preScroll);
        }
    }

    /**
     * aaalife.utils.modalInit - initializes modal functionality from data
     * attributes on the elements Notes - in the HTML you can add data-modal
     * attribute, this is a CSS-style selector relative to the clicked element.
     * You can use a parent selector "<" to traverse up the DOM tree to get the
     * correct context for the selector. This attaches the modal actions to the
     * element with the data attribute.
     */
    function modalInit() {
        if (!_modalInitRan) {
            $("[data-modal]").each(_initModalOrInline);
            _modalInitRan = true;
        }
    }

    /**
     * aaalife.utils.inlineInit - initializes the "inline" (modal-like)
     * functionality from data attributes on the elements Notes - in the HTML
     * you can add data-inline attribute, this is a CSS-style selector relative
     * to the clicked element. You can use a parent selector "<" to traverse up
     * the DOM tree to get the correct context for the selector. This attaches
     * the "inline-modal" actions to the element with the data attribute.
     */
    function inlineInit() {
        if (!_inlineModalInitRan) {
            $("[data-inline]").each(_initModalOrInline);
            _inlineModalInitRan = true;
        }
    }

    /**
     * _initModalOrInline
     *
     * @private
     */
    function _initModalOrInline() {
        var $el = $(this);
        var type = $el.attr("data-modal") ? "Modal" : "Inline";
        var selector = $el.data(type.toLowerCase());
        var className = $el.data("modal-class") || null;
        var styles = $el.data("styles") || null;
        var $content;
        var modal;

        if (selector) {
            $content = _getContentElement(selector, $el);
        }

        if ($content.length) {
            modal = new utilsToExtend[type]({
                content: $content,
                className: className,
                styles: styles,
                triggerEl: $el,
            });

            $el.on("click", function (e) {
                e.preventDefault();
                modal.open();
            });
        }
    }

    /**
     * _getContentElement
     *
     * @param selector
     * @param $el
     * @returns {*|{}}
     * @private
     */
    function _getContentElement(selector, $el) {
        var $ancestors;
        var $context;

        if (selector.match(/[<]/gim)) {
            $ancestors = $el.parentsUntil("html");
            $context = $ancestors.eq(selector.match(/[<]/gim).length - 1);

            if (!$context && $context.length) {
                $context = $("body");
            }

            return $context.find(selector.replace(/[<]/gim, ""));
        } else {
            return $el.find(selector);
        }
    }

    /**
     * getClass - get real type of JavaScript object
     *
     * @param object
     * @returns {string}
     */
    function getClass(object) {
        return Object.prototype.toString.call(object).slice(8, -1).toLowerCase();
    }

    /**
     * formatDollars - formats a number by adding $ and separators
     *
     * @param {number}
     *            dollars - dollar value to format
     * @param {=string}
     *            separator - (optional) defaults to comma
     * @return {string} - formatted dollar string
     */
    function formatDollars(dollars, sep) {
        var parts = dollars.toString().split(".");
        var separator = sep || ",";

        parts[0] = parts[0]
            .toString() // make it a string
            .replace(/\D*/g, "") // replace non-numbers
            .replace(/\B(?=(\d{3})+(?!\d))/g, separator); // insert commas

        dollars = "$" + parts.join(".");

        return dollars;
    }
}
