import $ from "jquery";

export function dataCollapse() {
    $("[data-collapse]").click(function (e) {
        e.preventDefault();
        var key = $(this).data("collapse");
        $(this).toggleClass("collapsed");
        $('[data-collapsible="' + key + '"]').toggleClass("hide");
    });
}
