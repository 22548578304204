export function setCookie(c_name, value, expiredays, makeSecure) {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);

    const hostNameWithoutSubdomain = document.location.hostname.replace(/^.+(\.[^.]+\.[^.]+$)/, `$1`);
    let cookieString =
        c_name +
        "=" +
        escape(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toUTCString()) +
        ";path=/;domain=" +
        hostNameWithoutSubdomain;

    if (makeSecure) cookieString += ";secure";

    document.cookie = cookieString;
}

export function getCookie(c_name) {
    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            let c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) c_end = document.cookie.length;
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

export function trimField(fld) {
    // removes leading and trailing blanks from fld
    var res = "";
    var c = 0;
    for (let i = 0; i < fld.length; i++) {
        if (fld.charAt(i) != " " || c > 0) {
            res += fld.charAt(i);
            if (fld.charAt(i) != " ") c = res.length;
        }
    }
    return res.substr(0, c);
}
