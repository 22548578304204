import $ from "jquery";
import { browserStorage } from "./aaalife-utils-browser-storage";

/**
 * init - initialization function for AAALife website JavaScript
 *
 * @return {void} - no return
 */
export function init() {
    // initialize foundation

    $(document).foundation();

    var user = browserStorage.getObject("user");
    var quote = browserStorage.getObject("quote");

    if (!user) {
        user = aaalife.data.user || {};
    }
    if (!user.zip && aaalife.fromServer.zip) {
        aaalife.data.user.zip = aaalife.fromServer.zip;
        browserStorage.setObject("user", aaalife.data.user);
    }

    if (!quote) {
        quote = aaalife.data.quote || {};
    }
    if (aaalife.fromServer.quoteType) {
        aaalife.data.quote.type = aaalife.fromServer.quoteType;
        browserStorage.setObject("quote", aaalife.data.quote);
    }
}
