export function getClubCodeFromZip(zip) {
    if (!zip) {
        return Promise.resolve("");
    }
    return new Promise(function (resolve, reject) {
        $.ajax({
            url: "/services/cds-services/clubCode",
            contentType: "application/json",
            dataType: "json",
            type: "GET",
            data: {
                zipCode: zip,
            },
            success: function (data) {
                resolve(data);
            },
            error: function (data) {
                console.error("Call for clubCode failed in utils-validate.");
                reject("");
            },
        });
    });
}
