import { setCookie } from "../agentQuoter/clubUtils";
import { browserStorage } from "../giwl-enrollment/aaalife-utils-browser-storage";
import { clubProfileLookup } from "../giwl-enrollment/aaalife-utils-extend";
import { getClubCodeFromZip } from "../utils/club-code-service";
import { updateDynamicDomElements } from "./dynamic-dom";

// HACK: Prevents race conditions where an earlier lookup of a profile resolves after a subsequent lookup
// causing the dom updates to use the wrong value.
let profileQueue = Promise.resolve();

// TODO: Pass in updatePhoneDomElements instead since there seems to be some differences for GIWL this way top level
// script can inject the right dependency. Same as for getClubCodeFromZip where there are two definitions, one for GIWL
// and one for non-GIWL. We should make those implementations conditional pass these in as dependencies

export function updateDynamicPhone(profile) {
    setGlobalState(profile);
    updateDynamicDomElements(profile, document, Array.from(document.querySelectorAll("body, body *")));
}

export function getClubAndCampaignProfile(zip, cmp, lead, clubCode = "") {
    profileQueue = profileQueue.then(async () => {
        // Lookup club code by zip if we don't have it already but have a zip otherwise just carry on and lookup the
        // profile with blank values for both.
        if (!clubCode && !!zip) {
            clubCode = (await getClubCodeFromZip(zip)) || "";
            setCookie("cc", clubCode, 30);
        }

        return await clubProfileLookup(zip, cmp, lead, clubCode);
    });

    return profileQueue;
}

export function setGlobalState(clubAndCampaignProfile) {
    browserStorage.setObject("clubProfileData", clubAndCampaignProfile);
    const promise = Promise.resolve(clubAndCampaignProfile);
    // HACK: Overwrite this at a global level for access by other modules for now. Later move assignment to
    // entrypoint files as part of initialization.
    if (!window.aaalife.promises) {
        window.aaalife.promises = {};
    }
    window.aaalife.promises.clubProfileDataPromise = promise;
}
