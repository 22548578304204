import $ from "jquery";
import { allToString, appendData, collectFormData, sendData } from "../collector";
import { getClubAndCampaignProfile, updateDynamicPhone } from "../utils/aaalife-dynamic-phone";
import { browserStorage } from "./aaalife-utils-browser-storage";

export function aaalifeHaveQuestionsInit() {
    var zipID = "#zipCode";

    if ($("#haveQuestionsForm").length > 0) {
        $(init);
    }

    function init() {
        $(zipID).on("blur", _removeNonDigits);
        $("#haveQuestionsForm").on("submit", _validateHaveQuestionsForm);
        _populateZipFromStorage();
    }

    function _removeNonDigits() {
        var $zip = $(zipID);
        var zip = $zip.val();

        $(zipID).val(zip.replace(/\D/g, ""));
    }

    function _validateHaveQuestionsForm(e) {
        var $zip = $(zipID);
        var zip = $zip.val();
        var itc = $("#haveQuestionsForm").data("itc");
        var attrData = $("#haveQuestionsForm").data();

        if (_checkZip()) {
            attrData = appendData(attrData, "itc", itc);
            attrData = allToString(attrData);
            sendData(collectFormData($("#haveQuestionsForm"), attrData), false);
            getClubAndCampaignProfile(zip)
                .then(updateDynamicPhone)
                .catch((error) => console.error("Error updating phone", error));
        } else {
            e.preventDefault();
            _highlightInvalidField(false, $zip);
        }
    }

    function _populateZipFromStorage() {
        var user = browserStorage.getObject("user");

        if (!user) {
            user = aaalife.data.user || {};
        }
        if (user && Object.keys(user).length > 0) {
            $(zipID).val(user.zip);
        }
    }

    function _checkZip() {
        var validData;
        var $zip = $(zipID);
        var zip = $zip.val();
        var valueMissing = aaalife.utils.validate.missingValue($zip);
        var validZip = aaalife.utils.validate.zipFormat(zip);

        zip = $zip.val(zip.replace(/[^\d\-].+/, "")).val();
        validData = !valueMissing && validZip;

        return validData;
    }

    function _highlightInvalidField(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            _showInlineError(false);
            $element.removeClass("invalid").addClass("valid");
        } else {
            _showInlineError(true);
            $element.removeClass("valid").addClass("invalid");
        }
    }

    function _showInlineError(show) {
        $(".inlineValidationHaveQuestions").toggleClass("show", show);
    }
}
