import $ from "jquery";
import { browserStorage } from "./aaalife-utils-browser-storage";

export function emailFormInit() {
    var _emailFormValid = {
        emailFormat: false,
    };

    if ($(".emailForm").length > 0) {
        $(init);
    }

    /**
     * init - initialize email form JS
     *
     * @return {void} void
     */
    function init() {
        aaalife.utils.modalInit();

        initEmailForm();
    }

    /**
     * initEmailForm -
     *
     * @return {void} - returns void
     */
    function initEmailForm() {
        var $emailField = $("#emailQuote-email");
        var $sendEmailButton = $(".emailForm .sendButton");

        $(document).on("modal-content-ready", _initOnModalOpen);

        $emailField.on("blur", _checkEmail);
        $("#emailQuoteForm").on("submit", _validateEmail);

        function _initOnModalOpen(e, modal) {
            var user = browserStorage.getObject("user");
            if (!user) {
                user = aaalife.data.user || {};
            }

            // If there's a saved email, pre-populate the email field
            if (user && user.email) {
                $emailField.val(user.email);
            }

            $(".formPanel").removeClass("hide");
            $(".confirmationPanel").addClass("hide");
        }
    }

    /**
     * _validateEmail - validate all required fields; send if all are valid
     *
     * @return {void} - returns void
     */
    function _validateEmail(e) {
        var isValid;
        var $emailField = $("#emailQuote-email");

        e.preventDefault();
        _emailFormValid.emailFormat = _checkEmail.call($emailField);

        isValid = Object.keys(_emailFormValid).every(function (key) {
            return _emailFormValid[key];
        });

        if (isValid) {
            sendEmail();
        } else {
            // form is invalid, display errors and allow user to fix

            $(".inlineValidation").toggleClass("show", true);
        }
    }

    /**
     * sendEmail - send the current quote to the given email address
     *
     * @return {void} - returns void
     */
    function sendEmail() {
        // TODO: actually send an email

        // show the confirmation panel
        $(".formPanel").addClass("hide");
        $(".confirmationPanel").removeClass("hide");
    }

    /**
     * _checkEmail
     *
     * @returns {boolean|*}
     * @private
     */
    function _checkEmail() {
        var isValid;
        var $input = $(this);
        var email = $input.val();

        isValid = aaalife.utils.validate.emailFormat(email, true);
        _emailFormValid.emailFormat = isValid;
        _highlightInvalidField(isValid, $input);

        return isValid;
    }

    /**
     * _highlightInvalidField
     *
     * @param isValid
     * @param $argElement
     * @private
     */
    function _highlightInvalidField(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            _showInlineError(false);
            $element.removeClass("invalid").addClass("valid");
        } else {
            _showInlineError(true);
            $element.removeClass("valid").addClass("invalid");
        }
    }

    /**
     * _showInlineError
     *
     * @param show
     * @private
     */
    function _showInlineError(show) {
        $(".inlineValidation").toggleClass("show", show);
    }
}
