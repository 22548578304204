import $ from "jquery";
import { appendData, sendData, sendDataAsync, transformData } from "../collector";
import { Foundation, Slider, Motion, Triggers, Keyboard, Touch } from "foundation-sites";
import { getQuoteResultsData } from "./aaalife-utils-get-data";
import { browserStorage } from "./aaalife-utils-browser-storage";
import { AggregationClient } from "../react-components/quote-form-small-footprint/utils/aggregation-client";

export function quoterResultsFactory() {
    var cmsInternal = "_cmsinternal";

    var results;

    var coverageValues = [];

    var minPixels = 60;
    var maxPixels = 120;

    var quote;

    var quoteResultsPageLoad = true;
    const contactInfoBeforeQuote = window.document.location.search.indexOf("initial_form") >= 0;

    $(init);

    return {
        init: init,
    };

    /**
     * init - initialize quote results JS
     *
     * @return {void} void
     */
    function init() {
        _populateUserEmailIfSaved();
        // set quote variable
        quote = browserStorage.getObject("quote");
        if (!quote) {
            quote = aaalife.data.quote || {};
        }

        if ($(".quoteResults").length > 0) {
            if (quote.results === "" || quote.results === undefined) {
                quote.results = aaalife.fromServer.quoteResultsString;
            }
            aaalife.data.quote = quote;

            var path = $(location).attr("href");

            /*
             * the Below if statement is used to prevent the cms from loading the data when there is no data to load
             * this prevents an error page from being displayed in the cms.
             */
            if (path.indexOf(cmsInternal) === -1) {
                getQuoteResultsData(quote.results).then(_quoteDataReady);
            }
        }
        initBackButtons();
    }

    function _populateUserEmailIfSaved() {
        var user = browserStorage.getObject("user");
        if (!user) {
            user = aaalife.data.user || {};
        }
        aaalife.data.user = user;

        $("#contact_email").val(user.email);
    }

    /*	function _getUserData() {
		var user = browserStorage.getObject('user');
		if (!user) {
			user = aaalife.data.user || {};
		}
		return user;
	}*/

    /**
     * _quoteDataReady - execute when quote data is returned
     *
     * @param data
     * @private
     */
    function _quoteDataReady(data) {
        var $coverageSlider = $(".quoteResults-slider .slider");
        var $barGraphWrapper = $(".quoteResults-graph-wrapper");

        results = data;

        if (!results) {
            _redirectToQuoteNotAvailablePage();
        }

        if ($coverageSlider.length) {
            initCoverageSlider($coverageSlider);
            $coverageSlider.on("moved.zf.slider", updateCoverageAmount);
        }
        if ($barGraphWrapper.length) {
            initTermBarsAndButtons();
        }

        initDisplayBasedOnInsuranceType();
    }

    function _redirectToQuoteNotAvailablePage() {
        window.location = "unexpected-error";
    }

    /**
     * initCoverageSlider - create the slider; map slider steps to coverage
     * amounts; display labels
     *
     * @param {Object}
     *            $coverageSlider - jQuery element in which to create the slider
     * @return {void} - returns void
     */
    function initCoverageSlider($coverageSlider) {
        var $endpointLow = $(".quoteResults-endpoints .endpoint-low");
        var $endpointHigh = $(".quoteResults-endpoints .endpoint-high");
        if (!results.premiumsForPolicyPeriods) {
            _redirectToQuoteNotAvailablePage();
        }
        var numberOfSteps = results.premiumsForPolicyPeriods[0].basePremiumAmounts.length;
        var lowEnd = results.premiumsForPolicyPeriods[0].basePremiumAmounts[0].coverageAmount.toLocaleString();
        var highEnd = results.premiumsForPolicyPeriods[0].basePremiumAmounts[numberOfSteps - 1].coverageAmount;
        var coverageAmount;
        var sliderStart = -1;
        var i;

        // label the endpoints
        $endpointLow.text("$" + addCommas(lowEnd));
        $endpointHigh.text("$" + addCommas(highEnd));

        // is there a saved coverage amount?
        if (quote && quote.coverage) {
            coverageAmount = quote.coverage.replace("$", "").replace(/,/g, "") * 1;
        }

        // populate the array mapping slider position to coverage amounts
        for (i = 0; i < numberOfSteps; i++) {
            coverageValues[i] = results.premiumsForPolicyPeriods[0].basePremiumAmounts[i].coverageAmount * 1;
            if (coverageValues[i] === coverageAmount) {
                sliderStart = i;
            }
        }

        // if larger than the range, set sliderStart to max, otherwise to center
        if (sliderStart === -1) {
            if (coverageAmount > coverageValues[coverageValues.length - 1]) {
                sliderStart = coverageValues.length - 1;
            } else {
                sliderStart = numberOfSteps / 2;
            }
        }

        // create the slider and set initial values
        var $slider = new window.Foundation.Slider($coverageSlider, {
            start: 0,
            end: numberOfSteps - 1,
            initialStart: sliderStart, // this will be dynamic, based on what
            // user selected on previous screen
            decimal: 0,
            step: 1,
        });
    }

    /**
     * initTermBarsAndButtons - show the correct number of bars according to the
     * results data
     *
     * @return {void} - returns void
     */
    function initTermBarsAndButtons() {
        var $barGraphWrapper = $(".quoteResults-graph-wrapper");
        var numberOfBars = results.premiumsForPolicyPeriods.length;
        var templateColumn = $("#qrGraphColumn").html();
        var quoteType;
        var $productName = $(".quoteResults-productName");
        var $col;
        var termYears;
        var selectedTerm;
        var i;
        var savedTerm;
        var selectedTermIndex = 0;

        $barGraphWrapper.find(".quoteResults-graph-column").remove(); // ensure
        // any
        // columns
        // inside
        // barGraphWrapper
        // are
        // gone
        // is there a saved term length?
        if (quote) {
            if (quote.term) {
                savedTerm = quote.term;
            }
            if (quote.type) {
                quoteType = quote.type;
            }
        }

        for (i = 0; i < numberOfBars; i++) {
            $col = $(templateColumn);

            termYears = results.premiumsForPolicyPeriods[i].period;
            $col.find(".term span").text(termYears);

            if (termYears == savedTerm) {
                selectedTermIndex = i;
            }

            $barGraphWrapper.append($col);
        }

        $(".term").eq(selectedTermIndex).addClass("selected");
        $(".term").on("click", selectTerm);

        selectedTerm = $(".term.selected").find("span").text();
        $(".summaryTerm").text(selectedTerm);

        if (quoteType == "express_term") {
            $productName.text(aaalife.fromServer.expressTerm.productName);
        } else if (quoteType == "traditional_term") {
            $productName.text(aaalife.fromServer.traditionalTerm.productName);
        } else {
            $productName.text("TODO");
        }
    }

    /**
     * initDisplayBasedOnInsuranceType - itinialize the button text depending on
     * insurance type
     *
     * @return {void} - returns void
     */
    function initDisplayBasedOnInsuranceType() {
        var $quoteFormComponentProductTitle = $(".formCmpnt-title");
        var $quoteFormComponentProductContent = $(".formCmpnt-copy");
        var $moreCoverageLink = $(".quoteResults-moreCoverageLink");
        var $applyButton = $(".applyButton");
        var $policyFormSeries = $(".formCmpnt-form-disclaimer");

        if (quote.type == "express_term") {
            $quoteFormComponentProductTitle.text(aaalife.fromServer.expressTerm.title);
            $quoteFormComponentProductContent.text(aaalife.fromServer.expressTerm.content);
            $moreCoverageLink.removeClass("hide");
            $applyButton.html(aaalife.fromServer.expressTerm.buttonText);
            $policyFormSeries.text(aaalife.fromServer.expressTerm.policyFormSeries);
        } else if (quote.type == "traditional_term" && !contactInfoBeforeQuote) {
            $quoteFormComponentProductTitle.text(aaalife.fromServer.traditionalTerm.title);
            $quoteFormComponentProductContent.text(aaalife.fromServer.traditionalTerm.content);
            $moreCoverageLink.addClass("hide");
            $applyButton.html(aaalife.fromServer.traditionalTerm.buttonText);
            $policyFormSeries.text(aaalife.fromServer.traditionalTerm.policyFormSeries);
        } else {
            $quoteFormComponentProductTitle.text(aaalife.fromServer.traditionalTermContactBeforeQuote.title);
            $quoteFormComponentProductContent.text(aaalife.fromServer.traditionalTermContactBeforeQuote.content);
            $moreCoverageLink.addClass("hide");
            $applyButton.html(aaalife.fromServer.traditionalTermContactBeforeQuote.buttonText);
            $policyFormSeries.text(aaalife.fromServer.traditionalTermContactBeforeQuote.policyFormSeries);
        }

        $applyButton.on("click", _quoteResultsCallToActionClicked);
    }

    function initBackButtons() {
        $(".combinedForm-backBtn-results").on("click", _quoteResultsBackClicked);
        $(".combinedForm-backBtn-apply").on("click", _applyNowBackClicked);
    }

    function removeNonNumericChars(premValue) {
        if (premValue != null) {
            return premValue.replace(/[^0-9.]/g, "");
        }
    }

    /**
     * _quoteResultsCallToActionClicked - store the selected quote values in
     * browser storage and redirect to the Apply Now page
     *
     * @return {void} - returns void
     */
    function _quoteResultsCallToActionClicked() {
        // //Look at url string
        // window.location.href
        //
        // //parse and iterate through url parameters
        // string s = regex("?").split
        // for(){s[i]}
        //
        // //Look for string key
        // if(s[] == lead=club)
        //
        // //indicate it existed or store in a variable and append that variable
        // at the end
        // lead existed; true
        // st = "?lead=club"
        // window..href + "?"st

        aaalife.data.quote.term = $(".summaryTerm").text(); // unformatted: e.g.
        // '20'
        aaalife.data.quote.coverage = $(".summaryCoverage").text(); // with
        // comma
        // only:
        // e.g.
        // '50,000'
        aaalife.data.quote.premium = $(".summaryPremium").text();
        aaalife.data.quote.termValues = $(".bar-label").text();
        aaalife.data.quote.alltermValues = aaalife.data.quote.termValues.replace("per", "");
        aaalife.data.quote.alltermValues = aaalife.data.quote.alltermValues.split(" ").join("").split("month");
        aaalife.data.quote.firstvalue = aaalife.data.quote.alltermValues[0];
        aaalife.data.quote.secondvalue = aaalife.data.quote.alltermValues[1];
        aaalife.data.quote.thirdvalue = aaalife.data.quote.alltermValues[2];
        aaalife.data.quote.fourthvalue = aaalife.data.quote.alltermValues[3];
        aaalife.data.quote.fifthvalue = aaalife.data.quote.alltermValues[4];

        browserStorage.setObject("quote", aaalife.data.quote);

        var currentData = {};
        currentData = appendData(currentData, "termLength", aaalife.data.quote.term);
        currentData = appendData(currentData, "premiumAmount", removeNonNumericChars(aaalife.data.quote.premium));
        currentData = appendData(currentData, "coverageAmount", aaalife.data.quote.coverage);
        currentData = appendData(currentData, "premiumAmountTT10Year", removeNonNumericChars(aaalife.data.quote.firstvalue));
        currentData = appendData(currentData, "premiumAmountTT15Year", removeNonNumericChars(aaalife.data.quote.secondvalue));
        currentData = appendData(currentData, "premiumAmountTT20Year", removeNonNumericChars(aaalife.data.quote.thirdvalue));
        currentData = appendData(currentData, "premiumAmountTT30Year", removeNonNumericChars(aaalife.data.quote.fourthvalue));
        var formData = {};
        formData = appendData(formData, "current", currentData);

        if (aaalife.data.quote.type == "express_term") {
            var data = transformData("premiumAmount", $(".summaryPremium").text());
            data = appendData(data, "coverageAmount", $(".summaryCoverage").text());
            data = appendData(data, "termLength", $(".summaryTerm").text());
            data = appendData(data, "premiumAmount10Year", removeNonNumericChars(aaalife.data.quote.firstvalue));
            data = appendData(data, "premiumAmount15Year", removeNonNumericChars(aaalife.data.quote.secondvalue));
            data = appendData(data, "premiumAmount20Year", removeNonNumericChars(aaalife.data.quote.thirdvalue));
            data = appendData(data, "premiumAmount25Year", removeNonNumericChars(aaalife.data.quote.fourthvalue));
            data = appendData(data, "premiumAmount30Year", removeNonNumericChars(aaalife.data.quote.fifthvalue));
            data = appendData(data, "expressTermProductCode", aaalife.data.quote.term + "X13");
            data = appendData(data, "quoteOriginPage", aaalife.data.quote.origin);

            aaalife.utils.expressTerm.zipReturnState();

            var groupData = transformData("current", data);
            sendData(groupData, false);
            var etAction = transformData("action", "toET");
            var etData = transformData("attr", etAction);
            sendData(etData, false);
            aaalife.utils.expressTerm.appendHiddenFormData("#quote-results");
            $("#quote-results").submit();
        } else {
            var attrData = {};
            attrData = appendData(attrData, "specialAction", "sf-p");
            formData = appendData(formData, "attr", attrData);
            var itc = $("#quoteResultsApplyNow").data("itc");
            if (itc != "" && itc !== undefined) {
                attrData = appendData(attrData, "itc", itc);
                formData = appendData(formData, "attr", attrData);
            }

            sendDataAsync(formData).then(function () {
                window.location.href = aaalife.fromServer.traditionalTerm.submitLocation;
            });
        }
    }

    /**
     * updateCoverageAmount - handler for slider moved event. Displays coverage
     * amount corresponding to slider position.
     *
     * @return {void} - returns void
     */
    function updateCoverageAmount() {
        var sliderValue = $(".quoteResults-slider").find("input[type=hidden]").val(); // range is 0 to (number of steps)
        var coverageValue;
        var premium;

        adjustBars(sliderValue);

        coverageValue = coverageValues[sliderValue];
        $(".quoteResults-coverageAmount span, .summaryCoverage").text(addCommas(coverageValue));

        premium = $(".term.selected").closest(".column").find(".bar-label span").text();
        $(".summaryPremium").text(premium);

        aaalife.data.quote.coverage = addCommas(coverageValue);
        browserStorage.setObject("quote", aaalife.data.quote);
        if (quoteResultsPageLoad) {
            savePremiumValues().then((response) => {
                quoteResultsPageLoad = false;
            });
        }
    }

    function savePremiumValues() {
        var termValues = $(".bar-label span").text();
        if (termValues != null) {
            termValues = termValues.replace(/\s/g, "");
        }
        var alltermValues = termValues.split("$").filter((x) => x);

        const aggregationClient = new AggregationClient({
            panelOne: aaalife.fromServer.quoteFormParameters?.panelOneInteractionTypeCode,
            panelTwo: aaalife.fromServer.quoteFormParameters?.panelTwoInteractionTypeCode,
            panelThree: aaalife.fromServer.quoteFormParameters?.panelThreeInteractionTypeCode,
            panelFour: aaalife.fromServer.quoteFormParameters?.panelFourInteractionTypeCode,
            quoteResults: aaalife.fromServer.quoteFormParameters?.quoteInteractionTypeCode,
        });

        var summaryPremium = $(".summaryPremium").text();
        let selectedPremium;
        if (summaryPremium !== undefined) {
            selectedPremium = summaryPremium.replace("$", "");
        }

        return aggregationClient
            .reportQuoteResultVisit(alltermValues, selectedPremium, aaalife.data.quote.type)
            .then((response) => {
                if (contactInfoBeforeQuote) {
                    aggregationClient.reportFullContactLead();
                }
            })
            .catch((error) => console.error(error));
    }

    /**
     * adjustBars - adjust the heights of the bar graph elements as coverage
     * amount slider is changed.
     *
     * @param {int}
     *            sliderValue - slider position (0 to number of steps)
     * @return {void} - returns void
     */
    function adjustBars(sliderValue) {
        var numberOfBars = results.premiumsForPolicyPeriods.length;
        var $termColumns = $(".quoteResults-graph-column");
        var minHeight = minPixels;
        var maxHeight = maxPixels;
        var amount;
        var i;
        var numberOfPolicyPeriods = results.premiumsForPolicyPeriods.length;
        var numberOfCoverageValues = results.premiumsForPolicyPeriods[0].basePremiumAmounts.length;
        var minPremium;
        var maxPremium;
        var scaleFactor;

        minPremium = results.premiumsForPolicyPeriods[0].basePremiumAmounts[0].totalPremiumAmount;
        maxPremium =
            results.premiumsForPolicyPeriods[numberOfPolicyPeriods - 1].basePremiumAmounts[numberOfCoverageValues - 1]
                .totalPremiumAmount;

        scaleFactor = (maxPixels - minPixels) / maxPremium;
        for (i = 0; i < numberOfBars; i++) {
            amount = results.premiumsForPolicyPeriods[i].basePremiumAmounts[sliderValue].totalPremiumAmount.toFixed(2);
            $termColumns
                .eq(i)
                .find(".bar-label span")
                .text("$" + amount);

            var barHeight = minHeight + (amount - minPremium) * scaleFactor;
            if (barHeight > maxHeight) {
                barHeight = maxHeight;
            }
            $termColumns
                .eq(i)
                .find(".bar")
                .css("height", barHeight + "px");
        }
    }

    /**
     * selectTerm - handler for term button clicked event. Displays number of
     * years and corresponding premium amount.
     *
     * @param {Object}
     *            e - jQuery event object
     * @return {void} - returns void
     */
    function selectTerm(e) {
        var termYears = $(this).find("span").text();
        var premium = $(this).closest(".column").find(".bar").find("span").text();

        e.preventDefault();

        $(".quoteResults-graph-column .term").removeClass("selected");
        $(this).addClass("selected");

        $(".summaryTerm").text(termYears);
        $(".summaryPremium").text(premium);

        // For ET, update 'product' field so it can be saved to couchbase
        $("#timsThingID").val(termYears);

        aaalife.data.quote.term = termYears;
        browserStorage.setObject("quote", aaalife.data.quote);
    }

    /**
     * addCommas - format number by inserting commas for thousands
     *
     * @param {number}
     *            nStr - input number (integer or floating point)
     * @return {string} - formatted number copied from
     *         http://www.mredkj.com/javascript/numberFormat.html
     */
    function addCommas(nStr) {
        // TODO: move this to utils?
        nStr += "";
        const x = nStr.split(".");
        let x1 = x[0];
        const x2 = x.length > 1 ? "." + x[1] : "";
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, "$1" + "," + "$2");
        }
        return x1 + x2;
    }

    function _quoteResultsBackClicked(e) {
        var flag = quote.panelFlag;
        var location = quote.origin;
        e.preventDefault();

        quote.estimatedNeed = quote.coverage;
        aaalife.data.quote = quote;
        browserStorage.setObject("quote", quote);

        if (flag == "true") {
            $("#panelBackForm").attr("action", location);
            $("#panelBackForm").submit();
        } else {
            window.location = location;
        }
    }

    function _applyNowBackClicked(e) {
        var location = aaalife.fromServer.urlStrings.resultsPage;
        e.preventDefault();

        aaalife.data.quote = quote;
        browserStorage.setObject("quote", quote);

        window.location = location;
    }
}
