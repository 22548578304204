export function isEmailAddressFormatValid(emailAddress) {
    if (!emailAddress) {
        return false;
    }
    const EMAIL_REGEXP = /^(([\//'/*/`/|a-zA-Z0-9!#$%&+\-=?^_{}~])+\.?)+[^\.\s]@(([a-zA-Z0-9\-]+\.)+[a-z]+)$/;
    return EMAIL_REGEXP.test(emailAddress);
}

export function isEmailAddressFormatValidForFast(emailAddress) {
    if (!emailAddress) {
        return false;
    }
    const EMAIL_REGEXP =
        /^[a-zA-Z0-9!#$%'*\-_+=?^`{|}~]+(?:\.[a-zA-Z0-9!#$%&'*+\=?^_`{|}~\-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])$/;
    return EMAIL_REGEXP.test(emailAddress);
}
