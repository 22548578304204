import { render as renderOrganicLandingPage } from "./expressTerm2/render-et-organic-landing-page";
import {
    attachDataLayerAutoPush,
    createDataLayerEventFromAttributes,
    getAllDecscendentFieldsWithDataLayerEventAttribute,
} from "./utils/datalayer-auto-push";

export function onDocumentReady() {
    // Make any dynamic changes to the HTML DOM here regardless of component but  make sure to gracefully handle scenarios where
    // your expected DOM elements are not present.

    renderOrganicLandingPage();

    attachDataLayerAutoPush({
        rootElement: document.body,
        eventFactory: createDataLayerEventFromAttributes,
        fieldSelector: getAllDecscendentFieldsWithDataLayerEventAttribute,
    });
}
