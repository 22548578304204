import React, { useCallback, useEffect, useReducer } from "react";
import { ageLastBirthday, createDate } from "../utils/age";
import { isEmailAddressFormatValid } from "../../../utils/email-format-validation";
import { Tooltip } from "../components/Tooltip";
import { useReactivateEffect } from "../hooks/ReactivateEffect";
import { yesNoToBooleanOrUndefined } from "../utils/value-translators";

const FIELDS = {
    ZIP: "zip",
    GENDER: "gender",
    YEAR: "dobYear",
    MONTH: "dobMonth",
    DAY: "dobDay",
    IS_MEMBER: "isMember",
    EMAIL: "email",
};

const requiredFields = Object.keys(FIELDS).filter((field) => FIELDS[field] !== FIELDS.EMAIL);

const CHANGED_FIELD_VALUE = "CHANGED_FIELD_VALUE";
const CHANGE_PRIMIARY_HOME_LOCATION = "CHANGE_PRIMIARY_HOME_LOCATION";
const CHANGED_LOCATION_RESTRICTIONS = "CHANGED_LOCATION_RESTRICTIONS";
const CUSTOMER_AGED = "CUSTOMER_AGED";
const NAVIGATE_NEXT = "NAVIGATE_NEXT";
const ACTIVATED = "ACTIVATED";

function* range(first, last) {
    for (var i = first; i <= last; i++) {
        yield i;
    }
}

const fieldDefinitions = {
    [FIELDS.ZIP]: {
        fieldName: "zip",
        elementName: "zip",
        defaultValue: "",
        required: true,
    },
    [FIELDS.GENDER]: {
        fieldName: "gender",
        elementName: "gender",
        defaultValue: "",
        required: true,
    },
};

function isValid(fieldName, fieldErrors) {
    return !fieldErrors.some((error) => error.fields.some((field) => field === fieldName));
}

function validateAgeGuidelines(minAge, maxAge, age) {
    if (age) {
        const belowMinAgeError = {
            fields: [FIELDS.YEAR, FIELDS.MONTH, FIELDS.DAY],
            message: `Expected age to be no less than ${minAge}.`,
            knockout: "ageGuidelinesNotMet",
        };
        const aboveMaxAgeError = {
            fields: [FIELDS.YEAR, FIELDS.MONTH, FIELDS.DAY],
            message: `Expected age to be no more than ${maxAge}.`,
            knockout: "ageGuidelinesNotMet",
        };
        return [...(age >= minAge ? [] : [belowMinAgeError]), ...(age <= maxAge ? [] : [aboveMaxAgeError])];
    } else {
        return [];
    }
}

function formatZip(zip) {
    return zip.replace(/\D*/g, "").substr(0, 5);
}

const createReducer = (restrictions) => {
    const setOfInvalidStates = new Set(restrictions.invalidStates);
    function isAvailableInState(state) {
        return !setOfInvalidStates.has(state);
    }

    return (state, action) => {
        switch (action.type) {
            case ACTIVATED:
                return action.state;
            case CHANGED_FIELD_VALUE:
                return {
                    ...state,
                    fields: { ...state.fields, [action.fieldName]: action.value },
                    // Clear out locationRestrictions if zip changes to avoid out of sync data
                    locationRestrictions: action.fieldName === FIELDS.ZIP ? {} : state.locationRestrictions,
                    validationErrors: state.validationErrors.filter((error) => !error.fields.includes(action.fieldName)),
                };
            case CUSTOMER_AGED: {
                const ageValidationErrors = validateAgeGuidelines(
                    restrictions.validAgeRange.minAge,
                    restrictions.validAgeRange.maxAge,
                    action.age,
                );

                return {
                    ...state,
                    age: action.age,
                    validationErrors: [
                        ...state.validationErrors.filter((error) => error.knockout !== "ageGuidelinesNotMet"),
                        ...ageValidationErrors,
                    ],
                };
            }
            case CHANGED_LOCATION_RESTRICTIONS:
                const validateLocationRestrictions = (allTermNotOffered) => {
                    return allTermNotOffered
                        ? [
                              {
                                  fields: [FIELDS.ZIP],
                                  message: "Expected ZIP code for a State in which the product is available.",
                                  knockout: "allTermNotOffered",
                              },
                          ]
                        : [];
                };

                const locationKnockoutErrors = validateLocationRestrictions(action.payload.allTermNotOffered);

                return {
                    ...state,
                    state: action.state,
                    locationRestrictions: {
                        ...action.payload,
                    },
                    validationErrors: [
                        ...state.validationErrors.filter((error) => error.knockout !== "allTermNotOffered"),
                        ...locationKnockoutErrors,
                    ],
                };
            case NAVIGATE_NEXT:
                const newValidationErrors = [
                    ...requiredFields.map((key) =>
                        state.fields[FIELDS[key]] !== undefined
                            ? null
                            : {
                                  fields: [FIELDS[key]],
                                  message: `Expected ${FIELDS[key]} to have a value because it is required.`,
                              },
                    ),
                    !state.fields.zip || state.fields.zip.match(/\d{5}/)
                        ? null
                        : { fields: [FIELDS.ZIP], message: "Expected ZIP code to be exactly 5 digits in length." },
                    !state.fields.zip || state.locationRestrictions.state
                        ? null
                        : {
                              fields: [FIELDS.ZIP],
                              message: "Expected zip to be a known US ZIP code value recognized by the CDS service.",
                          },
                    !state?.locationRestrictions?.state || isAvailableInState(state.locationRestrictions.state)
                        ? null
                        : {
                              fields: [FIELDS.ZIP],
                              message: "Expected ZIP code for a State in which the product is available.",
                              knockout: "allTermNotOffered",
                          },
                    !state.fields.gender || state.fields.gender.match(/^(Male|Female)$/i)
                        ? null
                        : { fields: [FIELDS.GENDER], message: `Expected ${FIELDS.GENDER} to be either Male or Female.` },
                    state.fields.dobYear >= 1921 && state.fields.dobYear <= 2008
                        ? null
                        : { fields: [FIELDS.YEAR], message: "Expected year to be a value between 1921 and 2008." },
                    ...validateAgeGuidelines(restrictions.validAgeRange.minAge, restrictions.validAgeRange.maxAge, state.age),
                    typeof state.fields.isMember === "boolean"
                        ? null
                        : {
                              fields: [FIELDS.IS_MEMBER],
                              message: `Expected ${FIELDS.IS_MEMBER} to have the value true or false.`,
                          },
                    !state.fields.email || isEmailAddressFormatValid(state.fields.email)
                        ? null
                        : {
                              fields: [FIELDS.EMAIL],
                              message: `Expected ${FIELDS.EMAIL} to be formatted as a valid email address.`,
                          },
                ].filter((error) => error);

                return {
                    ...state,
                    shouldNavigateNext: newValidationErrors.length === 0,
                    validationErrors: newValidationErrors,
                };

            default:
                return state;
        }
    };
};

export function Panel1({
    active,
    onNext,
    restrictions,
    initialState,
    knockoutEffect,
    zipInfoLookupEffect,
    zipChangedEffect,
    resources,
}) {
    const createInitialState = () => {
        return {
            // active: active,
            shouldNavigateNext: false,
            validationErrors: [],
            locationRestrictions: {},
            fields: {
                [FIELDS.ZIP]: initialState.zip,
                [FIELDS.GENDER]: initialState.gender,
                [FIELDS.YEAR]: initialState.dobYear,
                [FIELDS.MONTH]: initialState.dobMonth,
                [FIELDS.DAY]: initialState.dobDay,
                [FIELDS.IS_MEMBER]: initialState.isMember, // Expect true | false | undefined
                [FIELDS.EMAIL]: initialState.email,
            },
        };
    };
    const [state, dispatch] = useReducer(createReducer(restrictions), {}, createInitialState);
    useReactivateEffect(
        () =>
            dispatch({
                type: ACTIVATED,
                state: { ...createInitialState(), locationRestrictions: state.locationRestrictions, age: state.age },
            }),
        active,
    );

    useEffect(() => {
        const age = ageLastBirthday(createDate(state.fields.dobYear, state.fields.dobMonth, state.fields.dobDay), new Date());
        if (state.age !== age) {
            dispatch({ type: CUSTOMER_AGED, age: age });
        }
    }, [state.fields.dobYear, state.fields.dobMonth, state.fields.dobDay]);

    useEffect(() => {
        const zip = state.fields[FIELDS.ZIP];
        if (zip && zip.length === 5) {
            // Dispatch any events/actions for async validation completion or failure
            zipInfoLookupEffect(zip)
                .then((result) =>
                    dispatch({
                        type: CHANGED_LOCATION_RESTRICTIONS,
                        payload: { ...result },
                    }),
                )
                .catch((error) => {
                    dispatch({
                        type: CHANGED_LOCATION_RESTRICTIONS,
                        payload: {},
                    });
                    console.error(error);
                });
        } else {
            dispatch({
                type: CHANGED_LOCATION_RESTRICTIONS,
                payload: {},
            });
        }
    }, [state.fields[FIELDS.ZIP]]);

    useEffect(() => {
        if (state.shouldNavigateNext) {
            zipChangedEffect(state.fields[FIELDS.ZIP]);
            onNext({ ...state.fields, age: state.age });
        }
    }, [state.shouldNavigateNext, state.fields[FIELDS.ZIP]]);

    const knockouts = state.validationErrors.map((error) => error.knockout).filter((knockout) => knockout);
    useEffect(() => {
        if (knockouts.length > 0) {
            knockoutEffect(knockouts[0], state.fields[FIELDS.ZIP]);
        }
    }, [knockouts.length, knockouts[0]]);

    const onKnockout = useCallback(() => {
        if (knockouts.length > 0) {
            knockoutEffect(knockouts[0], state.fields[FIELDS.ZIP]);
        }
    }, [knockouts.length, knockouts[0]]);

    const zipKnockout = state.validationErrors.some((error) => error.knockout === "allTermNotOffered");

    const validOrInvalid = (fieldName) => (isValid(fieldName, state.validationErrors) ? "valid" : "invalid");

    const pristineOrDirty = (fieldName) => (state.fields[fieldName] !== undefined ? "dirty" : "pristine");

    return (
        <div className={["panel", "panel-panel1", active ? "active" : "hide"].join(" ")} style={{ width: 390, left: 0 }}>
            <p className={`inlineValidation-quote ${state.validationErrors.length > 0 ? "show" : ""}`}>
                Please complete/correct the required fields
            </p>
            <div className="row formStyle">
                <div className=" small-7 large-7 columns">
                    <label htmlFor="zip">
                        Zip Code <span>(primary home)</span>
                    </label>
                    <input
                        type="tel"
                        maxLength={5}
                        className={["zipNumeric", validOrInvalid(FIELDS.ZIP), pristineOrDirty(FIELDS.ZIP)].join(" ")}
                        id="zip"
                        name="zip"
                        value={formatZip(state.fields.zip || "")}
                        autoComplete="postal-code"
                        onChange={(e) => {
                            dispatch({ type: CHANGED_FIELD_VALUE, fieldName: FIELDS.ZIP, value: e.target.value || undefined });
                        }}
                        onBlur={(e) => {
                            // Force callback effect when leaving the ZIP Code field in order to reopen a
                            // potential knockout box. This is done in order to match original behavior for now.
                            onKnockout();
                        }}
                    />
                </div>
                <div className="small-6 columns">
                    <label htmlFor="gender" className="globalForm-labels">
                        Gender
                    </label>
                    <select
                        name="gender"
                        id="gender"
                        className={[validOrInvalid(FIELDS.GENDER), pristineOrDirty(FIELDS.GENDER)].join(" ")}
                        disabled={zipKnockout}
                        value={state.fields.gender || ""}
                        autoComplete="sex"
                        onChange={(e) => {
                            dispatch({
                                type: CHANGED_FIELD_VALUE,
                                fieldName: FIELDS.GENDER,
                                value: e.target.value || undefined,
                            });
                        }}
                    >
                        <option value="" hidden>
                            Select
                        </option>
                        <option>Male</option>
                        <option>Female</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="small-12 columns">
                    <label id="dateOfBirthLabel" className="globalForm-labels">
                        Date of Birth
                    </label>
                </div>
                <div className="formStyle input-group small-12 columns row collapse">
                    <div className="date small-5 columns">
                        <select
                            name="month"
                            id="month"
                            className={[validOrInvalid(FIELDS.MONTH), pristineOrDirty(FIELDS.MONTH)].join(" ")}
                            value={(state.fields.dobMonth || "").toString()}
                            disabled={zipKnockout}
                            autoComplete="bday-month"
                            aria-labelledby="dateOfBirthLabel"
                            aria-label="Month"
                            onChange={(e) => {
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.MONTH,
                                    value: (e.target.value || undefined) && parseInt(e.target.value, 10),
                                });
                            }}
                        >
                            <option value="" hidden>
                                MM
                            </option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </div>
                    <div className="date small-3 columns">
                        <select
                            name="day"
                            id="day"
                            className={[validOrInvalid(FIELDS.DAY), pristineOrDirty(FIELDS.DAY)].join(" ")}
                            value={(state.fields.dobDay || "").toString()}
                            disabled={zipKnockout}
                            autoComplete="bday-day"
                            aria-labelledby="dateOfBirthLabel"
                            aria-label="Day"
                            onChange={(e) => {
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.DAY,
                                    value: (e.target.value || undefined) && parseInt(e.target.value, 10),
                                });
                            }}
                        >
                            <option value="" hidden>
                                DD
                            </option>
                            {Array.from(range(1, 31)).map((dayOfMonth) => (
                                <option value={dayOfMonth} key={dayOfMonth}>
                                    {dayOfMonth}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="date small-4 columns">
                        <select
                            name="year"
                            id="year"
                            className={[validOrInvalid(FIELDS.YEAR), pristineOrDirty(FIELDS.YEAR)].join(" ")}
                            disabled={zipKnockout}
                            value={(state.fields.dobYear || "").toString()}
                            autoComplete="bday-year"
                            aria-labelledby="dateOfBirthLabel"
                            aria-label="Year"
                            onChange={(e) => {
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.YEAR,
                                    value: (e.target.value || undefined) && parseInt(e.target.value, 10),
                                });
                            }}
                        >
                            <option value="" hidden>
                                YYYY
                            </option>
                            {Array.from(range(1921, 2008))
                                .reverse()
                                .map((year) => (
                                    <option value={year} key={year}>
                                        {year}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <fieldset className="small-12 columns">
                    <legend className="quoteForm-member">
                        Are you a AAA member?{" "}
                        <Tooltip
                            id="membertoolTip"
                            svgClass="quoteForm-icon-arrow-questionMark"
                            divClass="quoteForm-tipBlock"
                            resources={resources}
                        >
                            You don’t need to be a AAA member to apply for life insurance. However, members of AAA do receive
                            some perks –{" "}
                            <a id="dynamicMembershipLink" href="https://www.aaa.com/joinnow" target="_blank">
                                learn more
                            </a>{" "}
                            about joining AAA.
                        </Tooltip>
                    </legend>
                    <div className="twoRadioStyle">
                        <input
                            type="radio"
                            value="Yes"
                            name="isMember"
                            id="isMemberYes"
                            required
                            className={[validOrInvalid(FIELDS.IS_MEMBER), pristineOrDirty(FIELDS.IS_MEMBER)].join(" ")}
                            disabled={zipKnockout}
                            checked={state.fields.isMember === true}
                            onChange={(e) => {
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.IS_MEMBER,
                                    value: yesNoToBooleanOrUndefined(e.target.value),
                                });
                            }}
                        />
                        <label htmlFor="isMemberYes">
                            <span>
                                <span />
                            </span>
                            Yes
                        </label>
                        <input
                            type="radio"
                            value="No"
                            name="isMember"
                            id="isMemberNo"
                            required
                            className={[validOrInvalid(FIELDS.IS_MEMBER), pristineOrDirty(FIELDS.IS_MEMBER)].join(" ")}
                            disabled={zipKnockout}
                            checked={state.fields.isMember === false}
                            onChange={(e) => {
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.IS_MEMBER,
                                    value: yesNoToBooleanOrUndefined(e.target.value),
                                });
                            }}
                        />
                        <label htmlFor="isMemberNo">
                            <span>
                                <span />
                            </span>
                            No
                        </label>
                    </div>
                </fieldset>
            </div>
            <div className="row">
                <div className="small-12 large-12 columns">
                    <label htmlFor="contact_email" className="globalForm-labels">
                        Email
                    </label>
                    <input
                        type="text"
                        id="contact_email"
                        name="email"
                        placeholder="you@email.com"
                        maxLength={100}
                        className={[validOrInvalid(FIELDS.EMAIL), pristineOrDirty(FIELDS.EMAIL)].join(" ")}
                        disabled={zipKnockout}
                        value={state.fields.email || ""}
                        onChange={(e) => {
                            dispatch({
                                type: CHANGED_FIELD_VALUE,
                                fieldName: FIELDS.EMAIL,
                                value: e.target.value || undefined,
                            });
                        }}
                    />
                </div>
            </div>
            <div className="buttonWrap">
                <button
                    id="panel1-next"
                    type="button"
                    data-load-page="/restservices/aggregate"
                    className="button btn-pink panel-container-nextbtn"
                    disabled={zipKnockout}
                    onClick={(e) => {
                        dispatch({ type: "NAVIGATE_NEXT", now: Date.now() });
                    }}
                >
                    Next
                </button>
            </div>
        </div>
    );
}
