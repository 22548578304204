import $ from "jquery";

export function initMobileMenu() {
    init();

    /**
     * init - initialize mobile nav menu JS
     *
     * @return {void} void
     */
    function init() {
        $(".mobile-menu-icon").on("click", toggleMobileNav);
        $(".closeBtn, .overlay").on("click", closeMobileNav);
        $(".mobile-menu .title").on("click", mobileNavToggle);
        $(window).on("changed.zf.mediaquery", _removeMobileNav);
    }

    /**
     * toggleMobileNav - Click handler to open/close mobile menu on icon
     * click
     *
     * @param {Object}
     *            e - jQuery event object
     * @return {void} void
     */
    function toggleMobileNav(e) {
        if (!$(".closeBtn").hasClass("sbar")) {
            $(".overlay, .closeBtn").addClass("mobile-nav");
            $(".mobile-menu, .overlay.mobile-nav, .closeBtn.mobile-nav").toggleClass("show");
            $(".mobile-menu-icon").toggleClass("open");
            $("html, body").toggleClass("preventScroll", $(".mobile-menu").hasClass("show"));
            $(".mobile-nav").toggleClass("mobile-nav", $(".mobile-menu").hasClass("show"));
        }
        e.preventDefault();
    }

    /**
     * closeMobileNav - Click handler to close mobile menu
     *
     * @param {Object}
     *            e - jQuery event object
     * @return {void} void
     */
    function closeMobileNav(e) {
        $(".mobile-menu, .overlay.mobile-nav, .closeBtn.mobile-nav").removeClass("show");
        $(".mobile-menu-icon").removeClass("open");
        e.preventDefault();
    }

    /**
     * mobileNavToggle - Click handler when mobile nav title is clicked
     *
     * @param {Object}
     *            e - jQuery event object
     * @return {void} void
     */
    function mobileNavToggle(e) {
        var $itemClicked = $(this);
        var startedHidden = !$itemClicked.hasClass("show");

        $(".title").removeClass("show");

        if (startedHidden) {
            $itemClicked.addClass("show");
        }
    }

    /**
     * _removeMobileNav - remove mobile menu when resizing from < xlarge to >=
     * xlarge
     *
     * @param event
     * @param newSize
     * @param oldSize
     * @private
     */
    function _removeMobileNav(event, newSize, oldSize) {
        if (["xlarge"].indexOf(newSize) > -1 && ["xxlarge"].indexOf(oldSize) == -1) {
            $(".mobile-menu.show").removeClass("show");
            $(".overlay").attr("class", "overlay");
            $(".closeBtn").attr("class", "closeBtn");
            $("html, body").removeClass("preventScroll");
            $(".mobile-menu-icon.open").removeClass("open");
        }
    }
}
