import React from "react";

export function HealthSectionContent(props) {
    return (
        <div className="inner-form-block">
            <div className="column-row odd subTitle">
                <p>Nicotine/Tobacco</p>
            </div>
            <div className="column-row innerContent">
                <div className="healthInfo">
                    <p className={props.labelClass}>No nicotine or tobacco use in the past:</p>
                    {!props.usedNicotine ? <p>{props.nicotineRange} months</p> : <p>Used last 12 Months</p>}
                </div>
            </div>
            <div className="column-row odd subTitle">
                <p>Blood Pressure</p>
            </div>
            <div className="column-row innerContent">
                <div className="healthInfo">
                    <p className={props.labelClass}>Blood pressure not exceeding:</p>
                    {props.bloodPressure.map((pressure) => (
                        <p key={pressure}>{pressure}</p>
                    ))}
                </div>
                <div className="healthInfo">
                    <p className={props.labelClass}>History of Hypertension:</p>
                    <p>{props.hypertensionMedications}</p>
                </div>
            </div>
            <div className="column-row odd  subTitle">
                <p>Cholesterol</p>
            </div>
            <div className="column-row innerContent">
                <div className="healthInfo">
                    <p className={props.labelClass}>Cholesterol between:</p>
                    <p>{props.cholesterol}</p>
                </div>
                <div className="healthInfo">
                    <p className={props.labelClass}>Cholesterol/HDL ratio not exceeding:</p>
                    {props.hdl.map((hdlRatio) => (
                        <p key={hdlRatio}>{hdlRatio}</p>
                    ))}
                </div>
                <div className="healthInfo">
                    <p className={props.labelClass}>If applicable, elevated cholesterol is:</p>
                    <p>Well Controlled with treatment</p>
                </div>
            </div>
            <div className="column-row odd  subTitle">
                <p>Other Health Conditions</p>
            </div>
            <div className="column-row innerContent">
                <div className="healthInfo">
                    <p className={props.labelClass}>History of heart disease:</p>
                    <p>No history</p>
                </div>
                <div className="healthInfo">
                    <p className={props.labelClass}>History of diabetes:</p>
                    <p>No history</p>
                </div>
                <div className="healthInfo">
                    <p className={props.labelClass}>History of Cancer:</p>
                    <p>No history</p>
                </div>
                <div className="healthInfo">
                    <p className={props.labelClass}>History of Drug or Alcohol Abuse:</p>
                    <p>{props.drugAbuseHistory}</p>
                </div>
                <div className="healthInfo">
                    <p className={props.labelClass}>Family history of heart disease and/or cancer:</p>
                    <p>{props.heartDisease}</p>
                </div>
            </div>
            <div className="column-row odd  subTitle">
                <p>Legal History</p>
            </div>
            <div className="column-row innerContent">
                <div className="healthInfo">
                    <p className={props.labelClass}>Moving violations while driving:</p>
                    <p>{props.movingViolations}</p>
                </div>
                <div className="healthInfo">
                    <p className={props.labelClass}>Driving under the influence (DUI) convictions:</p>
                    <p>{props.duiHistory}</p>
                </div>
            </div>
        </div>
    );
}
