/**
 * @typedef {Object} ValidationError
 * @property {string} errorMessage - An error message phrase starting with a lower case letter so that it can be
 * chained as necessary (ex. "a number between 12 and 30").
 * @property {String} property The name of the property.
 * @property {any} value The value of the property.
 */

/**
 * @typedef {Object} ValidationRule
 * @property {boolean} [required] - Whether the property is required to be defined or not.
 * @property {"string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"} type - The expected Javascript type of the property.
 * @property {boolean} [minLength] - The inclusive minimum length of the property.
 * @property {boolean} [maxLength] - The inclusive maximum length of the property.
 * @property {Array[any]} [acceptableValues] - A set of values for which a value is deemed valid if equal to any.
 * @property {RegExp} [regex] - A regular expression used which only matches valid values.
 * @property {string} validFormatDescription - A phrase that describes the expected format of the value starting with
 *  a lower case letter so that it can be chained as necessary (ex. "a number between 12 and 30").
 */

/**
 * 
 * @param {ValidationRule} rule The rules that govern validation for the property.
 * @param {string} key The name of the property.
 * @param {any} value The value of the property.
 * @returns {ValidationError} Information about the validation error if any otherwise undefined.
 * @example 
 *  validateProperty({
        required: true,
        type: "string",
        minLength: 1,
        maxLength: 60,
        regex: /^([a-zA-Z ])+$/,
        validFormatDescription: "a name containing any upper or lower case letter or space."},
        "name",
        "Alfred");
 */
export function validateProperty(rule, key, value) {
    const type = typeof value;
    if (!rule.required && type === "undefined") {
        // Property not present but not required
        return;
    }
    if (type !== rule.type) {
        return {
            property: key,
            value: value,
            errorMessage: `The value specified for ${key} was of type ${type} but was expected to be of type ${rule.type}.`,
        };
    }

    if (typeof rule.minLength === "number" || typeof rule.maxLength === "number") {
        const length = value.length;
        if (typeof length === "undefined") {
            return {
                property: key,
                value: value,
                errorMessage: `The value specified for ${key} did not have a length property but a length property was expected.`,
            };
        }
        if (length < rule.minLength) {
            return {
                property: key,
                value: value,
                errorMessage: `The value specified for ${key} had a length of ${length} but was expected to be greater than or equal to ${rule.minLength}.`,
            };
        }
        if (length > rule.maxLength) {
            return {
                property: key,
                value: value,
                errorMessage: `The value specified for ${key} had a length of ${length} but was expected to be less than or equal to ${rule.maxLength}.`,
            };
        }
    }

    if (rule.regex && !rule.regex.test(value)) {
        return {
            property: key,
            value: value,
            errorMessage: `The value specified for ${key} was not ${rule.validFormatDescription}.`,
        };
    }

    if (rule.acceptableValues && rule.acceptableValues.indexOf(value) < 0) {
        const valuesList = rule.acceptableValues.map((value) => value.toString()).join("|");
        return {
            property: key,
            value: value,
            errorMessage: `The value specified for ${key} was not one of the acceptable values (${valuesList}).`,
        };
    }
}
