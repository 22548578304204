import $ from "jquery";
import ValidateUtils from "./aaalife-utils-validate";

export function hookupZipCodePromoButtonOnClick() {
    $("#zipCodePromoButton").on("click", _dynamicAgentButtonClicked);

    function _dynamicAgentButtonClicked(e) {
        var zip = $("#zipCode").val();
        var isValid = ValidateUtils._zipCodeCDSValidation(zip);

        if (isValid) {
            _showInlineError(false);
            $("#zipCode").removeClass("invalid").addClass("valid");
            $("#haveQuestionsForm").submit();
        } else {
            e.preventDefault();
            $("#zipCode").removeClass("valid").addClass("invalid");
            _showInlineError(true);
        }
    }

    function _showInlineError(show) {
        $(".inlineValidationHaveQuestions").toggleClass("show", show);
    }
}
