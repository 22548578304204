import $ from "jquery";
import { aaalifeBaseInit } from "./aaalife-base-init";
import { browserStorage } from "./aaalife-utils-browser-storage";

export function initTimeoutElement() {
    $(document).ready(function () {
        var timeoutElement = document.getElementById("timeout");
        if (timeoutElement !== null) {
            var aaalifeBase = aaalifeBaseInit();
            browserStorage.setObject("user", aaalifeBase.data.user);
            browserStorage.setObject("quote", aaalifeBase.data.quote);
            browserStorage.setObject("validation", aaalifeBase.data.validation);
        }
    });
}
