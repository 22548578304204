import React, { useState } from "react";

export function Panel2({ active, onNext, onBack, initialState, resources: { checkmarkSvgIconUrl, leftArrowSvgIconUrl } }) {
    const [productType, setProductType] = useState(initialState.productType);
    const [isError, setIsError] = useState(false);

    const validateProductType = (productType) => productType === "traditional_term" || productType === "express_term";
    const isValidProductType = validateProductType(productType);

    const handleNextClicked = (e) => {
        setIsError(!isValidProductType);

        if (isValidProductType) {
            onNext({ productType });
        }
    };

    return (
        <div
            className={["panel", "panel-panel2", active ? "active" : "hide"].join(" ")}
            data-event="TQSIP"
            style={{ width: 390, left: 350 }}
            role="group"
            aria-labelledby="productGroupLabel"
        >
            <p className={`inlineValidation-quote ${isError ? "show" : "hide"}`}>Please complete/correct the required fields</p>
            <div className="row">
                <div className="small-12 large-12 columns innerContent" id="productGroupLabel">
                    <p>Choose a Term Life product:</p>
                </div>
            </div>
            <div className="quoteForm-outerblock row">
                <div className="small-2 large-2 columns">
                    <input
                        type="radio"
                        className={["radioStyle", isError ? "invalid" : "valid"].join(" ")}
                        defaultValue="express_term"
                        id="panel2radio1"
                        name="insurance_type"
                        checked={productType === "express_term"}
                        onChange={(e) => setProductType("express_term")}
                    />
                    <label htmlFor="panel2radio1">
                        <span className="radioChild">
                            <span />
                        </span>
                    </label>
                </div>
                <div className="quoteForm-innerWrap small-10 large-10 columns">
                    <h4>ExpressTerm</h4>
                    <ul>
                        <li>
                            <svg className="icon quoteForm-icon-checkmark">
                                <use xlinkHref={checkmarkSvgIconUrl} />
                            </svg>
                            &nbsp;&nbsp;$25,000 to $500,000 in coverage
                        </li>
                        <li>
                            <svg className="icon quoteForm-icon-checkmark">
                                <use xlinkHref={checkmarkSvgIconUrl} />
                            </svg>
                            &nbsp;&nbsp;10, 15, 20, or 30-year term
                        </li>
                        <li>
                            <svg className="icon quoteForm-icon-checkmark">
                                <use xlinkHref={checkmarkSvgIconUrl} />
                            </svg>
                            &nbsp;&nbsp;Apply online
                        </li>
                    </ul>
                </div>
            </div>
            <div className="quoteForm-outerblock row">
                <div className="small-2 large-2 columns">
                    <input
                        type="radio"
                        className={["radioStyle", isError ? "invalid" : "valid"].join(" ")}
                        defaultValue="traditional_term"
                        id="panel2radio2"
                        name="insurance_type"
                        checked={productType === "traditional_term"}
                        onChange={(e) => setProductType("traditional_term")}
                    />
                    <label htmlFor="panel2radio2">
                        <span className="radioChild">
                            <span />
                        </span>
                    </label>
                </div>
                <div className="quoteForm-innerWrap small-10 large-10 columns">
                    <h4>Traditional Term</h4>
                    <ul>
                        <li>
                            <svg className="icon quoteForm-icon-checkmark">
                                <use xlinkHref={checkmarkSvgIconUrl} />
                            </svg>
                            &nbsp;&nbsp;$100,000 to $5,000,000 in coverage
                        </li>
                        <li>
                            <svg className="icon quoteForm-icon-checkmark">
                                <use xlinkHref={checkmarkSvgIconUrl} />
                            </svg>
                            &nbsp;&nbsp;10, 15, 20, or 30-year term
                        </li>
                        <li>
                            <svg className="icon quoteForm-icon-checkmark">
                                <use xlinkHref={checkmarkSvgIconUrl} />
                            </svg>
                            &nbsp;&nbsp;Apply and buy with an agent
                        </li>
                    </ul>
                </div>
            </div>
            <div className="buttonWrap">
                <button
                    type="button"
                    id="panel2-back"
                    className="button panel-container-backbtn"
                    data-itc="AL31"
                    onClick={onBack}
                >
                    Back
                    <svg className="icon panel-container-icon-arrow-left">
                        <use xlinkHref={leftArrowSvgIconUrl} />
                    </svg>
                </button>
                <button
                    id="panel2-next"
                    type="button"
                    data-itc="AL31B"
                    data-load-page="/restservices/aggregate"
                    data-special-action="sf-p"
                    className="button btn-pink panel-container-nextbtn"
                    onClick={handleNextClicked}
                >
                    Next
                </button>
            </div>
        </div>
    );
}
