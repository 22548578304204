import $ from "jquery";
import "jquery-mask-plugin";
import { collectFormData } from "../collector";
import { checkAndSetPhoneSource, checkAndSetEmailSource } from "../utils/data-source-code";
import { format10DigitNanpaPhone, parseUnformatted10DigitPhone } from "../utils/phone";
import { browserStorage } from "./aaalife-utils-browser-storage";
import { COND_MAP_TRADITIONAL } from "./aaalife-utils-get-data";

var _formDataValid = {
    firstName: false,
    lastName: false,
    phoneFormat: false,
    emailFormat: false,
};

/**
 * init - initialize apply now JS
 *
 * @return {void} void
 */
export function init() {
    if ($(".applyNow-form").length > 0) {
        _initApplyNowForm();
    }
    _initEventListeners();
}

/**
 * _initEventListeners
 *
 * @private
 */
function _initEventListeners() {
    $(".showtooltip svg").on("click", function () {
        $(this).parent().find(".tooltipinfo").show();
    });
    $(".tooltipinfo .tooltipclose").on("click", function () {
        $(this).parent().hide();
    });
    if ($(".applyNow-form").length > 0) {
        // Navigation
        $(".applyNow-form").on("submit", _validateForm);

        // Validation
        $('.applyNow-form [name="firstName"]').on("change", _hasValue);
        $('.applyNow-form [name="lastName"]').on("change", _hasValue);
        $('.applyNow-form [name="phone"]').on("change", _validatePhoneNumber);
        $('.applyNow-form [name="phone"]').mask("YZZ(A00) A00-0000", {
            translation: {
                A: {
                    pattern: /[2-9]/,
                },
                Z: {
                    pattern: /[0-1 ]/,
                    optional: true,
                },
                Y: {
                    pattern: /[+ ]/,
                    optional: true,
                },
            },
        });
        $('.applyNow-form [name="email"]').on("change", _checkEmail);

        $('.applyNow-form [name="firstName"]').mask("YYYYYYYYYYYYYYYYYYYY", {
            translation: {
                Y: {
                    pattern: /[A-Za-z'-]/,
                },
            },
        });
        $('.applyNow-form [name="lastName"]').mask("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYY", {
            translation: {
                Y: {
                    pattern: /[A-Za-z'-]/,
                },
            },
        });
    }

    if ($(".applyNow-confirmation").length > 0) {
        $(".startNewQuoteLink").on("click", _startNewQuote);
    }
}

/**
 * _initApplyNowForm
 *
 * @private
 */
function _initApplyNowForm() {
    var quote = browserStorage.getObject("quote");
    var user = browserStorage.getObject("user");
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dobString = "";
    var htWtString = "";

    if (!quote) {
        quote = aaalife.data.quote || {};
    }
    if (!user) {
        user = aaalife.data.user || {};
    }

    if (quote && Object.keys(quote).length > 0) {
        $(".applyNow-premium").text(quote.premium);
        $(".applyNow-term").text(quote.term);
        $(".applyNow-coverage").text(quote.coverage);

        aaalife.data.quote = quote;
        browserStorage.setObject("quote", quote);
    }

    if (user && Object.keys(user).length > 0) {
        $(".applyNow-gender").text(user.gender);

        dobString = months[user.dobMonth - 1] + " " + user.dobDay + ", " + user.dobYear;
        $(".applyNow-dob").text(dobString);

        htWtString = user.heightFeet + "'" + user.heightInches + '"  & ' + user.weight + " lbs";
        $(".applyNow-height-weight").text(htWtString);

        if (user.eligibleForOnlyFair === "yes") {
            $(".overallHealth").detach();
        } else {
            $(".applyNow-health").text(COND_MAP_TRADITIONAL[user.health]);
        }
        $(".applyNow-nicotine").text(user.nicotine);

        $("#firstName").val(user.firstName);
        $("#lastName").val(user.lastName);
        $("#phone").val(user.phone);
        $("#email").val(user.email);
    }
}

/**
 * _checkEmail
 *
 * @returns {boolean|*}
 * @private
 */
function _checkEmail(e, shouldValidate) {
    var isValid;
    var $input = $(this);
    var email = $input.val();

    isValid = aaalife.utils.validate.emailFormat(email, true);
    _formDataValid.emailFormat = isValid;
    if (shouldValidate) {
        _highlightInvalidField(isValid, $input);
        checkAndSetEmailSource(email);
    } else {
        _highlightInvalidField(true, $input);
    }

    return isValid;
}

/**
 * _validatePhoneNumber
 *
 * @returns {*}
 * @private
 */
function _validatePhoneNumber(e, shouldValidate) {
    const $input = $(this);
    const phone = parseUnformatted10DigitPhone($input.val());

    const isValid = aaalife.utils.validate.phoneFormat(phone);
    _formDataValid.phoneFormat = isValid;
    if (shouldValidate) {
        _highlightInvalidField(isValid, $input);

        if (isValid) {
            checkAndSetPhoneSource(phone);
            $input.val(format10DigitNanpaPhone(phone));
        }
    } else {
        _highlightInvalidField(true, $input);
    }

    return isValid;
}

/**
 * _hasValue
 *
 * @returns {boolean}
 * @private
 */
function _hasValue(e, shouldValidate) {
    var $el = $(this);
    var isValid = $el.is(":visible") ? !aaalife.utils.validate.missingValue($el) : true;

    if (shouldValidate) {
        _highlightInvalidField(isValid, $el);
    } else {
        _highlightInvalidField(true, $el);
    }

    return isValid;
}

/**
 * _highlightInvalidField
 *
 * @param isValid
 * @param $argElement
 * @private
 */
function _highlightInvalidField(isValid, $argElement) {
    var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

    if (isValid) {
        _showInlineError(false);
        $element.removeClass("invalid").addClass("valid");
    } else {
        _showInlineError(true);
        $element.removeClass("valid").addClass("invalid");
    }
}

/**
 * _showInlineError
 *
 * @param show
 * @private
 */
function _showInlineError(show) {
    $(".inlineValidation").toggleClass("show", show);
}

/**
 * _removeElement
 *
 * @param show
 * @private
 */
function _removeElement(elementId) {
    var element = document.getElementById(elementId);
    if (element != null) {
        element.parentNode.removeChild(element);
    }
}

/**
 * _validateForm
 *
 * @param e
 * @private
 */
function _validateForm(e) {
    var isValid;
    var $firstNameField = $('.applyNow-form [name="firstName"]');
    var $lastNameField = $('.applyNow-form [name="lastName"]');
    var $phoneField = $(".applyNow-form #phone");
    var $emailField = $(".applyNow-form #email");

    _formDataValid.firstName = _hasValue.call($firstNameField, null, true);
    _formDataValid.lastName = _hasValue.call($lastNameField, null, true);
    _formDataValid.phoneFormat = _validatePhoneNumber.call($phoneField[0], null, true);
    _formDataValid.emailFormat = _checkEmail.call($emailField[0], null, true);

    isValid = Object.keys(_formDataValid).every(function (key) {
        return _formDataValid[key];
    });

    if (isValid) {
        var user = browserStorage.getObject("user");
        if (!user) {
            user = aaalife.data.user || {};
        }

        user.firstName = $firstNameField.val();
        user.lastName = $lastNameField.val();
        user.phone = $phoneField.val();
        user.email = $emailField.val();

        aaalife.data.user = user;
        browserStorage.setObject("user", aaalife.data.user);
        _removeElement("invalidData");
        $(".applyNow-form").append('<input type="hidden" id="invalidData" name="invalidData" value="false">');
        var itc = $(".applyNow-form").data("itc");
        if (itc != "") {
            $(".applyNow-form").append('<input type="hidden" name="attr-itc" value="' + itc + '">');
        }

        $(".applyNow-form").append('<input type="hidden" name="attr-specialAction" value="sf">');
        // TODO: any additional submit logic needed before form submit goes
        // here
    } else {
        // form is invalid, display errors and allow user to fix
        e.preventDefault();
        $(".inlineValidation").toggleClass("show", true);
        _removeElement("invalidData");
        $(".applyNow-form").append('<input type="hidden" id="invalidData" name="invalidData" value="true">');
        var formData = collectFormData($(".applyNow-form"), $(this).closest("button").data());
        sendData(formData, false);
    }
}

/**
 * _startNewQuote
 *
 * @private
 */
function _startNewQuote(e) {
    var user = {};
    var quote = {};
    var zip = browserStorage.getObject("user").zip;
    var email = browserStorage.getObject("user").email;
    var destinationPage = browserStorage.getObject("quote").origin;

    e.preventDefault();

    aaalife.data.user = user;
    aaalife.data.quote = quote;
    aaalife.data.user.zip = zip;
    aaalife.data.user.email = email;
    browserStorage.setObject("user", aaalife.data.user);
    browserStorage.setObject("quote", aaalife.data.quote);

    window.location = destinationPage;
}
