export function nativeSelector(elements = []) {
    var results = [];
    var child;
    for (var i = 0; i < elements.length; i++) {
        child = elements[i].childNodes[0];
        if (elements[i].hasChildNodes() && child.nodeType === 3) {
            results.push(child);
        }
    }
    return results;
}
