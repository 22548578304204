import { useEffect, useRef } from "react";

/**
 * @param {function(): void} reactivated - A parameterless callback function called when the effect is reactivated.
 * @param {boolean} active - A boolean value that specifies whether the effect state should be currently active or inactive.
 */
export function useReactivateEffect(reactivated, active) {
    const isFirstRun = useRef(true);
    useEffect(() => {
        if (!isFirstRun.current && active) {
            reactivated();
        }

        if (isFirstRun.current) {
            isFirstRun.current = false;
        }
    }, [active]);
}
