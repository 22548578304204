import $ from "jquery";

/**
 * init - initialize accordion
 *
 * @return {void} void
 */
export function init() {
    $("body").on("click", ".accordion section h3", function (e) {
        var topOfSection;
        var $thisSection = $(this).parents("section");
        var $otherSections = $(this).parents().siblings("section");
        var $nextSection = $(this).parents().next("section");
        var isNoCollapse = $(this).parents(".accordion").hasClass("noCollapse");

        e.preventDefault();

        // open a closed panel
        if ($thisSection.hasClass("ac_hidden")) {
            $otherSections.addClass("ac_hidden");
            $thisSection.removeClass("ac_hidden");
        } else {
            // close an open panel, if allowed
            if (!isNoCollapse) {
                $thisSection.addClass("ac_hidden");
                $nextSection.removeClass("ac_hidden");
            }
        }

        // scroll to the top of the form, if needed
        topOfSection = $(this).offset().top - 20;
        if (window.scrollY > topOfSection) {
            window.scrollTo(0, topOfSection);
        }

        // show or hide the Red Circle on the homepage Term Quote
        // first panel
        if (!$("#item1").hasClass("ac_hidden") && !$(".panel-panel1").hasClass("hide")) {
            $(".quoteForm-redCircleBlock").removeClass("hide");
        } else {
            $(".quoteForm-redCircleBlock").addClass("hide");
        }
    });
}
