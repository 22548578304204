import $ from "jquery";
import { Foundation, MediaQuery } from "foundation-sites";

export function aaalifeSideBarInit() {
    var _$sidebarActivators = $(".btn[data-sidebar]");
    var _selectors = [];
    var _queSide = {
        small: "sidebar-left",
        medium: "sidebar-left",
        large: "sidebar-left",
        xlarge: "sidebar-right",
        xxlarge: "sidebar-right",
    };

    // Call init on document ready
    $(init);

    /**
     * init - Call init on document ready
     *
     * @return {void} - returns void
     */
    function init() {
        // set and update class to determine which side the sidebar is on
        _$sidebarActivators.each(_addSideClass);
        $(window).on("changed.zf.mediaquery", _updateSideClass);

        // update close button color based on media query
        $(window).on("changed.zf.mediaquery", _updateCloseButton);

        // remove sidebar based on media query
        $(window).on("changed.zf.mediaquery", _removeSidebars);

        // attach click event to elements with 'data-sidebar' attribute
        _$sidebarActivators.on("click", _handleBtnClick);
        $(".overlay, .closeBtn").on("click", _closeAllSidebars);
    }

    /**
     * _handleBtnClick - handle the clicks on the sidebar activation links
     *
     * @param {object}
     *            event - jQuery event object
     * @return {void} - returns void
     */
    function _handleBtnClick(event) {
        var $this = $(this);
        var selector = $this.attr("data-sidebar");

        if (_selectors.indexOf(selector) === -1) {
            _selectors.push(selector);
        }

        _selectors.every(_closeOpenSidebars);

        if (["medium", "large", "xlarge", "xxlarge"].indexOf(Foundation.MediaQuery.current) > -1) {
            $(".closeBtn").addClass("reverse");
        }

        $(".closeBtn, .overlay").addClass("sbar");
        $(".overlay.sbar, .closeBtn.sbar, " + selector).addClass("show");
        $("html, body").toggleClass("preventScroll", $(selector).hasClass("show"));
        event.preventDefault();
    }

    /**
     * _closeAllSidebars - handle the clicks on overlay or closeBtn
     *
     * @param {object}
     *            event - jQuery event object
     * @return {void} - returns void
     */
    function _closeAllSidebars(event) {
        $(".closeBtn.reverse").removeClass("reverse");
        $(".closeBtn.sbar, .overlay.sbar").removeClass("sbar");
        if (!$(".closeBtn").hasClass("mobile-nav")) {
            $(".closeBtn, .overlay").removeClass("show");
        }
        _selectors.every(_closeOpenSidebars);
        event.preventDefault();
    }

    /**
     * _removeSidebars - remove sidebar when resizing from < xlarge to >= xlarge
     */
    function _removeSidebars(event, newSize, oldSize) {
        if (["xlarge"].indexOf(newSize) > -1 && ["xxlarge"].indexOf(oldSize) == -1) {
            _closeAllSidebars(event);
        }
    }

    /**
     * _addSideClass - Add a class to put the side on the left or right on
     * initialization
     */
    function _addSideClass() {
        var selector = $(this).attr("data-sidebar");
        if (_selectors.indexOf(selector) === -1) {
            _selectors.push(selector);
        }
        $(selector).addClass(_queSide[Foundation.MediaQuery.current] || "");
    }

    /**
     * _updateSideClass - update the class to put the sidebar on the left or
     * right when the media query size changes
     *
     * @param {object}
     *            event - jQuery event object
     * @param {string}
     *            newSize - the new media query size
     * @return {void} - returns void
     */
    function _updateSideClass(event, newSize) {
        $(".sidebar-left").removeClass("sidebar-left");
        $(".sidebar-right").removeClass("sidebar-right");
        setTimeout(function () {
            _$sidebarActivators.each(_addSideClass);
        }, 0);
    }

    /**
     * _updateCloseButton - update the color of the close button on resize
     *
     * @param event
     * @param newSize
     * @param oldSize
     * @private
     */
    function _updateCloseButton(event, newSize, oldSize) {
        if (["medium", "large", "xlarge", "xxlarge"].indexOf(newSize) > -1) {
            $(".closeBtn").addClass("reverse");
        } else {
            $(".closeBtn").removeClass("reverse");
        }
    }

    /**
     * _closeOpenSidebars - closes any open sidebars
     *
     * @param {string}
     *            currentValue - selector string
     * @return {boolean} - returns true
     */
    function _closeOpenSidebars(currentValue) {
        $(currentValue).removeClass("show");
        $("html, body").removeClass("preventScroll");

        return true;
    }
}
