import $ from "jquery";

export function chooseTopicWrapperInit() {
    if ($(".chooseTopicWrapper").length > 0) {
        $(_init);
    }

    /**
     * _init - initialize the FAQs page
     *
     * @private
     */
    function _init() {
        aaalife.utils.boxAccordion.init();
        aaalife.utils.arrowAccordion.init();
    }
}
