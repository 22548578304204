import $ from "jquery";

export function faqFormInit() {
    if ($(".faqs").length > 0 || $(".faqCmpnt").length > 0) {
        $(_init);
    }

    /**
     * _init - initialize the FAQs page
     *
     * @private
     */
    function _init() {
        aaalife.utils.arrowAccordion.init();
        if (window.location.href.indexOf("#contactingAAALife") > -1) {
            _scrollToContactOnFaq();
        }
    }

    function _scrollToContactOnFaq() {
        $(".arrowAccordion-header a")[0].click();
        $(".arrowAccordion-header a")[4].click();
    }
}
