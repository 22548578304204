import $ from "jquery";
import { transformData } from "../collector";

export function initOrganicLandingForm() {
    if ($("#organicLandingForm").length > 0) {
        $(init);
    }

    /**
     * init - setup logic for the plan detail section
     *
     * @return {void} - returns void
     */
    function init() {
        $("#helppagefooterlinks").on("click", _redirectHomepageLink);
        $("#organicLandingForm").on("submit", _organicLandingSubmit);
    }

    function _redirectHomepageLink(e) {
        e.preventDefault();
        window.open("homepagea", "_blank");
    }

    function _organicLandingSubmit() {
        aaalife.utils.expressTerm.zipReturnState();
        aaalife.utils.expressTerm.appendHiddenFormData("#organicLandingForm");
        var etAction = transformData("action", "toET");
        var etData = transformData("attr", etAction);

        sendData(etData, false);
    }
}
