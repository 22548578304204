import { nativeSelector } from "./html-dom-utils";

const dynamicPhoneSelector = [
    ".dynamicPhone",
    ".imgFormCmpnt-phone",
    ".phone-link",
    ".phone",
    ".phoneLink",
    ".promosCmpnt-phone",
    "#dynamicPhone",
].join(", ");
const dynamicCampaignPhoneSelector = [
    ".dynamicCampaignPhone",
    "#dynamicCampaignPhone",
    "#dynamicCampaignPhone2",
    "#dynamicCampaignPhone3",
].join(", ");
const dynamicAgentButtonSelector = [".dynamicAgentButton", "#contactBlock-findAgentForm"].join(", ");

export function updateDynamicDomElements(
    data,
    rootElement,
    elements = [rootElement, ...(rootElement.querySelectorAll("*") || [])],
) {
    if (!data) return;
    let textnodes = nativeSelector(elements);
    let _nv;
    for (var i = 0, len = textnodes.length; i < len; i++) {
        _nv = textnodes[i].nodeValue;
        textnodes[i].nodeValue = _nv.replace(/%%clubPhoneNumberFormatted%%/g, data.phoneFormatted);
        _nv = textnodes[i].nodeValue;
        textnodes[i].nodeValue = _nv.replace(/%%clubPhoneNumberRaw%%/g, data.phone);
        _nv = textnodes[i].nodeValue;
        textnodes[i].nodeValue = _nv.replace(/%%campaignPhoneNumberFormatted%%/g, data.phoneFormatted);
        _nv = textnodes[i].nodeValue;
        textnodes[i].nodeValue = _nv.replace(/%%campaignPhoneNumberRaw%%/g, data.phone);
        _nv = textnodes[i].nodeValue;
        textnodes[i].nodeValue = _nv.replace(/%%clubMembershipLink%%/g, data.clubMembershipLink);
        _nv = textnodes[i].nodeValue;
        textnodes[i].nodeValue = _nv.replace(/%%clubAgentLink%%/g, data.clubAgentLink);
    }

    rootElement.querySelectorAll(dynamicPhoneSelector).forEach((element) => {
        element.setAttribute("href", "tel:" + data.phone);
        element.textContent = data.phoneFormatted;
    });

    rootElement.querySelectorAll(".dynamicAgentLink").forEach((element) => element.setAttribute("href", data.agentLink));

    rootElement
        .querySelectorAll(dynamicAgentButtonSelector)
        .forEach((element) => element.setAttribute("action", data.agentLink));

    rootElement.querySelectorAll(".dynamicMembershipLink").forEach((element) =>
        element.addEventListener("click", function () {
            window.location.href = data.membershipLink;
        }),
    );

    rootElement.querySelectorAll(dynamicCampaignPhoneSelector).forEach((element) => {
        element.setAttribute("href", "tel:" + data.campaignPhone);
        element.textContent = data.campaignPhoneFormatted;
    });

    rootElement.querySelectorAll(".sisu").forEach((element) => {
        element.setAttribute("href", "tel:" + data.sisuNumber);
        element.textContent = data.sisuPhoneFormatted;
    });

    rootElement.querySelectorAll(".memberServices").forEach((element) => {
        element.setAttribute("href", "tel:" + data.memberServicesNumber);
        element.textContent = data.memberServicesPhoneFormatted;
    });
}
