import { collectData } from "./DataCollectionService";
import { transferQuoteToEt2 } from "./expressterm-link";

export function createExpressTermQuoteService({ etSubdomain, transferedQuoteApiUrl }) {
    return {
        transferQuote: async ({ user, quote }) => {
            // HACK: Send blank payload to aggregation to see if aggregationSessionId in response has changed
            const response = await collectData({});
            if (document.getElementById("sessionID").value != response.aggregationSessionId) {
                console.warn("The session has expired before submitting quote request.");
                // window.location.href = "/session-expired";
                //return;
            }

            try {
                const et2Url = await transferQuoteToEt2(transferedQuoteApiUrl, window.location.href, etSubdomain, {
                    user,
                    quote,
                });
                window.location.href = et2Url;
            } catch (error) {
                console.error(error);
                window.location.href = "/unexpected-error";
            }
        },
    };
}
