import $ from "jquery";

// arrowAccordion

export default {
    init: init,
};

/**
 * init - initialize the arrowAccordion
 *
 * @private
 */
function init() {
    if ($(".arrowAccordion").length === 0) {
        //.error('No arrowAccordion found');
        return;
    }

    _initArrowAccordion();
}

function _initArrowAccordion() {
    var $categories = $(".arrowAccordion"); // arrowAccordion
    var $categoryHeaderLinks = $(".arrowAccordion-header a"); // arrowAccordion-header
    var $expandAllButton = $(".arrowAccordion-expandAll"); // arrowAccordion-expandAll
    var $collapseAllButton = $(".arrowAccordion-collapseAll"); // arrowAccordion-collapseAll
    var $allCategoryBlocks = $(".arrowAccordion-block"); // arrowAccordion-block

    $categories.each(_initAccordionRows);
    $categoryHeaderLinks.on("click", _accordionHeaderClicked);
    $expandAllButton.on("click", _expandAllClicked);
    $collapseAllButton.on("click", _collapseAllClicked);

    /**
     * _initAccordionRows - set the initial state of the rows within an
     * arrowAccordion category (shown or hidden)
     *
     * @private
     */
    function _initAccordionRows() {
        var $categoryBlocks = $(this).find(".arrowAccordion-block");
        var $searchBlock = $(this).find(".search-searchBlock");

        if ($(this).hasClass("open")) {
            $categoryBlocks.removeClass("hide");
        } else {
            $categoryBlocks.addClass("hide");
            $searchBlock.addClass("noBorder");
        }
    }

    /**
     * _accordionHeaderClicked - show or hide the arrowAccordion section
     * when the header is clicked
     *
     * @param {event}
     *            e
     * @private
     */
    function _accordionHeaderClicked(e) {
        var $category = $(this).parents(".arrowAccordion");
        var $categoryBlocks = $category.find(".arrowAccordion-block");
        var $searchBlock = $category.find(".search-searchBlock"); // search
        // pages
        // only

        e.preventDefault();

        if ($category.hasClass("open")) {
            $category.removeClass("open");
            $categoryBlocks.addClass("hide");
            $searchBlock.addClass("noBorder"); // search pages only remove
            // border from div
        } else {
            $category.addClass("open");
            $categoryBlocks.removeClass("hide");
            $searchBlock.removeClass("noBorder"); // search pages only add
            // border back to div
        }
    }

    function _expandAllClicked(e) {
        e.preventDefault();
        $categories.addClass("open");
        $allCategoryBlocks.removeClass("hide");
    }

    function _collapseAllClicked(e) {
        e.preventDefault();
        $categories.removeClass("open");
        $allCategoryBlocks.addClass("hide");
    }
}
