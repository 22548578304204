/**
 * @typedef {Object} AggregationRestResponse - The type describing the state returned by the form level reducer
 * @property {string} aggregationSessionId - The session identifier used by the aggregation system
 * @property {string} visitorId - The visitor identifier used by HippoCMS/brXM
 */

/**
 * @param {Object} data - The data entered by the user that will be issued along with a request for a quote
 * @return {Promise<AggregationRestResponse>} This is the result
 */
export function collectData(data) {
    return fetch(aaalife.ctx + "restservices/aggregate", {
        headers: {
            accept: "*/*",
            "content-type": "application/json",
        },
        body: JSON.stringify(data),
        method: "POST",
        mode: "cors",
        credentials: "include",
    }).then((response) => response.json());
}
