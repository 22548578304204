import $ from "jquery";
import { allToString, appendData, collectFormData, sendDataAndEvent } from "../collector";

export function aaalifeSendMeInfoFormInit() {
    if ($("#sendMeInfoForm").length > 0) {
        init();
    }

    function init() {
        $("#sendMeInfoFormSubmit").on("click", _slidePromoPanel);
    }

    function _slidePromoPanel(e) {
        e.preventDefault();

        if (_checkEmail()) {
            $(".promosCmpnt-img-sendMeInfo").addClass("hide");
            $(".promosCmpnt-contentWrap-sendMeInfo").addClass("hide");
            $(".promosCmpnt-confirmation").removeClass("hide");

            var itc = $("#sendMeInfoForm").data("itc");
            var attrData = $("#sendMeInfoForm").data();
            attrData = appendData(attrData, "itc", itc);
            attrData = allToString(attrData);
            sendDataAndEvent("SMIC", collectFormData($("#sendMeInfoForm"), attrData));
        }
    }

    function _checkEmail() {
        var isValid;
        var $input = $("#contact_email");
        var email = $input.val();

        isValid = aaalife.utils.validate.emailFormat(email, true);
        _highlightInvalidField(isValid, $input);

        return isValid;
    }

    function _highlightInvalidField(isValid, $argElement) {
        var $element = typeof $argElement !== "undefined" ? $argElement : $(this);

        if (isValid) {
            $element.removeClass("invalid").addClass("valid");
        } else {
            $(".inlineValidation").toggleClass("show", true);
            $element.removeClass("valid").addClass("invalid");
        }
    }
}
