import $ from "jquery";
import React from "react";
import ReactDOM from "react-dom";
// import App from "./aaalife-react.jsx";
import SessionTimeoutDialog from "./react-components/session-timeout-dialog.jsx";
import { Foundation, MediaQuery, Keyboard } from "foundation-sites";
if (!window.$) {
    window.$ = $; // HACK: To make jQuery available as $ in global scope for scripts embedded in ftl files.
}
import "classlist-polyfill";
import svg4everybody from "imports-loader?define=>false!svg4everybody";
svg4everybody();
import "core-js/features/string/pad-start";
import "core-js/features/object/entries";
import "core-js/web/url";
import ResponsiveBackgrounds from "imports-loader?define=>false!responsivebackgroundimages/js/bg-responsive";
import AjaxInterceptInit from "./giwl-enrollment/aaalife-override-ajax";
import { initalizeReturnToAAALink } from "./giwl-enrollment/aaalife-return-to-aaa-link";
import createAaalife, { aaalifeBaseInit } from "./giwl-enrollment/aaalife-base-init";
import { browserStorage } from "./giwl-enrollment/aaalife-utils-browser-storage";
import { Modal } from "./giwl-enrollment/aaalife-utils-modal";
import { Inline } from "./giwl-enrollment/aaalife-utils-inline-without-recycling";
import { polyfillArrayMethods } from "./giwl-enrollment/aaalife-array-polyfills";
import "./giwl-enrollment/aaalife-nodelist-polyfills";
import { extendUtils } from "./giwl-enrollment/aaalife-utils-extend";
import { hookupZipCodePromoButtonOnClick } from "./giwl-enrollment/aaalife-zip-code-promo-button-click";
import * as expressTermUtils from "./giwl-enrollment/aaalife-utils-express-term";
import ValidateUtils from "./giwl-enrollment/aaalife-utils-validate";
import PrevalidateUtils from "./giwl-enrollment/aaalife-utils-prevalidate";
import { init } from "./giwl-enrollment/aaalife-init";
import { init as accordionInit } from "./giwl-enrollment/aaalife-accordion-init";
import AlertUtils, { _init as alertInit } from "./giwl-enrollment/aaalife-utils-alert";
import { init as applyNowInit } from "./giwl-enrollment/aaalife-applynow-init";
import arrowAccordion from "./giwl-enrollment/aaalife-utils-arrow-accordion";
import boxAccordion from "./giwl-enrollment/aaalife-utils-box-accordion";
import { initEstimatedNeedsForm } from "./giwl-enrollment/aaalife-estimated-needs-form-init";
import { initLoginForm } from "./giwl-enrollment/aaalife-login-form-init";
import { initMobileMenu } from "./giwl-enrollment/aaalife-mobile-menu-init";
import { initPlanDetails } from "./giwl-enrollment/aaalife-plan-details-init";
import { initOrganicLandingForm } from "./giwl-enrollment/aaalife-organic-landing-form-init";
import { quoterResultsFactory } from "./giwl-enrollment/aaalife-quoter-results";
import { initQuoteForm } from "./giwl-enrollment/aaalife-quote-form-init-non-giwl";
import { aaalifeSearchInit } from "./giwl-enrollment/aaalife-search-page-init";
import { aaalifeSideBarInit } from "./giwl-enrollment/aaalife-sidebar-init";
import { aaalifeSwiperInit } from "./giwl-enrollment/aaalife-swiper-init";
import { aaalifeCampaignAndTalkToExpertInit } from "./giwl-enrollment/aaalife-campaign-talk-to-expert-init";
import { showContactFormOnLoad } from "./giwl-enrollment/aaalife-show-contact-form-onLoad";
import { aaalifeSendMeInfoFormInit } from "./giwl-enrollment/aaalife-send-me-info-form-init";
import { aaalifeHaveQuestionsInit } from "./giwl-enrollment/aaalife-have-questions-init";
import { compareProductsFormInit } from "./giwl-enrollment/aaalife-compare-product-form-init";
import { customerSupportFormInit } from "./giwl-enrollment/aaalife-customer-support-form-init";
import { emailFormInit } from "./giwl-enrollment/aaalife-email-form-init";
import { findAgentFormInit } from "./giwl-enrollment/aaalife-find-agent-form-init";
import { faqFormInit } from "./giwl-enrollment/aaalife-faq-form-init";
import { chooseTopicWrapperInit } from "./giwl-enrollment/aaalife-choose-topic-wrapper-init";

//Chunk #4
import { agentQuoter } from "./giwl-enrollment/aaalife-agent-quoter-init";
import createGeoLocator from "./giwl-enrollment/aaalife-geo-location";
import { dataCollapse } from "./giwl-enrollment/aaalife-utils-catagory-component";
import { initTimeoutElement } from "./giwl-enrollment/aaalife-timeout-element-init";
import { initBlockPipeForAllElementsNamedEmail } from "./giwl-enrollment/aaalife-block-pipe-for-all-elements-named-email";
import { aaalifeLightboxInit } from "./giwl-enrollment/aaalife-lightbox-init";
import { aaalifeAlertBarInit } from "./giwl-enrollment/aaalife-alert-bar";
import { aaalifeAlertLightBox } from "./giwl-enrollment/aaalife-alert-light-box";
import { aaalifeFooterSearch } from "./giwl-enrollment/aaalife-footer-search";
import { onDocumentReady } from "./on-document-ready";
import { stickyHeaderInit } from "./utils/sticky-header";
import { initializePopupStoryList } from "./popup-story-list/popup-story-list.js";

ResponsiveBackgrounds.init({
    envs: ["sm", "md", "lg"],
});

var coverageValue = 500000;

AjaxInterceptInit();

initalizeReturnToAAALink($);

window.aaalife = createAaalife();
const aaalife = window.aaalife;

// HACK: Make browserStorage available to scripts embedded into .ftl files
aaalife.utils.browserStorage = browserStorage;

aaalife.utils.Modal = Modal;

aaalife.utils.Inline = Inline;

polyfillArrayMethods();

aaalife.utils.validate = ValidateUtils;

aaalife.utils = extendUtils(aaalife.utils);

hookupZipCodePromoButtonOnClick();

aaalife.utils.expressTerm = expressTermUtils;

aaalife.utils.prevalidate = PrevalidateUtils;

$(init);

if ($(".accordion").length > 0) {
    $(accordionInit);
}

$(alertInit);
aaalife.utils.alert = AlertUtils;

if ($(".applyNow-form").length > 0 || $(".applyNow-confirmation").length > 0) {
    $(applyNowInit);
}

aaalife.utils.arrowAccordion = arrowAccordion;
aaalife.utils.boxAccordion = boxAccordion;

initEstimatedNeedsForm();

compareProductsFormInit(); // SAFE TO DELETE - seems not been used anywhere in aaalife site

customerSupportFormInit();

emailFormInit();

chooseTopicWrapperInit(); // SAFE TO DELETE - seems not been used anywhere in aaalife site

faqFormInit();

findAgentFormInit();

initLoginForm();

initMobileMenu();

initPlanDetails();

initOrganicLandingForm();

aaalife.quoteresults = quoterResultsFactory();

initQuoteForm();

aaalifeSearchInit();

aaalifeSideBarInit();

aaalifeSwiperInit();

aaalifeCampaignAndTalkToExpertInit();

showContactFormOnLoad();

aaalifeSendMeInfoFormInit();

aaalifeHaveQuestionsInit();

agentQuoter();

aaalife.utils.geolocator = createGeoLocator();

dataCollapse();

// lightbox
aaalifeLightboxInit();

aaalifeAlertBarInit();

// Alert Light Box
aaalifeAlertLightBox();

aaalifeFooterSearch();

initTimeoutElement();

initBlockPipeForAllElementsNamedEmail();

stickyHeaderInit();

// TODO: Uncomment to attach session dialog component
// ReactDOM.render(<SessionTimeoutDialog />, document.getElementById("session-timeout-dialog"));

initializePopupStoryList();

$(document).ready(function () {
    onDocumentReady();
});
