/**
 * Creates a Date object that reprsents the same date specified by year, month, and day
 * @param {Number} year The year value specified as a 4 digit number
 * @param {Number} month The month value between 1 and 12
 * @param {Number} day The day value between 1 and 31 depending on the month specified
 */
export function createDate(year, month, day) {
    return new Date(year, month - 1, day);
}

export function createDateFromEpocTime(epocTime) {
    const date = new Date();
    date.setTime(epocTime);
    return date;
}

/**
 * Calculates the {@link https://www.quickquote.com/blog/how-to-calculate-your-life-insurance-age/ Age Last Birthday}
 * @param {Date} dob Date of birth
 * @param {Date} now Current date and time right now
 */
export function ageLastBirthday(dob, now) {
    var age = now.getFullYear() - dob.getFullYear();
    var monthsUntilBirthday = now.getMonth() - dob.getMonth();

    return monthsUntilBirthday < 0 || (monthsUntilBirthday === 0 && now.getDate() < dob.getDate()) ? age - 1 : age;
}
