import $ from "jquery";
import { browserStorage } from "./aaalife-utils-browser-storage";
export { zipReturnState, appendHiddenFormData };

// return {
// 	zipReturnState : zipReturnState,
// 	appendHiddenFormData : appendHiddenFormData
// };

function zipReturnState(e) {
    var user = _getUserData();
    aaalife.data.user = user;

    var geocoder = new google.maps.Geocoder();
    var zip = aaalife.data.user.zip;
    geocoder.geocode(
        {
            componentRestrictions: {
                country: "US",
                postalCode: zip,
            },
        },
        _processGeocodeResults,
    );

    /**
     * _processGeocodeResults
     *
     * @param results
     * @param status
     * @private
     */
    function _processGeocodeResults(results, status) {
        var user = _getUserData();
        aaalife.data.user = user;

        if (status == google.maps.GeocoderStatus.OK) {
            let state = results[0].address_components.find(_searchAddrComponents);

            if (state && state.short_name) {
                state = state.short_name;
                aaalife.data.user.state = state;
                browserStorage.setObject("user", aaalife.data.user);
            }
        }
    }
}

function _searchAddrComponents(addrComp) {
    return addrComp.types.find(_searchTypes);
}

function _searchTypes(type) {
    return type === "administrative_area_level_1";
}

function appendHiddenFormData(formId) {
    var user = _getUserData();
    var quote = _getQuoteData();
    aaalife.data.user = user;
    aaalife.data.quote = quote;

    var sessionId = $("#sessionID").val();

    if (formId.localeCompare("#quoteForm") != 0 && formId.localeCompare("#expressTermForm") != 0) {
        $(formId).append('<input type="hidden" name="day" value="' + aaalife.data.user.dobDay + '">');
        $(formId).append('<input type="hidden" name="month" value="' + aaalife.data.user.dobMonth + '">');
        $(formId).append('<input type="hidden" name="year" value="' + aaalife.data.user.dobYear + '">');
        $(formId).append('<input type="hidden" name="zip" value="' + aaalife.data.user.zip + '">');
        $(formId).append('<input type="hidden" name="gender" value="' + aaalife.data.user.gender + '">');
        $(formId).append('<input type="hidden" name="inches" value="' + aaalife.data.user.heightInches + '">');
        $(formId).append('<input type="hidden" name="feet" value="' + aaalife.data.user.heightFeet + '">');
        $(formId).append('<input type="hidden" name="weight" value="' + aaalife.data.user.weight + '">');
        $(formId).append('<input type="hidden" name="rateYourHealth" value="' + aaalife.data.user.health + '">');
        $(formId).append('<input type="hidden" name="nicotineUse" value="' + aaalife.data.user.nicotine + '">');
        $(formId).append('<input type="hidden" name="nicotineUsageKey" value="' + aaalife.data.user.nicotineUsageKey + '">');
        $(formId).append('<input type="hidden" name="email" value="' + aaalife.data.user.email + '">');
        $(formId).append('<input type="hidden" name="isMember" value="' + aaalife.data.user.isMember + '">');
        $(formId).append('<input type="hidden" name="replacingExistingPolicy" value="' + aaalife.data.quote.hasPolicy + '">');
        $(formId).append('<input type="hidden" name="embeddedQuoteUrl" value="' + aaalife.fromServer.urlStrings.pageUrl + '">');
        $(formId).append(
            '<input type="hidden" name="aaaLifeReturnPage" value="' + aaalife.fromServer.urlStrings.pageUrl + '">',
        );
        $(formId).append('<input type="hidden" name="term" value="' + aaalife.data.quote.term + '">');
        $(formId).append('<input type="hidden" name="product" value="' + aaalife.data.quote.term + 'X13">');
        $(formId).append('<input type="hidden" name="coverageAmount" value="' + aaalife.data.quote.coverage + '">');
    }
    /*		$(formId).append(
            '<input type="hidden" name="state" value="'
                    + aaalife.data.user.state + '">');
*/ $(formId).append('<input type="hidden" name="sessionId" value="' + sessionId + '">');
}

function _getUserData() {
    var user = browserStorage.getObject("user");

    if (!user) {
        user = aaalife.data.user || {};
    }
    return user;
}

function _getQuoteData() {
    var quote = browserStorage.getObject("quote");

    if (!quote) {
        quote = aaalife.data.quote || {};
    }
    return quote;
}
