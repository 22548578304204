import $ from "jquery";
import "js-cookie";

export function aaalifeAlertLightBox() {
    $(document).ready(function () {
        $(_init);

        /**
         * _init - initialize Alert(s)
         *
         * @return {void} void
         */
        function _init() {
            var idProp = $(".modalAlertLightBox");
            if (idProp.length == 0) return;
            var idValue = idProp.attr("id");
            if (Cookies.get("alertLightBox") === undefined || Cookies.get("alertLightBox") != idValue) {
                initModal();
            }
        }

        function initModal($modalContent) {
            var _modalAlert;

            $modalContent = $modalContent || $(".modalAlertLightBox");
            _modalAlert = new aaalife.utils.Modal({
                wrapperClass: "alert-box-wrapper",
                content: $modalContent.html(),
                styles: $modalContent.data("styles"),
            });
            _modalAlert.open();
            sendEvent("ALB");
            $(".modal-bg").css("background", $(".modalAlertLightBox").attr("data-bgColor"));

            $(document).on(
                "modal-close",
                {
                    type: "modal",
                },
                _closeBtnClicked,
            );
            $(".modal-window.content-ready").css("background", $(".modalAlertLightBox").attr("data-bgColor"));
        }

        function _closeBtnClicked() {
            var alertID = $(".modalAlertLightBox").attr("id");

            Cookies.set("alertLightBox", alertID, {
                expires: 99999,
            });
        }

        if (window.location.href.indexOf("agent-quote") > -1) {
            $(".alert-bar").hide();
        }
    });
}
