import $ from "jquery";

export function aaalifeLightboxInit() {
    $(document).ready(function () {
        $('a[href^="lightbox"],a[href^="#lightbox"]').on("click", function (e) {
            e.preventDefault();
            var url = $(this).attr("href");

            if (url.substring(0, 1) == "#") url = url.substring(1);

            url = aaalife.ctx + url;

            $.ajax({
                url: url,
                dataType: "html",
                success: function (data) {
                    initModal($(data));
                },
            });
        });

        function initModal($modalContent) {
            var _modalAlert;
            $modalContent = $modalContent;
            _modalAlert = new aaalife.utils.Modal({
                wrapperClass: "lightbox-wrapper",
                content: $modalContent,
                styles: $modalContent.data("styles"),
            });

            _modalAlert.open();
        }
    });
}
