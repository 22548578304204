export const getAllDescendentFields = (rootElement) => rootElement.querySelectorAll("input,select,textarea");

export const getAllDecscendentFieldsWithDataLayerEventAttribute = (rootElement) =>
    rootElement.querySelectorAll("input[data-datalayerevent],select[data-datalayerevent],textarea[data-datalayerevent]");

export const createDataLayerEventFromAttributes = (inputElement) => {
    const eventName = inputElement.getAttribute("data-datalayerevent");
    const data = JSON.parse(inputElement.getAttribute("data-datalayerdata") || "{}");
    return { value: inputElement.value, ...data, event: eventName };
};

const addChangeEventHandler = (field, eventListener) => {
    field.addEventListener("change", eventListener);
};

const addChangeEventHandlers = (fields, eventListener) =>
    fields.forEach((field) => addChangeEventHandler(field, eventListener));

export const attachDataLayerAutoPush = ({
    rootElement,
    eventFactory = createDataLayerEventFromAttributes,
    fieldSelector = getAllDecscendentFieldsWithDataLayerEventAttribute,
}) => {
    const descendentFields = fieldSelector(rootElement);

    const handleChange = (event) => dataLayer.push(eventFactory(event.target));

    addChangeEventHandlers(descendentFields, handleChange);
};
