const validStates = new Set([
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MP",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VI",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
]);

const stateRestrictionsProperties = [
    "allTermNotOffered",
    "expressTermNotOffered",
    "replacementPolicyNotOffered",
    "annuitiesNotOffered",
];

async function getValidStateFromCdsService(zip) {
    const response = await fetch(`/services/cds-services/stateAbbreviation?zipCode=${zip}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });
    const state = await response.json();
    if (validStates.has(state)) {
        return state;
    } else {
        throw new Error(`Invalid state '${state}' in CDS service response.`);
    }
}

export function createZipInfoService(restrictions) {
    async function getZipInfo(zip) {
        const state = await getValidStateFromCdsService(zip);
        const zipConditions = stateRestrictionsProperties.reduce((zipConditions, restriction) => {
            return {
                ...zipConditions,
                [restriction]: restrictions[restriction].some((restrictedState) => state === restrictedState),
            };
        }, {});

        return {
            zip,
            state,
            ...zipConditions,
        };
    }

    return {
        getZipInfo,
    };
}
