import { getCookie } from "../utils/cookie";
import { getToggle } from "../utils/toggle";
// Adapted from `expressterm-link.js` in AAALife.com

function changeSubdomain(originalUrl, subdomain) {
    return originalUrl.replace(/^.+(\.[^.]+\.[^.]+$)/, `${subdomain}$1`);
}

function createTransferredQuoteApiUrl(currentLocation, et2Subdomain, pathname) {
    const url = new URL(currentLocation);
    url.hostname = changeSubdomain(url.hostname, et2Subdomain);
    const newUrl = new URL("http://" + url.hostname + pathname);
    url.pathname = newUrl.pathname;
    url.search = newUrl.search;
    return url.toString();
}

function translateDob(dobYear, dobMonth, dobDay) {
    return dobYear && dobMonth && dobDay
        ? dobYear + "-" + dobMonth.padStart(2, "0") + "-" + dobDay.padStart(2, "0")
        : undefined;
}

function translateHeight(feet, inches) {
    return feet && inches
        ? {
              feet: parseInt(feet, 0),
              inches: parseInt(inches, 0),
          }
        : undefined;
}

function translateIsAaaMember(isMember) {
    return isMember === "Yes" || isMember === "true" || isMember === true
        ? true
        : isMember === "No" || isMember === "false" || isMember === false
          ? false
          : undefined; // explicitly return undefined if user hasn't specified a value
}

function translateIsNicotineUser(nicotine) {
    return nicotine === "Yes" ? true : nicotine === "No" ? false : undefined; // explicitly return undefined if user hasn't specified a value
}

function translateReplaceExistingInsurance(user) {
    return user.gender // If we have a valid gender (either "Male" or "Female") we know user has likely filled out a quote form
        ? false // Always assume false for now, address reading actual value of _quoterValid.panel1.policyReplacement
        : undefined; // User hasn't indicated replacement so don't default to true/false
}

function translateUser(user) {
    const userData = {
        dob: translateDob(user.dobYear, user.dobMonth, user.dobDay),
        email: user.email || undefined,
        gender: user.gender || undefined,
        height: translateHeight(user.heightFeet, user.heightInches),
        is_aaa_member: translateIsAaaMember(user.isMember),
        nicotine_use: user.nicotineUsageKey && user.nicotineUsageKey.toString().replace("nicotineUse-", ""),
        is_nicotine_user: translateIsNicotineUser(user.nicotine),
        replace_existing_insurance: translateReplaceExistingInsurance(user),
        state: user.state || undefined,
        weight: parseInt(user.weight, 0) || undefined,
        zipcode: user.zip || undefined,
    };

    const objectContainsValues = Object.entries(userData).some(([_, value]) => value !== undefined);
    return objectContainsValues ? userData : undefined;
}

function buildExpressTermRequestObject({ user, quote }) {
    // const { user, quote } = aaalife.data;

    let data = {
        user: translateUser(user),
        quote: {
            product: quote.type || "express_term",
            coverage_amount: parseInt(quote.coverage.replace(/,/g, "")) || undefined,
            term_length: parseInt(quote.term) || undefined,
        },
        tracking: {
            campaign_code: getCookie("cmp") || undefined,
            lead_source: getCookie("sc") || "67",
        },
    };
    return data;
}

function sendDataToET(url, data) {
    const etFlag = { attr: { action: "toET" } };
    return serviceCall("/restservices/aggregate", etFlag)
        .then(() => serviceCall(url, data, getToggle("cors-simple-request") ? "text/plain" : "application/json", "omit"))
        .catch(() => {
            window.location.href = "/unexpected-error";
        });
}

function serviceCall(url, data, contentType = "application/json", credOption = "same-origin", timeout = 10000) {
    return Promise.race([
        fetch(url, {
            credentials: credOption,
            headers: {
                "Content-Type": contentType,
                Accept: "application/json",
            },
            method: "POST",
            body: JSON.stringify(data),
        }),
        new Promise((_, reject) => setTimeout(() => reject(new Error("timeout")), timeout)),
    ]);
}

export function transferQuoteToEt2(transferedQuoteApiUrl, currentLocation, et2Subdomain, { user, quote }) {
    return sendDataToET(transferedQuoteApiUrl, buildExpressTermRequestObject({ user, quote })).then((response) => {
        return response.json().then((data) => {
            if (data.response && typeof data.response.pathname === "string") {
                return createTransferredQuoteApiUrl(currentLocation, et2Subdomain, data.response.pathname);
            }

            throw data;
        });
    });
}
