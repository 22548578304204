import $ from "jquery";

// boxAccordion

var _linkEventsInited = false;

export default {
    init: init,
};

/**
 * init - initializes the boxAccordion and sets up events for media query
 * change
 */
function init() {
    if ($(".boxAccordion").length === 0) {
        return;
    }

    if (["small", "medium"].indexOf(Foundation.MediaQuery.current) > -1) {
        // Only init choice accordion on small/medium
        _initBoxAccordion();
    }

    // watch for window resize
    $(window).on("changed.zf.mediaquery", _handleMQChange);
}

/* ---- Private Methods --- */

/**
 * _handleMQChange
 *
 * @param event
 * @param newSize
 * @param oldSize
 * @private
 */
function _handleMQChange(event, newSize, oldSize) {
    // resizing from mobile to desktop
    if (["small", "medium"].indexOf(oldSize) > -1 && ["large", "xlarge", "xxlarge"].indexOf(newSize) > -1) {
        $(".boxAccordion-group-body").removeClass("hide");
    }

    // resizing from desktop to mobile
    if (["large", "xlarge", "xxlarge"].indexOf(oldSize) > -1 && ["small", "medium"].indexOf(newSize) > -1) {
        _initBoxAccordion();
    }
}

/**
 * _initChoiceAccordion - initialize the mobile-only accordion wrapping the
 * Compare Products checkboxes
 *
 * @private
 */
function _initBoxAccordion() {
    $(".boxAccordion-group").each(_initAccordionSection);
    if (!_linkEventsInited) {
        $(".boxAccordion").on("click", ".boxAccordion-groupHead a", _accordionSectionClicked);
        _linkEventsInited = true;
    }
}

/**
 * _initAccordionSection - set the initial state of the rows within a mobile
 * accordion section (shown or hidden)
 *
 * @private
 */
function _initAccordionSection() {
    var $boxAccordionGroup = $(this);

    if ($boxAccordionGroup.hasClass("open")) {
        $boxAccordionGroup.find(".boxAccordion-group-body").removeClass("hide");
    } else {
        $boxAccordionGroup.find(".boxAccordion-group-body").addClass("hide");
    }
}

/**
 * _accordionSectionClicked - show or hide the mobile accordion section when
 * the header is clicked
 *
 * @param {event}
 *            e
 * @private
 */
function _accordionSectionClicked(e) {
    e.preventDefault();

    var $boxAccordionGroup = $(this).parents(".boxAccordion-group");

    if ($boxAccordionGroup.hasClass("open")) {
        $boxAccordionGroup.find(".boxAccordion-group-body").addClass("hide");
        $boxAccordionGroup.removeClass("open");
    } else {
        $boxAccordionGroup.find(".boxAccordion-group-body").removeClass("hide");
        $boxAccordionGroup.addClass("open");
    }
}
