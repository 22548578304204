import { PRODUCT_TYPES } from "../constants/PRODUCT_TYPES";
import { booleanToYesNoOrElse as yesNoOrElse } from "../utils/value-translators";
import { AggregationClient } from "./aggregation-client";

export function hideShowPanel1Decorations(activePanel) {
    const isFirstPanel = activePanel === 1;
    const hideClass = "hide-but-do-not-collapse";
    document.querySelector(".quoteForm-redCircleBlock")?.classList.toggle(hideClass, !isFirstPanel);
    Array.from(document.querySelectorAll("#item2, #item3")).forEach((section) =>
        section.classList.toggle(hideClass, !isFirstPanel),
    );
    document.querySelector("#item1")?.classList.toggle("expand", !isFirstPanel);
}

export function presentQuote(
    state,
    persistGlobalValues,
    transferExpressTermQuoteService,
    submitQuoteRequest,
    source,
    quoteResultsPageUrl,
) {
    const user = { ...stateToGlobalUser(state), source };
    const validation = stateToGlobalValidation(state);
    const quote = stateToGlobalQuote(state);

    persistGlobalValues({ user, validation, quote });

    switch (state.productType) {
        case PRODUCT_TYPES.EXPRESS_TERM:
            transferExpressTermQuoteService.transferQuote({ user, quote });
            break;
        case PRODUCT_TYPES.TRADITIONAL_TERM:
            submitQuoteRequest(state)
                .then((response) => {
                    window.location.href = quoteResultsPageUrl;
                })
                .catch((error) => {
                    console.error(error);
                    window.location.href = "/unexpected-error";
                });
            break;
        default:
            throw new Error(`A product type of '${state.productType}' was unexpected.`);
    }
}

function stateToGlobalQuote(state) {
    return {
        type: state.productType,
        term: state.termLength,
        coverage: state.coverageAmount,
        premium: "",
        estimatedNeed: "",
        results: "",
        origin: window.location.pathname,
        panelFlag: "true",
    };
}

function stateToGlobalValidation(state) {
    return {
        koStatus: "n",
        zip: state.zip,
        ...(!(state.month && state.day && state.year)
            ? {}
            : {
                  age: {
                      month: state.month.toString(),
                      day: state.day.toString(),
                      year: state.year.toString(),
                  },
              }),
        ...(!(state.feet && state.inches && state.weight)
            ? {}
            : {
                  bmi: {
                      heightFeet: state.feet.toString(),
                      heightInches: state.inches.toString(),
                      weight: state.weight.toString(),
                  },
              }),
    };
}

function stateToGlobalUser(state) {
    return {
        zip: state.zip,
        gender: state.gender,
        dobMonth: state.month.toString(),
        dobDay: state.day.toString(),
        dobYear: state.year.toString(),
        isMember: yesNoOrElse("", state.isMember),
        email: state.email,
        hasPolicy: yesNoOrElse("", state.replacingPolicy),
        heightFeet: state.feet?.toString(),
        heightInches: (state.inches || "").toString(),
        weight: (state.weight || "").toString(),
        health: state.healthRating || "",
        nicotine: state.nicotine === "nicotineUse-LessThan12MonthsAgo" ? "Yes" : "No",
        eligibleForOnlyFair:
            state.healthConditionGroup?.healthRatings?.length === 1 && state.healthConditionGroup.healthRatings[0] === "fair"
                ? "yes"
                : "no",
        firstName: state.firstName,
        lastName: state.lastName,
        phone: state.phone,
        nicotineUsageKey: state.nicotine,
    };
}

/**
 * Calls toString on the provided value otherwise returns the elseValue
 * @param {string} elseValue
 * @param {any} value
 */
function toStringOrElse(elseValue, value) {
    return !!value && typeof value.toString === "function" ? value.toString() : elseValue;
}

/**
 *
 * @param {import("../reducer").FormState} state
 * @param {({user, quote, validation}) => void} persistGlobalValues
 * @param {(event) => void} pushToDataLayer
 * @param {string} source
 * @param {AggregationClient} aggregationClient
 * @returns
 */
export const panelTransitionHandler = (state, persistGlobalValues, pushToDataLayer, source, aggregationClient) => {
    // Hide/show the red circle
    hideShowPanel1Decorations(state.activePanel);

    const persistStateToGlobals = () => {
        const user = { ...stateToGlobalUser(state), source };
        const validation = stateToGlobalValidation(state);
        const quote = stateToGlobalQuote(state);

        persistGlobalValues({ user, validation, quote });
    };

    const isBackNavigation = state.activePanel < state.previousPanel;

    switch (state.activePanel) {
        case 1:
            return aggregationClient.reportPanel1Visit();
        case 2:
            persistStateToGlobals();

            const panel1Data = extractPanel1Data(state);

            pushToDataLayer({
                event: "TQSIP",
                user: {
                    zip: panel1Data.zip,
                    gender: panel1Data.gender,
                    state: panel1Data.state,
                    membership: panel1Data.membership,
                    ag: panel1Data.age,
                },
            });

            return aggregationClient.reportPanel2Visit(panel1Data, isBackNavigation, source);
        case 3:
            persistStateToGlobals();

            const panel2Data = extractPanel2Data(state);

            pushToDataLayer({
                event: "TQSP2",
                user: {
                    product:
                        panel2Data.productType === "traditional_term"
                            ? "TRAD-TERM"
                            : panel2Data.productType === "express_term"
                              ? "EXPRESSTERM"
                              : "",
                },
            });

            return aggregationClient.reportPanel3Visit(panel2Data, isBackNavigation, source);
        case 4:
            persistStateToGlobals();

            const panel3Data = extractPanel3Data(state);

            pushToDataLayer({
                event: "TQSP3",
            });

            return aggregationClient.reportPanel4Visit(panel3Data, source);
    }
};

/**
 * @param {import("../reducer").FormState} state
 */
function extractPanel1Data(state) {
    return {
        zip: state.zip,
        gender: state.gender,
        membership: yesNoOrElse("", state.isMember),

        year: toStringOrElse("", state.year),
        month: toStringOrElse("", state.month),
        day: toStringOrElse("", state.day),
        email: state.email,

        age: toStringOrElse("", state.age),
    };
}

/**
 * @param {import("../reducer").FormState} state
 */
function extractPanel2Data(state) {
    return { ...extractPanel1Data(state), productType: state.productType };
}

/**
 * @param {import("../reducer").FormState} state
 */
function extractPanel3Data(state) {
    return {
        ...extractPanel2Data(state),
        feet: toStringOrElse("", state.feet),
        inches: toStringOrElse("", state.inches),
        weight: toStringOrElse("", state.weight),
        nicotineUse: state.nicotine === "nicotineUse-LessThan12MonthsAgo" ? "Yes" : "No",
        rateYourHealth: state.healthRating || "",
        coverageAmount: state.coverageAmount,
        termLength: state.termLength,
    };
}
