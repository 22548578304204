import React, { useState } from "react";

export function Tooltip({
    children,
    id,
    svgClass,
    divClass,
    divAttributes = {},
    resources: { tooltipOpenIconUrl, tooltipCloseIconUrl },
}) {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <span className="showtooltip tooltip-wrap">
            <svg className={`icon ${svgClass}`} onClick={(e) => setShowTooltip(true)}>
                <use xlinkHref={tooltipOpenIconUrl} />
            </svg>
            <div
                id={id}
                className={`tooltipinfo ${divClass}`}
                {...divAttributes}
                style={{ display: showTooltip ? "block" : "none" }}
            >
                <a className="tooltipclose" onClick={(e) => setShowTooltip(false)}>
                    <svg className="icon icon-close">
                        <use xlinkHref={tooltipCloseIconUrl} />
                    </svg>
                </a>
                <p>{children}</p>
            </div>
        </span>
    );
}
