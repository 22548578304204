import { Inline } from "./aaalife-utils-inline";
import { v4 as uuidv4 } from "uuid";

export function getCookie(cookieName) {
    var b = document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

export function showErrorModal(text, onClose) {
    aaalife.utils.modalInit();
    let _inlineModal = new Inline({
        content: $(".errorModal1"),
        onClose: onClose,
    });
    //TODO: this used to be in giwl-enrollment, make sure other files calling this are pointing to giwl-utils
    $(".error-modal .error-modal-heading").html(text.heading);
    $(".error-modal .error-desc").html(text.desc);
    $(".error-modal .error-secondarydesc").html(text.desc2);

    _inlineModal.open();
}

export function generateUUID() {
    return uuidv4();
}
