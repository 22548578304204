import React, { Component } from "react";
// import css from "../../css/session-timeout-dialog/session-timeout-dialog.css";

class SessionTimeoutDialog extends Component {
    render() {
        return (
            <div className="dialog">
                <div className="action-bar">
                    <span className="action-title">{this.props.title}</span>
                    <i className="material-icons modal-close">close</i>
                </div>

                <div className="template">
                    <div className="header">Are you still there?</div>
                    <div className="body-text">
                        To protect your privacy your session will end soon unless you choose to continue.
                    </div>

                    <div className="images" />
                </div>
            </div>
        );
    }
}

export default SessionTimeoutDialog;
