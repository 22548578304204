import { NAVIGATE_NEXT, NAVIGATE_BACK, LOCATION_RESTRICTED, ZIP_CHANGED } from "./actions";

/**
 * @typedef {Object} LegacyHealthConditionGroupCriteria - The criteria used to determine inclusion within a HealthConditionGroup
 * @property {string} minAge -
 * @property {string} maxBMI -
 * @property {string} minBMI -
 */

/**
 * @typedef {Object} LegacyHealthConditionGroup - A grouping of related health conditions for determining health rating options
 * @property {Array.<"excellent"|"good"|"fair">} allowableHealthConditions - An array of health rating options for this group
 * @property {LegacyHealthConditionGroupCriteria} criteria - Criteria for the health conditions group
 */

/**
 * @typedef {Object} HealthConditionGroup - A grouping of related health conditions for determining health rating options
 * @property {Array.<"excellent"|"good"|"fair">} healthRatings - An array of health rating options for this group
 * @property {string} minAge -
 * @property {string} maxBmi -
 * @property {string} minBmi -
 */

/**
 * @typedef {Object} FormState - The type describing the state returned by the form level reducer
 * @property {1|2|3|4} activePanel - The ordinal value of the active panel
 * @property {1|2|3|4} previousPanel - The ordinal value of the previously active panel
 * @property {boolean} shouldPresentQuote - True if the form should navigate the user to the /quote-results page otherwise false
 * @property {Object} locationRestrictions - Product restrictions specific to the user's location determined by ZIP code
 * @property {{"1", "2", "3", "4"}} panels - The uncommitted panel state
 * @property {HealthConditionGroup} healthConditionGroup - The health condition group applicable to the customer's health conditions
 * @property {number} age - The customer's most recently calculated age last birthday in full years
 * @property {string} zip - The customer's zip
 * @property {"Male"|"Female"} gender - The customer's gender
 * @property {number} year - The customer's birth year
 * @property {number} month - The customer's birth month
 * @property {number} day - The customer's birth day
 * @property {string} email - The customer's email
 * @property {boolean} isMember - The customer's isMember
 * @property {number=} heightFeet -
 * @property {"express_term"|"traditional_term"} productType - The selected product type
 * @property {boolean} replacingPolicy - The customer's replacingPolicy
 * @property {number} feet - The customer's height in whole feet
 * @property {number} inches - The customer's inches
 * @property {number} weight - The customer's weight
 * @property {string} nicotine - The customer's nicotine
 * @property {string} healthRating - The customer's healthRating
 * @property {string} coverageAmount - The customer's coverageAmount
 * @property {string} termLength - The customer's termLength
 * @property {string} firstName - The customer's firstName
 * @property {string} lastName - The customer's lastName
 * @property {string} phone - The customer's phone
 * @property {["express_term"|"traditional_term"]} productTypeOptions
 */

/**
 * @param {FormState} state - The current state of the reducer
 * @param {Object} action - The action that has been dispatched to the reducer
 */
export function reducer(state, action) {
    switch (action.type) {
        case LOCATION_RESTRICTED:
            return { ...state, locationRestrictions: action.payload };
        case ZIP_CHANGED:
            return { ...state, zip: action.payload.zip };
        case NAVIGATE_NEXT:
            const lastPanel = state.productType === "traditional_term" ? 4 : 3;
            const panelState = action.payload.panelState;
            return {
                ...state,
                ...(state.activePanel === 1
                    ? {
                          zip: panelState.zip,
                          gender: panelState.gender,
                          year: panelState.dobYear,
                          month: panelState.dobMonth,
                          day: panelState.dobDay,

                          email: panelState.email,

                          isMember: panelState.isMember,

                          age: panelState.age,
                      }
                    : state.activePanel === 2
                      ? {
                            productType: panelState.productType || state.productType,
                        }
                      : state.activePanel === 3
                        ? {
                              replacingPolicy: panelState.replacingPolicy,
                              feet: panelState.feet,
                              inches: panelState.inches,
                              weight: panelState.weight,
                              nicotine: panelState.nicotine,
                              healthRating: panelState.healthRating,
                              coverageAmount: panelState.coverageAmount,
                              termLength: panelState.termLength,

                              healthConditionGroup: panelState.healthConditionGroup,
                          }
                        : state.activePanel === 4
                          ? {
                                firstName: panelState.firstName,
                                lastName: panelState.lastName,
                                phone: panelState.phone,
                                email: panelState.email,
                            }
                          : {}),
                previousPanel: state.activePanel,
                activePanel: next(state.productTypeOptions.length > 1, state.activePanel, lastPanel),
                shouldPresentQuote: state.activePanel === lastPanel,
            };
        case NAVIGATE_BACK:
            return state.activePanel === 1
                ? state
                : {
                      ...state,
                      previousPanel: state.activePanel,
                      activePanel: back(state.productTypeOptions.length > 1, state.activePanel),
                      // Store panel state when moving back
                      panels: {
                          ...state.panels,
                          [state.activePanel]: action.payload.panelState,
                      },
                  };
        default:
            return state;
    }
}

function next(isIntegratedFunnel, activePanel, lastPanel) {
    return activePanel < lastPanel
        ? isIntegratedFunnel || activePanel !== 1
            ? activePanel + 1
            : activePanel + 2 // Skip product type selection panel
        : activePanel;
}

function back(isIntegratedFunnel, activePanel, lastPanel) {
    return isIntegratedFunnel || activePanel !== 3 ? activePanel - 1 : activePanel - 2; // Skip product type selection panel
}
