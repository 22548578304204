import $ from "jquery";
import { Foundation, MediaQuery } from "foundation-sites";

export function initPlanDetails() {
    if ($(".plan-details").length > 0) {
        $(init);
    }

    /**
     * init - setup logic for the plan detail section
     *
     * @return {void} - returns void
     */
    function init() {
        $(".plan-details .heading").on("click", _categoryListToggle);

        // Toggle elements closed on mobile widths
        if (Foundation.MediaQuery.current === "small" || Foundation.MediaQuery.current === "medium") {
            $(".category.show").removeClass("show");
        }
    }

    /**
     * _categoryListToggle - toggles show class on closest category div
     *
     * @return {void} - returns void
     */
    function _categoryListToggle() {
        if (Foundation.MediaQuery.current === "small" || Foundation.MediaQuery.current === "medium") {
            $(this).closest(".category").toggleClass("show");
        }
    }
}
