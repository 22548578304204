import { appendData } from "../collector";
import { ageLastBirthday, createDate } from "../utils/age";
import { browserStorage } from "./aaalife-utils-browser-storage";

export default {
    hasValidZip: hasValidZip,
    hasValidAge: hasValidAge,
    hasValidBMI: hasValidBMI,
    storeZipObj: storeZipObj,
    isSameKOStatus: isSameKOStatus,
    zipName: "zip",
    geoZipName: "geoZip",
};

function storeKOStatus(validateObj, koValue) {
    if (validateObj == null) {
        validateObj = {};
    }

    validateObj = appendData(validateObj, "koStatus", koValue);
    browserStorage.setObject("validation", validateObj);
}

function isSameKOStatus(koValue) {
    var validation = browserStorage.getObject("validation");
    if (validation == null) {
        storeKOStatus(validation, koValue);
        return false;
    }

    if (validation.koStatus === koValue) {
        return true;
    } else {
        storeKOStatus(validation, koValue);
    }

    return false;
}

function storeZipObj(zip) {
    var validation = browserStorage.getObject("validation");
    if (validation == null) {
        validation = {};
    }

    // var zipObj = {
    // 	zip : zip
    // };
    validation = appendData(validation, "zip", zip);
    browserStorage.setObject("validation", validation);
}

function hasValidZip(zip) {
    // if (paramName != aaalife.utils.prevalidate.zipName
    // 		&& paramName != aaalife.utils.prevalidate.geoZipName) {
    // 	return false;
    // }

    var validation = browserStorage.getObject("validation");

    if (validation == null) {
        return false;
    }

    if (validation.zip == null) {
        return false;
    }

    if (validation.zip === zip || validation.zip !== zip) {
        return true;
    }

    // if (paramName === aaalife.utils.prevalidate.zipName) {
    // 	if (validation.zip === zip) {
    // 		return true;
    // 	}
    // } else {
    // 	if (validation.geoZip === zip) {
    // 		return true;
    // 	}
    // }

    return false;
}

function storeAgeObj(validateObj, info) {
    var ageObj = {
        month: info.month,
        day: info.day,
        year: info.year,
    };
    validateObj = appendData(validateObj, "age", ageObj);
    browserStorage.setObject("validation", validateObj);
}

function hasValidAge(info) {
    var month = info.month;
    var day = info.day;
    var year = info.year;

    if (!aaalife.utils.validate.age(month, day, year).allSelected) {
        return true;
    }

    var validation = browserStorage.getObject("validation");

    if (validation == null) {
        validation = {};
        storeAgeObj(validation, info);
        return false;
    } else if (validation.age == null) {
        storeAgeObj(validation, info);
        return false;
    }

    const age = ageLastBirthday(createDate(year, month, day), new Date());
    const storedAge = ageLastBirthday(createDate(validation.age.year, validation.age.month, validation.age.day), new Date());
    if (age === storedAge) {
        return true;
    }

    storeAgeObj(validation, info);
    return false;
}

function storeBMIObj(validateObj, data) {
    var bmiObj = {
        heightFeet: data.feet,
        heightInches: data.inches,
        weight: data.weight,
    };
    validateObj = appendData(validateObj, "bmi", bmiObj);
    browserStorage.setObject("validation", validateObj);
}

function hasValidBMI(data) {
    var validation = browserStorage.getObject("validation");

    if (validation == null) {
        validation = {};
        storeBMIObj(validation, data);
        return false;
    } else if (validation.bmi == null) {
        storeBMIObj(validation, data);
        return false;
    }

    if (
        validation.bmi.heightFeet === data.feet &&
        validation.bmi.heightInches === data.inches &&
        validation.bmi.weight === data.weight
    )
        return true;

    storeBMIObj(validation, data);
    return false;
}
